import React from "react";
import CustomModal from "components/Modal/CustomModal";
import { Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { host } from "services/api";

const NoVolunteerDotDetail = ({ isOpen, toggle, DOT }) => {
  const { uuid, date, video, remark, late_remark } = DOT.DOT;

  return (
    <CustomModal
      isOpen={isOpen}
      title="VOT Status Detail"
      onClick={() => toggle(false)}
      style={{ maxWidth: "60%" }}
    >
      <Container className="mt-3" fluid>
        <Row className="mb-3">
          <Col sm={6}>ရက်စွဲ</Col>
          <Col sm={6}>{date}</Col>
        </Row>
        {video && (
          <Row className="mb-3">
            <Col sm={6}>Video</Col>
            <Col sm={6}>
              <a href={`${host}/DOT-video/${uuid}`} target="_black">
                Click Link To View
              </a>
            </Col>
          </Row>
        )}
        {remark && (
          <Row className="mb-3">
            <Col sm={6}>မှတ်ချက်</Col>
            <Col sm={6}>{remark}</Col>
          </Row>
        )}
        {late_remark && (
          <Row className="mb-3">
            <Col sm={6}>နောက်ကျသည့် အကြောင်းအရင်း</Col>
            <Col sm={6}>{late_remark}</Col>
          </Row>
        )}
      </Container>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  DOT: store.DOT,
});

export default connect(mapStateToProps)(NoVolunteerDotDetail);
