import DashboardCard from "components/Dashboard/DashboardCard";
import React from "react";
import { useHistory } from "react-router";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import BackBtn from "utils/backBtn";

const Dashboard = () => {
  const router = useHistory();

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader>
          <CardTitle tag="h3">Dashboard</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <DashboardCard
                title="Volunteers"
                onClick={() => router.push(`/admin/volunteer-virtualization`)}
              />
            </Col>
            <Col md="4">
              <DashboardCard
                title="Patients"
                onClick={() => router.push(`/admin/patient-virtualization`)}
              />
            </Col>
            <Col md="4">
              <DashboardCard
                title="VOT Status"
                onClick={() => router.push(`/admin/dot-status-virtualization`)}
              />
            </Col>
            <Col md="4">
              <DashboardCard
                title="Activity"
                onClick={() => router.push(`/admin/activity-virtualization`)}
              />
            </Col>
            <Col md="4">
              <DashboardCard
                title="Monthly Volunteer Visualization"
                onClick={() =>
                  router.push(`/admin/monthly-volunteer-report-visualization`)
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Dashboard;
