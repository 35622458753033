import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import CustomTable from 'components/Table/Table'
import { connect } from 'react-redux'
import { getMedicines, getMedicine, deleteMedicine } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import CustomAlert from 'components/Alert/CustomAlert'
import { basicDataMiddleware, CREATE } from 'utils/middleware'
import BackBtn from 'utils/backBtn'
import MedicineCreate from './MedicineCreate'
import MedicineUpdate from './MedicineUpdate'

const MedicineList = ({
  status,
  auth,
  medicine,
  getMedicines,
  getMedicine,
  deleteMedicine,
}) => {
  const { role } = auth.user
  const [alert, setAlert] = useState(null)

  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)

  useEffect(() => {
    getMedicines()
  }, [getMedicines])

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteMedicine(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Medicines</h3>
              </Col>
              {basicDataMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">FDC Dose</th>
                <th scope="col">Acronym</th>
                {basicDataMiddleware(role, CREATE) && (
                  <th scope="col">Action</th>
                )}
              </tr>
            }
            body={medicine.medicines.map((medicine, index) => (
              <tr key={medicine.id}>
                <td>{index + 1}</td>
                <td>{medicine.name}</td>
                <td>{medicine.abbreviation}</td>
                {basicDataMiddleware(role, CREATE) && (
                  <td>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={async () => {
                        await getMedicine(medicine.id)
                        setIsUpdateOpen(true)
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(medicine.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          />
        </Card>
      </Container>

      <MedicineUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <MedicineCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  )
}

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  medicine: store.medicine,
})

export default connect(mapStateToProps, {
  getMedicines,
  getMedicine,
  deleteMedicine,
})(MedicineList)
