import React, { useState } from "react";
import { useHistory } from "react-router";
import { Button, Row, Col, Container, Card, CardHeader } from "reactstrap";
import BackBtn from "utils/backBtn";
import PresumptiveInfo from "./DetailComponents/PresumptiveInfo";
import { connect } from "react-redux";
import VDOTConclusion from "./DetailComponents/VDOTConclusion";
import DOTEnd from "./DetailComponents/DOTEnd";

const DOTPatientWithNoVolunteerDetail = ({ patient }) => {
  const history = useHistory();
  const [page, setPage] = useState("");

  const handleShowPage = () => {
    switch (page) {
      case "Presumptive_Info":
        return <PresumptiveInfo />;
        case "DOT_End":
          return <DOTEnd />;
      case "VDOT_end":
        return <VDOTConclusion />;
      default:
        return <PresumptiveInfo />;
    }
  };

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row className="mb-2">
            <Col>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage("Presumptive_Info")}
              >
                PRESUMPTIVE INFO
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage("DOT_End")}
              >
                VOT End
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => setPage("VDOT_end")}
              >
                VDOT CONCLUSION
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {!false && (
                <Button
                  color="success"
                  size="sm"
                  onClick={() =>
                    history.push(
                      `/admin/change-volunteer-create/${patient.patient?.id}`
                    )
                  }
                >
                  VOT with/without volunteer?
                </Button>
              )}
              <Button
                size="sm"
                color="warning"
                onClick={() =>
                  history.push(
                    `/admin/no-volunteer-dot-list/${patient.patient?.id}`
                  )
                }
              >
                VOT Status
              </Button>
              <Button
                size="sm"
                color="warning"
                onClick={() =>
                  history.push(`/admin/VOT-calendar/${patient.patient?.id}`)
                }
              >
                VOT Calendar
              </Button>
            </Col>
          </Row>
        </CardHeader>
        {handleShowPage()}
      </Card>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps)(DOTPatientWithNoVolunteerDetail);
