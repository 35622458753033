import { call, setAccessToken } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import {
  ACTIVITY_DASHBOARD,
  DOT_STATUS_DASHBOARD,
  PATIENT_DASHBOARD,
  SET_LOADING,
  SET_SUCCESS,
  VOLUNTEER_DASHBOARD,
  VOLUNTEER_REPORT_DASHBOARD,
} from "../type";
import { NotificationManager } from "react-notifications";

export const getVolunteerDashboard = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `volunteer-dashboard?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      dispatch({
        type: VOLUNTEER_DASHBOARD,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getPatientDashboard = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `patient-dashboard?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      dispatch({
        type: PATIENT_DASHBOARD,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getDOTStatusDashboard = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `dot-status-dashboard?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      dispatch({
        type: DOT_STATUS_DASHBOARD,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getActivityDashboard = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `activity-dashboard?${new URLSearchParams(query).toString()}`
      );
      const result = response.data;

      dispatch({
        type: ACTIVITY_DASHBOARD,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};

export const getVolunteerReportDashboard = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch({ type: SET_SUCCESS, payload: false });
    try {
      const response = await call(
        "get",
        `volunteer-report-dashboard?${new URLSearchParams(query).toString()}`
      );
      
      const result = response.data;

      dispatch({
        type: VOLUNTEER_REPORT_DASHBOARD,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_LOADING });
  };
};
