import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { updateDistrict } from 'store/actions'

const DistrictUpdate = ({
  isOpen,
  toggle,
  status,
  district,
  updateDistrict,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { name } = district.district

    setValue('name', name)
  }, [setValue, district.district])

  const onSubmit = async (data) => {
    setLoading(true)
    await updateDistrict(district.district.id, data)
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      toggle(false)
    }
  }, [status.success, toggle])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update District"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="District Name"
            register={{
              ...register('name', {
                required: 'District Name is required!',
              }),
            }}
            placeholder="Enter district name"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Update'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  district: store.district,
})

export default connect(mapStateToProps, { updateDistrict })(DistrictUpdate)
