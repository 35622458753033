import {
  CREATE_TRAINING,
  DELETE_TRAINING,
  SET_TRAINING,
  SHOW_TRAININGS,
  UPDATE_TRAINING,
} from '../type'

const initialState = {
  trainings: [],
  training: {},
  total: 0,
}

const training = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TRAININGS:
      return {
        ...state,
        trainings: action.payload.data,
        total: action.payload.total,
      }
    case CREATE_TRAINING:
      return {
        ...state,
        trainings: [action.payload, ...state.trainings],
        total: state.total + 1,
      }
    case DELETE_TRAINING:
      return {
        ...state,
        trainings: state.trainings.filter(
          (training) => training.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_TRAINING:
      return {
        ...state,
        training: action.payload,
      }
    case UPDATE_TRAINING:
      let index = state.trainings.findIndex(
        (training) => training.id === action.payload.id,
      )
      state.trainings[index] = action.payload
      if (state.training.id === action.payload.id) {
        state.training = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default training
