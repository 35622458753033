import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Button,
} from "reactstrap";
import BackBtn from "utils/backBtn";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import styles from "./VOTReport.module.css";
import { connect } from "react-redux";
import { getVOTReport } from "store/actions";
import { useHistory } from "react-router";
import queryString from "query-string";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { currentDate } from "utils/currentDate";

const VOTReport = ({ status, getVOTReport, report }) => {
  const router = useHistory();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }
    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    getVOTReport(query);
  }, [getVOTReport, router.location.search]);

  if (status.loading) {
    return <FullScreenLoading />;
  }

  let fileName = "VOT-Report";

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs="6">
              <h3 className="mb-0">VOT Report</h3>
            </Col>
            <Col className="text-right" xs="6">
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm mb-3"
                table="table-to-xls"
                filename={fileName}
                sheet="VOT-Report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
              />
            </Col>
            <Col md={4}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                value={endDate}
                min={startDate}
                max={currentDate()}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
              />
            </Col>
            <Col md={3}>
              <Button
                className="mt-3"
                size="sm"
                color="success"
                onClick={handleFilter}
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table
            className={classNames("app-table align-items-center table-fixed")}
            responsive
            id="table-to-xls"
          >
            <thead className="thead-light">
              <tr>
                <th className="fixed-cell left" rowSpan={3}>
                  Organization
                </th>
                <th className="fixed-cell left" rowSpan={3}>
                  State/Region
                </th>
                <th className="fixed-cell left" rowSpan={3}>
                  Township
                </th>
                <th className="fixed-cell left" rowSpan={3}>
                  Fiscal Year (FY)
                </th>
                <th className="fixed-cell left" rowSpan={3}>
                  Quarter
                </th>
                <th className={classNames(styles.bg)} rowSpan={2} colSpan={4}>
                  Screened for eligibility for VOT
                </th>
                <th rowSpan={2} colSpan={4}>
                  Eligible for VOT
                </th>
                <th className={classNames(styles.bg)} rowSpan={2} colSpan={4}>
                  Eligible & enrolled VOT
                </th>
                <th rowSpan={2} colSpan={4}>
                  Declined VOT
                </th>
                <th className={classNames(styles.bg)} rowSpan={3}>
                  Currently on VOT
                </th>
                <th colSpan={4}>Missed targeted VOT doses</th>
                <th className={classNames(styles.bg)} colSpan={7}>
                  Stopped VOT
                </th>
                <th rowSpan={2} colSpan={4}>
                  Eligible and enrolled VOT patient with cured/completed Tx
                </th>
              </tr>
              <tr>
                <th rowSpan={2}>
                  No. of cases with missed targeted VOT doses (Head count)
                </th>
                <th colSpan={3}>Missed doses (Frequency)</th>
                <th className={classNames(styles.bg)} rowSpan={2}>
                  No. of Stopped VOT (Head count)
                </th>
                <th className={classNames(styles.bg)} rowSpan={2}>
                  Switch to Family DOT/In-Person DOT
                </th>
                <th className={classNames(styles.bg)} rowSpan={2}>
                  Deny VOT
                </th>
                <th className={classNames(styles.bg)} rowSpan={2}>
                  Damage and Losing Phone
                </th>
                <th className={classNames(styles.bg)} rowSpan={2}>
                  Technical Issues
                </th>
                <th className={classNames(styles.bg)} rowSpan={2}>
                  Internet Connection Problem
                </th>
                <th className={classNames(styles.bg)} rowSpan={2}>
                  Others (Specify)
                </th>
              </tr>
              <tr>
                <th className={classNames(styles.bg)}>{"<15 Female"}</th>
                <th className={classNames(styles.bg)}>{"15+ Female"}</th>
                <th>{"<15 Male"}</th>
                <th>{"15+ Male"}</th>
                <th>{"<15 Female"}</th>
                <th>{"15+ Female"}</th>
                <th>{"<15 Male"}</th>
                <th>{"15+ Male"}</th>
                <th className={classNames(styles.bg)}>{"<15 Female"}</th>
                <th className={classNames(styles.bg)}>{"15+ Female"}</th>
                <th>{"<15 Male"}</th>
                <th>{"15+ Male"}</th>
                <th>No. of declined (Head count)</th>
                <th>Family</th>
                <th>Privacy</th>
                <th>Others (Specify)</th>
                <th>{"<10%"}</th>
                <th>{"10-50%"}</th>
                <th>{">50%"}</th>
                <th>{"<15 Female"}</th>
                <th>{"15+ Female"}</th>
                <th>{"<15 Male"}</th>
                <th>{"15+ Male"}</th>
              </tr>
            </thead>
            <tbody className="list">
              {report.VOTReport.map((data, index) => (
                <tr key={index}>
                  <td className="fixed-cell left">{data.organization}</td>
                  <td className="fixed-cell left">{data.region}</td>
                  <td className="fixed-cell left">{data.township}</td>
                  <td className="fixed-cell left">{data.fy}</td>
                  <td className="fixed-cell left">{data.quarter}</td>
                  <td>{data._1_less_15_female}</td>
                  <td>{data._1_greater_15_female}</td>
                  <td>{data._1_less_15_male}</td>
                  <td>{data._1_greater_15_male}</td>
                  <td>{data._2_less_15_female}</td>
                  <td>{data._2_greater_15_female}</td>
                  <td>{data._2_less_15_male}</td>
                  <td>{data._2_greater_15_male}</td>
                  <td>{data._3_less_15_female}</td>
                  <td>{data._3_greater_15_female}</td>
                  <td>{data._3_less_15_male}</td>
                  <td>{data._3_greater_15_male}</td>
                  <td>{data._4_declined}</td>
                  <td>{data._4_family}</td>
                  <td>{data._4_privacy}</td>
                  <td>{data._4_others}</td>
                  <td>{data._5_currently_on_VOT}</td>
                  <td>{data._6_head_count}</td>
                  <td>
                    {data._6_less_10
                      ? `${data._6_less_10}(${data._6_less_10_percentage}%)`
                      : "-"}
                  </td>
                  <td>
                    {data._6_between_10_50
                      ? `${data._6_between_10_50}(${data._6_between_10_50_percentage}%)`
                      : "-"}
                  </td>
                  <td>
                    {data._6_greater_50
                      ? `${data._6_greater_50}(${data._6_greater_50_percentage}%)`
                      : "-"}
                  </td>
                  <td>{data._7_no_of_stopped_VOT}</td>
                  <td>{data._7_switch_to_family_DOT_in_person_DOT}</td>
                  <td>{data._7_deny_VOT}</td>
                  <td>{data._7_damage_and_losing_phone}</td>
                  <td>{data._7_technical_issues}</td>
                  <td>{data._7_internet_connection_problem}</td>
                  <td>{data._7_others}</td>
                  <td>{data._8_less_15_female}</td>
                  <td>{data._8_greater_15_female}</td>
                  <td>{data._8_less_15_male}</td>
                  <td>{data._8_greater_15_male}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  report: store.report,
});

export default connect(mapStateToProps, {
  getVOTReport,
})(VOTReport);
