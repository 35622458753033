import { call, setAccessToken } from "../../services/api";
import { serverErrorMessage } from "../../utils/messages";
import { SHOW_PATIENT_VOT_MISSED_REPORT, SET_SECOND_LOADING } from "../type";
import { NotificationManager } from "react-notifications";

export const getPatientVotMissedReport = (id, query) => {
  return async (dispatch) => {
    dispatch({ type: SET_SECOND_LOADING });
    try {
      const result = await call(
        "get",
        `patient-vot-report/${id}?${new URLSearchParams(query).toString()}`
      );

      dispatch({
        type: SHOW_PATIENT_VOT_MISSED_REPORT,
        payload: result,
      });
    } catch (error) {
      const { status, data } = error.response;

      if (status === 401) {
        setAccessToken(null);
        NotificationManager.error(data.data.message);
      } else {
        NotificationManager.error(serverErrorMessage);
      }
    }
    dispatch({ type: SET_SECOND_LOADING });
  };
};
