import React from 'react'
import { FormGroup } from 'reactstrap'

const CustomDateInput = React.forwardRef(
  (
    {
      id,
      label,
      register,
      placeholder,
      errors,
      onChange,
      min, // '1950-01-01'
      max, // '2100-12-31'
      disabled = false,
      isRequired = true,
      type = 'date',
    },
    ref,
  ) => {
    return (
      <FormGroup>
        <label htmlFor={id}>
          {label} {isRequired && <sup className="text-danger">*</sup>}
        </label>
        <input
          ref={ref}
          id={id}
          className="form-control"
          {...register}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          disabled={disabled}
          min={min}
          max={max}
        />
        {errors[id] && (
          <span className="text-danger">{errors[id]?.message}</span>
        )}
      </FormGroup>
    )
  },
)

export default CustomDateInput
