import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getSupervisionVisit,
  getSupervisionVisits,
  deleteSupervisionVisit,
  getTownships,
} from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { paginationLimit } from 'variables/limits'
import CustomAlert from 'components/Alert/CustomAlert'
import {
  activitiesMiddleware,
  CREATE,
  isVolunteerSupervisor,
} from 'utils/middleware'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'
import BackBtn from 'utils/backBtn'
import { allMonths } from 'variables/options'
import { years } from 'variables/options'
import SupervisionVisitUpdate from './SupervisionVisitUpdate'
import SupervisionVisitCreate from './SupervisionVisitCreate'

const SupervisionVisitList = ({
  status,
  auth,
  township,
  supervisionVisit,
  getSupervisionVisit,
  deleteSupervisionVisit,
  getSupervisionVisits,
  getTownships,
}) => {
  const { role } = auth.user
  const router = useHistory()
  const [alert, setAlert] = useState(null)

  const [exportLoading, setExportLoading] = useState(false)
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1

    if (month) {
      query.month = month.value
    } else {
      delete query['month']
    }

    if (year) {
      query.year = year.value
    } else {
      delete query['year']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getSupervisionVisits(query)
  }, [getSupervisionVisits, router.location.search])

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteSupervisionVisit(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      const response = await call(
        'get',
        `supervision-visits-export?${new URLSearchParams(query).toString()}`,
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Month: data.month,
        Year: data.year,
        Number_of_Supervision_Visit_in_this_month: data.quantity,
      }))

      let fileName = 'Supervision-Visits'
      if (month) {
        fileName += `-${month.label}`
      }
      if (year) {
        fileName += `-${year.label}`
      }
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`
      }

      if (response.status === 'success') {
        excelExport(result, fileName)
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">ကွင်းဆင်းကြီးကြပ်သည့်အရေအတွက်</h3>
              </Col>
              {activitiesMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                    className="mb-3"
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={allMonths}
                  value={month}
                  onChange={(value) => setMonth(value)}
                  placeholder="Filter by month..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>

              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={years}
                  value={year}
                  onChange={(value) => setYear(value)}
                  placeholder="Filter by year..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              {!isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    className="mt-4"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}
              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-4"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  className="mt-4"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? 'Loading' : 'Export'}
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Month</th>
                <th scope="col">Year</th>
                <th scope="col">Number Of Supervision Visit</th>
                <th scope="col">Township</th>
                {activitiesMiddleware(role, CREATE) && (
                  <th scope="col">Action</th>
                )}
              </tr>
            }
            body={supervisionVisit.supervisionVisits.map(
              (supervisionVisit, index) => (
                <tr key={supervisionVisit.id}>
                  <td>{index + 1}</td>
                  <td>{supervisionVisit.month}</td>
                  <td>{supervisionVisit.year}</td>
                  <td>{supervisionVisit.quantity}</td>
                  <td>{supervisionVisit.township.name}</td>
                  {activitiesMiddleware(role, CREATE) && (
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={async () => {
                          await getSupervisionVisit(supervisionVisit.id)
                          setIsUpdateOpen(true)
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => handleDelete(supervisionVisit.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              ),
            )}
          />

          {supervisionVisit.total > paginationLimit && (
            <CustomPagination
              pageCount={supervisionVisit.total / paginationLimit}
            />
          )}
        </Card>
      </Container>

      <SupervisionVisitUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <SupervisionVisitCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  supervisionVisit: store.supervisionVisit,
  township: store.township,
})

export default connect(mapStateToProps, {
  getSupervisionVisits,
  getSupervisionVisit,
  deleteSupervisionVisit,
  getTownships,
})(SupervisionVisitList)
