import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { updateAccountEmail } from 'store/actions'

const AccountEmailUpdate = ({
  isOpen,
  toggle,
  status,
  account,
  updateAccountEmail,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { email } = account.account

    setValue('email', email)
  }, [setValue, account.account])

  const onSubmit = async (data) => {
    setLoading(true)
    await updateAccountEmail(account.account.id, {
      email: data.email,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      toggle(false)
    }
  }, [status.success, toggle])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Account Email"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="email"
            label="Email"
            register={{
              ...register('email', {
                required: 'Email is required!',
              }),
            }}
            placeholder="Enter email"
            type="email"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Update'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  account: store.account,
})

export default connect(mapStateToProps, { updateAccountEmail })(
  AccountEmailUpdate,
)
