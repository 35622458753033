import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
    CardHeader,
    CardTitle,
    Button,
} from "reactstrap";
import { getTownships } from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { useHistory } from "react-router";
import { currentDate } from "utils/currentDate";
import { getDOTStatusDashboard } from "store/actions";
import BarChart from "components/Chart/BarChart";
import BackBtn from "utils/backBtn";

const DOTStatusVirtualization = () => {
    const router = useHistory();

    const [selectedTownship, setSelectedTownship] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const { status, dashboard, township, auth } = useSelector((state) => state);
    const { dotStatusDashboard } = dashboard;
    const { with_volunteers, without_volunteers, No_DOTs } =
        dotStatusDashboard;
    const { role } = auth.user;

    const dispatch = useDispatch();

    useEffect(() => {
        const query = queryString.parse(router.location.search);
        dispatch(getDOTStatusDashboard(query));
    }, [dispatch, router.location.search]);

    useEffect(() => {
        if (role !== undefined && !isVolunteerSupervisor(role)) {
            dispatch(getTownships());
        }
    }, [dispatch, role]);

    const townships = township.townships.map((element) => ({
        value: element.id,
        label: element.name,
    }));

    const handleFilter = () => {
        const query = queryString.parse(router.location.search);

        if (selectedTownship) {
            query.township_id = selectedTownship.value;
        } else {
            delete query["township_id"];
        }

        if (startDate !== "") {
            query.start_date = startDate;
        } else {
            delete query["start_date"];
        }

        if (endDate !== "") {
            query.end_date = endDate;
        } else {
            delete query["end_date"];
        }

        router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    };

    const withVolunteers = {
        labels: with_volunteers?.map((data) => data.township_name),
        datasets: [
            {
                label: "Male",
                data: with_volunteers?.map((data) => data.male),
                backgroundColor: "rgba(255, 99, 132)",
            },
            {
                label: "Female",
                data: with_volunteers?.map((data) => data.female),
                backgroundColor: "rgba(53, 162, 235)",
            },
        ],
    };

    const withVolunteersTotal = with_volunteers?.reduce(
        (acc, curr) => acc + (curr.male + curr.female),
        0
    );

    const withoutVolunteers = {
        labels: without_volunteers?.map((data) => data.township_name),
        datasets: [
            {
                label: "Male",
                data: without_volunteers?.map((data) => data.male),
                backgroundColor: "rgba(255, 99, 132)",
            },
            {
                label: "Female",
                data: without_volunteers?.map((data) => data.female),
                backgroundColor: "rgba(53, 162, 235)",
            },
        ],
    };

    const withoutVolunteersTotal = without_volunteers?.reduce(
        (acc, curr) => acc + (curr.male + curr.female),
        0
    );

    const noDOT = {
        labels: No_DOTs?.map((data) => data.township_name),
        datasets: [
            {
                label: "Male",
                data: No_DOTs?.map((data) => data.male),
                backgroundColor: "rgba(255, 99, 132)",
            },
            {
                label: "Female",
                data: No_DOTs?.map((data) => data.female),
                backgroundColor: "rgba(53, 162, 235)",
            },
        ],
    };

    const noDOTTotal = No_DOTs?.reduce(
        (acc, curr) => acc + (curr.male + curr.female),
        0
    );

    if (status.loading) {
        return <FullScreenLoading />;
    }

    return (
        <Container className="mt-3" fluid>
            <BackBtn />
            <Card>
                <CardHeader>
                    <CardTitle tag="h3">VOT Status Virtualization</CardTitle>
                    <Row>
                        {!isVolunteerSupervisor(role) && (
                            <Col sm={3}>
                                <Select
                                    className="mt-4"
                                    options={townships}
                                    value={selectedTownship}
                                    onChange={(value) => setSelectedTownship(value)}
                                    placeholder="Filter by township..."
                                    isSearchable={true}
                                    isClearable={true}
                                />
                            </Col>
                        )}
                        <Col sm={3}>
                            <label htmlFor="start_date">Start Date</label>
                            <input
                                id="start_date"
                                className="form-control"
                                type="date"
                                onChange={(event) => setStartDate(event.target.value)}
                                value={startDate}
                            />
                        </Col>
                        <Col sm={3}>
                            <label htmlFor="end_date">End Date</label>
                            <input
                                id="end_date"
                                className="form-control"
                                type="date"
                                min={startDate}
                                max={currentDate()}
                                onChange={(event) => setEndDate(event.target.value)}
                                disabled={startDate === ""}
                                value={endDate}
                            />
                        </Col>
                        <Col sm={3}>
                            <Button
                                size="sm"
                                color="success"
                                onClick={handleFilter}
                                className="mt-4"
                            >
                                <i className="fa fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <BarChart
                        title="VOT Patient With Volunteer"
                        total={withVolunteersTotal}
                        className="DOT-patient-with-volunteer"
                        data={withVolunteers}
                    />
                    <BarChart
                        title="VOT Patient Without Volunteer"
                        total={withoutVolunteersTotal}
                        className="DOT-patient-without-volunteer"
                        data={withoutVolunteers}
                    />
                    <BarChart
                        title="No VOT Patient"
                        total={noDOTTotal}
                        className="No-DOT-patient"
                        data={noDOT}
                    />
                </CardBody>
            </Card>
        </Container>
    );
};

export default DOTStatusVirtualization;
