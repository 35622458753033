import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_COMMUNITY,
  DELETE_COMMUNITY,
  SET_COMMUNITY,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_COMMUNITIES,
  UPDATE_COMMUNITY,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getCommunities = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `communities?${new URLSearchParams(query).toString()}`,
      )

      dispatch({
        type: SHOW_COMMUNITIES,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createCommunity = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'communities', data)
      const result = response.data

      dispatch({
        type: CREATE_COMMUNITY,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Community has been created successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const getCommunity = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `communities/${id}`)
      const result = response.data

      dispatch({
        type: SET_COMMUNITY,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const updateCommunity = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call('post', `communities/${id}?_method=PUT`, data)
      const result = response.data

      dispatch({
        type: UPDATE_COMMUNITY,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Community has been updated successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const deleteCommunity = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `communities/${id}`)

      dispatch({ type: DELETE_COMMUNITY, payload: id })
      NotificationManager.success('Community has been deleted successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
