import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { getVolunteerDashboard } from "store/actions";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import { getTownships } from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import queryString from "query-string";
import { useHistory } from "react-router";
import { currentDate } from "utils/currentDate";
import BarChart from "components/Chart/BarChart";
import DoughnutChart from "components/Chart/DoughnutChart";
import PieChart from "components/Chart/PieChart";
import BackBtn from "utils/backBtn";

const VolunteerVirtualization = () => {
  const router = useHistory();

  const [selectedTownship, setSelectedTownship] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { status, dashboard, township, auth } = useSelector((state) => state);
  const { volunteerDashboard } = dashboard;
  const {
    by_township,
    by_age,
    by_education_status,
    reported_volunteers,
    trained_volunteer,
  } = volunteerDashboard;
  const { role } = auth.user;

  const dispatch = useDispatch();

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    dispatch(getVolunteerDashboard(query));
  }, [dispatch, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      dispatch(getTownships());
    }
  }, [dispatch, role]);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  const bar = {
    labels: by_township?.map((data) => data.township_name),
    datasets: [
      {
        label: "Male",
        data: by_township?.map((data) => data.male),
        backgroundColor: "rgba(255, 99, 132)",
      },
      {
        label: "Female",
        data: by_township?.map((data) => data.female),
        backgroundColor: "rgba(53, 162, 235)",
      },
    ],
  };

  const barTotal = by_township?.reduce(
    (acc, curr) => acc + (curr.male + curr.female),
    0
  );

  let volunteerAgeDataSet = [];
  if (by_age?.less_18 > 0) {
    volunteerAgeDataSet = [
      ...volunteerAgeDataSet,
      {
        data: by_age?.less_18,
        color: "rgb(255, 99, 132)",
      },
    ];
  }
  if (by_age?.between_18_30 > 0) {
    volunteerAgeDataSet = [
      ...volunteerAgeDataSet,
      {
        data: by_age?.between_18_30,
        color: "rgb(54, 162, 235)",
      },
    ];
  }
  if (by_age?.greater_30 > 0) {
    volunteerAgeDataSet = [
      ...volunteerAgeDataSet,
      {
        data: by_age?.greater_30,
        color: "rgb(255, 205, 86)",
      },
    ];
  }

  const doughnut = {
    labels: ["<18", ">18 and <30", ">30"],
    datasets: [
      {
        label: "volunteer age",
        data: volunteerAgeDataSet.map((data) => data.data),
        backgroundColor: volunteerAgeDataSet.map((data) => data.color),
        hoverOffset: 4,
      },
    ],
  };

  const doughnutTotal =
    by_age?.less_18 + by_age?.between_18_30 + by_age?.greater_30;

  let byEducationStatusDataSet = [];
  if (by_education_status?.primary > 0) {
    byEducationStatusDataSet = [
      ...byEducationStatusDataSet,
      {
        data: by_education_status?.primary,
        color: "rgb(255, 99, 132)",
      },
    ];
  }
  if (by_education_status?.secondary > 0) {
    byEducationStatusDataSet = [
      ...byEducationStatusDataSet,
      {
        data: by_education_status?.secondary,
        color: "rgb(54, 162, 235)",
      },
    ];
  }
  if (by_education_status?.higher > 0) {
    byEducationStatusDataSet = [
      ...byEducationStatusDataSet,
      {
        data: by_education_status?.higher,
        color: "rgb(255, 205, 86)",
      },
    ];
  }

  const pie = {
    labels: ["Primary", "Secondary", "Higher"],
    datasets: [
      {
        label: "education status",
        data: byEducationStatusDataSet.map((data) => data.data),
        backgroundColor: byEducationStatusDataSet.map((data) => data.color),
        hoverOffset: 4,
      },
    ],
  };

  const pieTotal =
    by_education_status?.primary +
    by_education_status?.secondary +
    by_education_status?.higher;

  const reportedVolunteersBar = {
    labels: reported_volunteers?.map((data) => data.township_name),
    datasets: [
      {
        label: "Male",
        data: reported_volunteers?.map((data) => data.male),
        backgroundColor: "rgba(255, 99, 132)",
      },
      {
        label: "Female",
        data: reported_volunteers?.map((data) => data.female),
        backgroundColor: "rgba(53, 162, 235)",
      },
    ],
  };

  const reportedVolunteersBarTotal = reported_volunteers?.reduce(
    (acc, curr) => acc + (curr.male + curr.female),
    0
  );

  const trainedVolunteerBar = {
    labels: trained_volunteer?.map((data) => data.township_name),
    datasets: [
      {
        label: "Male",
        data: trained_volunteer?.map((data) => data.male),
        backgroundColor: "rgba(255, 99, 132)",
      },
      {
        label: "Female",
        data: trained_volunteer?.map((data) => data.female),
        backgroundColor: "rgba(53, 162, 235)",
      },
    ],
  };

  const trainedVolunteerBarTotal = trained_volunteer?.reduce(
    (acc, curr) => acc + (curr.male + curr.female),
    0
  );

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader>
          <CardTitle tag="h3">Volunteer Virtualization</CardTitle>
          <Row>
            {!isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-4"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
            <Col sm={3}>
              <label htmlFor="start_date">Start Date</label>
              <input
                id="start_date"
                className="form-control"
                type="date"
                onChange={(event) => setStartDate(event.target.value)}
                value={startDate}
              />
            </Col>
            <Col sm={3}>
              <label htmlFor="end_date">End Date</label>
              <input
                id="end_date"
                className="form-control"
                type="date"
                min={startDate}
                max={currentDate()}
                onChange={(event) => setEndDate(event.target.value)}
                disabled={startDate === ""}
                value={endDate}
              />
            </Col>
            <Col sm={3}>
              <Button
                size="sm"
                color="success"
                onClick={handleFilter}
                className="mt-4"
              >
                <i className="fa fa-search" />
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <BarChart
            title="Volunteers in Township"
            total={barTotal}
            className="volunteers_in_township"
            data={bar}
          />
          <DoughnutChart
            title="Volunteers Age"
            total={doughnutTotal}
            className="volunteers_age"
            data={doughnut}
          />
          <PieChart
            title="Education Status of Volunteer"
            total={pieTotal}
            className="volunteers_education_status"
            data={pie}
          />
          <BarChart
            title="Reported Volunteer"
            total={reportedVolunteersBarTotal}
            className="reported_volunteer"
            data={reportedVolunteersBar}
          />
          <BarChart
            title="Trained Volunteer"
            total={trainedVolunteerBarTotal}
            className="trained_volunteer"
            data={trainedVolunteerBar}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default VolunteerVirtualization;
