import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import { connect } from "react-redux";
import { updatePatient } from "store/actions";
import { useParams } from "react-router";
import { yesOrNo } from "variables/options";
import { useQuery } from "hooks/useQuery";

const Eligible = ({ patient, updatePatient }) => {
  const { control, handleSubmit, setValue } = useForm();

  const { id } = useParams();

  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false);
  const [selectedEligible, setSelectedEligible] = useState(null);

  useEffect(() => {
    const { VOT_eligible } = patient.patient;

    setValue(
      "VOT_eligible",
      VOT_eligible === null
        ? null
        : {
            value: VOT_eligible,
            label: VOT_eligible,
          }
    );
    setSelectedEligible(
      VOT_eligible === null
        ? null
        : {
            value: VOT_eligible,
            label: VOT_eligible,
          }
    );
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      id,
      {
        VOT_eligible: data.VOT_eligible?.value,
      },
      "VOT-eligible",
      "VOT Eligible"
    );
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>VOT Eligible</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="VOT_eligible"
              label="VOT Eligible"
              control={control}
              rules={{
                required: "VOT Eligible is required!",
              }}
              options={yesOrNo}
              value={selectedEligible}
              setData={setSelectedEligible}
              placeholder="VOT Eligible"
              isRequired={true}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
      {fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
      </CardFooter>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(Eligible);
