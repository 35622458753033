export const SET_LOADING = "SET_LOADING";
export const SET_SECOND_LOADING = "SET_SECOND_LOADING";
export const SET_SUCCESS = "SET_SUCCESS";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SHOW_ACCOUNTS = "SHOW_ACCOUNTS";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

export const SHOW_REGIONS = "SHOW_REGIONS";
export const SET_REGION = "SET_REGION";
export const CREATE_REGION = "CREATE_REGION";
export const UPDATE_REGION = "UPDATE_REGION";
export const DELETE_REGION = "DELETE_REGION";

export const SHOW_DISTRICTS = "SHOW_DISTRICTS";
export const SET_DISTRICT = "SET_DISTRICT";
export const CREATE_DISTRICT = "CREATE_DISTRICT";
export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const DELETE_DISTRICT = "DELETE_DISTRICT";

export const SHOW_TOWNSHIPS = "SHOW_TOWNSHIPS";
export const SET_TOWNSHIP = "SET_TOWNSHIP";
export const CREATE_TOWNSHIP = "CREATE_TOWNSHIP";
export const UPDATE_TOWNSHIP = "UPDATE_TOWNSHIP";
export const DELETE_TOWNSHIP = "DELETE_TOWNSHIP";

export const SHOW_VOLUNTEERS = "SHOW_VOLUNTEERS";
export const SET_VOLUNTEER = "SET_VOLUNTEER";
export const CREATE_VOLUNTEER = "CREATE_VOLUNTEER";
export const UPDATE_VOLUNTEER = "UPDATE_VOLUNTEER";
export const DELETE_VOLUNTEER = "DELETE_VOLUNTEER";

export const SHOW_TRAININGS = "SHOW_TRAININGS";
export const SET_TRAINING = "SET_TRAINING";
export const CREATE_TRAINING = "CREATE_TRAINING";
export const UPDATE_TRAINING = "UPDATE_TRAINING";
export const DELETE_TRAINING = "DELETE_TRAINING";

export const SHOW_HES = "SHOW_HES";
export const SET_HE = "SET_HE";

export const SHOW_VOLUNTEER_HANDOUTS = "SHOW_VOLUNTEER_HANDOUTS";
export const SET_VOLUNTEER_HANDOUT = "SET_VOLUNTEER_HANDOUT";
export const CREATE_VOLUNTEER_HANDOUT = "CREATE_VOLUNTEER_HANDOUT";
export const UPDATE_VOLUNTEER_HANDOUT = "UPDATE_VOLUNTEER_HANDOUT";

export const SHOW_PATIENT_HANDOUTS = "SHOW_PATIENT_HANDOUTS";
export const SET_PATIENT_HANDOUT = "SET_PATIENT_HANDOUT";
export const CREATE_PATIENT_HANDOUT = "CREATE_PATIENT_HANDOUT";
export const UPDATE_PATIENT_HANDOUT = "UPDATE_PATIENT_HANDOUT";

export const SHOW_TAKE_MEDICINES = "SHOW_TAKE_MEDICINES";
export const SET_TAKE_MEDICINE = "SET_TAKE_MEDICINE";

export const SHOW_PATIENTS = "SHOW_PATIENTS";
export const SET_PATIENT = "SET_PATIENT";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const LATEST_PRESUMPTIVE_TB_NUMBER = "LATEST_PRESUMPTIVE_TB_NUMBER";

export const SHOW_CHANGE_VOLUNTEERS = "SHOW_CHANGE_VOLUNTEERS";
export const SET_CHANGE_VOLUNTEER = "SET_CHANGE_VOLUNTEER";
export const DELETE_CHANGE_VOLUNTEER = "DELETE_CHANGE_VOLUNTEER";

export const SHOW_PATIENT_MEDICINES = "SHOW_PATIENT_MEDICINES";
export const SET_PATIENT_MEDICINE = "SET_PATIENT_MEDICINE";
export const CREATE_PATIENT_MEDICINE = "CREATE_PATIENT_MEDICINE";
export const UPDATE_PATIENT_MEDICINE = "UPDATE_PATIENT_MEDICINE";
export const DELETE_PATIENT_MEDICINE = "DELETE_PATIENT_MEDICINE";

export const SHOW_FU_SPUTUM_EXAMINITIONS = "SHOW_FU_SPUTUM_EXAMINITIONS";
export const SET_FU_SPUTUM_EXAMINITION = "SET_FU_SPUTUM_EXAMINITION";
export const CREATE_FU_SPUTUM_EXAMINITION = "CREATE_FU_SPUTUM_EXAMINITION";
export const UPDATE_FU_SPUTUM_EXAMINITION = "UPDATE_FU_SPUTUM_EXAMINITION";
export const DELETE_FU_SPUTUM_EXAMINITION = "DELETE_FU_SPUTUM_EXAMINITION";

export const SHOW_SUPERVISION_VISITS = "SHOW_SUPERVISION_VISITS";
export const SET_SUPERVISION_VISIT = "SET_SUPERVISION_VISIT";
export const CREATE_SUPERVISION_VISIT = "CREATE_SUPERVISION_VISIT";
export const UPDATE_SUPERVISION_VISIT = "UPDATE_SUPERVISION_VISIT";
export const DELETE_SUPERVISION_VISIT = "DELETE_SUPERVISION_VISIT";

export const SHOW_DOTS = "SHOW_DOTS";
export const SET_DOT = "SET_DOT";
export const CREATE_DOTS = "CREATE_DOTS";
export const CHANGE_STATUS_NOAPP = "CHANGE_STATUS_NOAPP";
export const DELETE_DOT = "DELETE_DOT";
export const CREATE_DOTS_DASHBOARD = "CREATE_DOTS_DASHBOARD";

export const SHOW_CONTACT_TRACIES = "SHOW_CONTACT_TRACIES";
export const SET_CONTACT_TRACY = "SET_CONTACT_TRACY";

export const SHOW_COMMUNITIES = "SHOW_COMMUNITIES";
export const SET_COMMUNITY = "SET_COMMUNITY";
export const CREATE_COMMUNITY = "CREATE_COMMUNITY";
export const UPDATE_COMMUNITY = "UPDATE_COMMUNITY";
export const DELETE_COMMUNITY = "DELETE_COMMUNITY";

export const SHOW_MEDICINES = "SHOW_MEDICINES";
export const SET_MEDICINE = "SET_MEDICINE";
export const CREATE_MEDICINE = "CREATE_MEDICINE";
export const UPDATE_MEDICINE = "UPDATE_MEDICINE";
export const DELETE_MEDICINE = "DELETE_MEDICINE";

export const VOLUNTEER_REPORT = "VOLUNTEER_REPORT";
export const FACILITATOR_REPORT = "FACILITATOR_REPORT";
export const CBTBC_REPORT = "CBTBC_REPORT";
export const AIS_REPORT = "AIS_REPORT";
export const VOT_REPORT = "VOT_REPORT";

export const VOLUNTEER_DASHBOARD = "DASHBOARD_DASHBOARD";
export const PATIENT_DASHBOARD = "PATIENT_DASHBOARD";
export const DOT_STATUS_DASHBOARD = "DOT_STATUS_DASHBOARD";
export const ACTIVITY_DASHBOARD = "ACTIVITY_DASHBOARD";
export const VOLUNTEER_REPORT_DASHBOARD = "VOLUNTEER_REPORT_DASHBOARD";

export const SHOW_PATIENT_VOT_MISSED_REPORT = "SHOW_PATIENT_VOT_MISSED_REPORT";
