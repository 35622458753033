import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import CustomPagination from 'components/Pagination/Pagination'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import TakeMedicineDetail from './TakeMedicineDetail'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getTakeMedicines,
  getTakeMedicine,
  getVolunteers,
  getTownships,
} from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { paginationLimit } from 'variables/limits'
import {
  isVolunteerSupervisor,
  activitiesMiddleware,
  EXPORT,
} from 'utils/middleware'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'
import BackBtn from 'utils/backBtn'

const TakeMedicineList = ({
  status,
  auth,
  takeMedicine,
  township,
  volunteer,
  getTakeMedicines,
  getTakeMedicine,
  getVolunteers,
  getTownships,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [exportLoading, setExportLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const [monthYear, setMonthYear] = useState('')
  const [selectedTownship, setSelectedTownship] = useState(null)
  const [selectedVolunteer, setSelectedVolunteer] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: `${element.name}(${element.code})`,
  }))

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (monthYear !== '') {
      query.month_year = monthYear
    } else {
      delete query['month_year']
    }

    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value
    } else {
      delete query['volunteer_id']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getTakeMedicines(query)
  }, [getTakeMedicines, router.location.search])

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  useEffect(() => {
    getVolunteers()
  }, [getVolunteers, role])

  if (status.loading) {
    return <FullScreenLoading />
  }

  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      const response = await call(
        'get',
        `take-medicines-export?${new URLSearchParams(query).toString()}`,
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Volunteer: `${data.volunteer?.name}(${data.volunteer?.code})`,
        Township: data.volunteer?.township?.name,
        Date: data.date,
        'တာဝန်ခံကျန်းမာရေး ဝန်ထမ်းလက်မှတ်': data.health_worker_sign,
        'ဆေးတိုက်ကျွေးသူလက်မှတ်': data.volunteer_sign,
      }))

      let fileName = 'Take-Medicines'
      if(monthYear) {
        fileName += `-${monthYear}`
      }
      if(selectedVolunteer) {
        fileName += `-${selectedVolunteer.label}`
      }
      if(selectedTownship) {
        fileName += `-${selectedTownship.label}`
      }

      if (response.status === 'success') {
        excelExport(result, fileName)
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col sm={6}>
                <h3 className="mb-0">တီဘီဆေးရရှိမှုစာရင်း</h3>
              </Col>
              {activitiesMiddleware(role, EXPORT) && (
                <Col sm={6} className="text-right">
                  <Button
                    size="sm"
                    color="info"
                    className="mb-3"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? 'Loading' : 'Export'}
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={3}>
                <label htmlFor="month_year">Month-Year</label>
                <input
                  id="month_year"
                  className="form-control"
                  type="month"
                  value={monthYear}
                  onChange={(event) => setMonthYear(event.target.value)}
                />
              </Col>
              {role !== undefined && !isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <Select
                    className="mt-4"
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}
              <Col sm={4}>
                <Select
                  className="mt-4"
                  options={volunteers}
                  value={selectedVolunteer}
                  onChange={(value) => setSelectedVolunteer(value)}
                  placeholder="Filter by volunteer..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
              <Col sm={2}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-4"
                >
                  <i className="fa fa-search" />
                </Button>
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th className="fixed-cell left" scope="col">
                  စဥ်
                </th>
                <th className="fixed-cell left" scope="col">
                  အမည်
                </th>
                <th scope="col">နေ့စွဲ</th>
                <th scope="col">မြို့နယ်</th>
                <th scope="col">တာဝန်ခံကျန်းမာရေး ဝန်ထမ်းလက်မှတ်</th>
                <th scope="col">ဆေးတိုက်ကျွေးသူလက်မှတ်</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={takeMedicine.takeMedicines.map((takeMedicine, index) => (
              <tr key={takeMedicine.id}>
                <td className="fixed-cell left">{index + 1}</td>
                <td className="fixed-cell left">
                  {takeMedicine.volunteer.name}
                </td>
                <td>{takeMedicine.date}</td>
                <td>{takeMedicine.volunteer?.township?.name}</td>
                <td>
                  <a href={takeMedicine.health_worker_sign} target="_black">
                    Click To View
                  </a>
                </td>
                <td>
                  <a href={takeMedicine.volunteer_sign} target="_black">
                    Click To View
                  </a>
                </td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={async () => {
                      await getTakeMedicine(takeMedicine.id)
                      setIsOpen(true)
                    }}
                  >
                    Detail
                  </Button>
                </td>
              </tr>
            ))}
          />

          {takeMedicine.total > paginationLimit && (
            <CustomPagination
              pageCount={takeMedicine.total / paginationLimit}
            />
          )}
        </Card>
      </Container>

      <TakeMedicineDetail isOpen={isOpen} toggle={setIsOpen} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  takeMedicine: store.takeMedicine,
})

export default connect(mapStateToProps, {
  getTakeMedicines,
  getTakeMedicine,
  getVolunteers,
  getTownships,
})(TakeMedicineList)
