import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_FU_SPUTUM_EXAMINITION,
  DELETE_FU_SPUTUM_EXAMINITION,
  SET_LOADING,
  SET_FU_SPUTUM_EXAMINITION,
  SET_SUCCESS,
  SHOW_FU_SPUTUM_EXAMINITIONS,
  UPDATE_FU_SPUTUM_EXAMINITION,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getFUSputumExaminitions = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const response = await call('get', `fu-sputum-examinitions/${id}`)
      const result = response.data

      dispatch({
        type: SHOW_FU_SPUTUM_EXAMINITIONS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getFUSputumExaminition = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `fu-sputum-examinition/${id}`)
      const result = response.data

      dispatch({
        type: SET_FU_SPUTUM_EXAMINITION,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createFUSputumExaminition = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'fu-sputum-examinitions', data)
      const result = response.data

      dispatch({
        type: CREATE_FU_SPUTUM_EXAMINITION,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success(
        'F/U Sputum Examinition has been created successfully!',
      )
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const updateFUSputumExaminition = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call(
        'post',
        `fu-sputum-examinitions/${id}?_method=PUT`,
        data,
      )
      const result = response.data

      dispatch({
        type: UPDATE_FU_SPUTUM_EXAMINITION,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success(
        'F/U Sputum Examinition has been updated successfully!',
      )
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const deleteFUSputumExaminition = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `fu-sputum-examinitions/${id}`)

      dispatch({ type: DELETE_FU_SPUTUM_EXAMINITION, payload: id })
      NotificationManager.success(
        'F/U Sputum Examinition has been deleted successfully!',
      )
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
