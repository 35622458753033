import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Container, Card, CardHeader, Row, Col, Button } from 'reactstrap'
import BackBtn from 'utils/backBtn'
import { years } from 'variables/options'
import { reportMonths } from 'variables/options'
import styles from './AISReport.module.css'
import { connect } from 'react-redux'
import { getTownships } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { isVolunteerSupervisor } from 'utils/middleware'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import classnames from 'classnames'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import { getAISReport } from 'store/actions'

const AISReport = ({
  status,
  auth,
  township,
  report,
  getTownships,
  getAISReport,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    if (month) {
      query.month = month.value
    } else {
      delete query['month']
    }

    if (year) {
      query.year = year.value
    } else {
      delete query['year']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    getAISReport(query)
  }, [getAISReport, router.location.search])

  if (status.loading) {
    return <FullScreenLoading />
  }

  const {
    _1_female,
    _1_greater_than_equal_5_and_less_than_15_female,
    _1_greater_than_equal_5_and_less_than_15_male,
    _1_greater_than_equal_15_female,
    _1_greater_than_equal_15_male,
    _1_less_than_5_female,
    _1_less_than_5_male,
    _1_male,
    _1_total,
    _2_female,
    _2_greater_than_equal_5_and_less_than_15_female,
    _2_greater_than_equal_5_and_less_than_15_male,
    _2_greater_than_equal_15_female,
    _2_greater_than_equal_15_male,
    _2_less_than_5_female,
    _2_less_than_5_male,
    _2_male,
    _2_total,
    _3_female,
    _3_greater_than_equal_5_and_less_than_15_female,
    _3_greater_than_equal_5_and_less_than_15_male,
    _3_greater_than_equal_15_female,
    _3_greater_than_equal_15_male,
    _3_less_than_5_female,
    _3_less_than_5_male,
    _3_male,
    _3_total,
    _4_female,
    _4_male,
    _4_total,
    _5_female,
    _5_male,
    _5_total,
    _6_female,
    _6_male,
    _6_total,
  } = report.AISReport

  let fileName = 'AIS-Report'
  if (month) {
    fileName += `-${month.label}`
  }
  if (year) {
    fileName += `-${year.label}`
  }
  if (selectedTownship) {
    fileName += `-${selectedTownship.label}`
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">AIS Report</h3>
            </Col>
            <Col className="text-right" xs={6}>
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm"
                table="table-to-xls"
                filename={fileName}
                sheet="AIS-Report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-3"
                options={reportMonths}
                value={month}
                onChange={(value) => setMonth(value)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={year}
                onChange={(value) => setYear(value)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            {role !== undefined && !isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
          </Row>
        </CardHeader>

        <table id="table-to-xls" className={styles.styled_table}>
          <thead>
            <tr>
              <th rowSpan={2} scope="col">
                No
              </th>
              <th rowSpan={2} scope="col">
                Indicators
              </th>
              <th></th>
              <th className="text-center" colSpan={3} scope="col">
                Male
              </th>
              <th className="text-center" colSpan={3} scope="col">
                Female
              </th>
              <th className="text-center" rowSpan={2}>
                Male
              </th>
              <th className="text-center" rowSpan={2}>
                Female
              </th>
              <th className="text-center" rowSpan={2}>
                Total
              </th>
            </tr>
            <tr>
              <th></th>
              <th className="text-center" scope="col">
                {'<5'}
              </th>
              <th className="text-center" scope="col">
                {'>=5 and <15'}
              </th>
              <th className="text-center" scope="col">
                {'>=15'}
              </th>
              <th className="text-center" scope="col">
                {'<5'}
              </th>
              <th className="text-center" scope="col">
                {'>=5 and <15'}
              </th>
              <th className="text-center" scope="col">
                {'>=15'}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>AF-4 no of presumptive TB identified</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    All Patient List
                  </span>
                </i>
              </td>
              <td className="text-center">{_1_less_than_5_male}</td>
              <td className="text-center">
                {_1_greater_than_equal_5_and_less_than_15_male}
              </td>
              <td className="text-center">{_1_greater_than_equal_15_male}</td>
              <td className="text-center">{_1_less_than_5_female}</td>
              <td className="text-center">
                {_1_greater_than_equal_5_and_less_than_15_female}
              </td>
              <td className="text-center">{_1_greater_than_equal_15_female}</td>
              <td className="text-center">{_1_male}</td>
              <td className="text-center">{_1_female}</td>
              <td className="text-center">{_1_total}</td>
            </tr>
            <tr>
              <td>2</td>
              <td>AF-5- no of presumptive case tested/evaluation for TB</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Sputum{' or-> '}Other Investigation equal done
                  </span>
                </i>
              </td>
              <td className="text-center">{_2_less_than_5_male}</td>
              <td className="text-center">
                {_2_greater_than_equal_5_and_less_than_15_male}
              </td>
              <td className="text-center">{_2_greater_than_equal_15_male}</td>
              <td className="text-center">{_2_less_than_5_female}</td>
              <td className="text-center">
                {_2_greater_than_equal_5_and_less_than_15_female}
              </td>
              <td className="text-center">{_2_greater_than_equal_15_female}</td>
              <td className="text-center">{_2_male}</td>
              <td className="text-center">{_2_female}</td>
              <td className="text-center">{_2_total}</td>
            </tr>
            <tr>
              <td>3</td>
              <td>DT-1- Case notification</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Patient is TB or DRTB
                  </span>
                </i>
              </td>
              <td className="text-center">{_3_less_than_5_male}</td>
              <td className="text-center">
                {_3_greater_than_equal_5_and_less_than_15_male}
              </td>
              <td className="text-center">{_3_greater_than_equal_15_male}</td>
              <td className="text-center">{_3_less_than_5_female}</td>
              <td className="text-center">
                {_3_greater_than_equal_5_and_less_than_15_female}
              </td>
              <td className="text-center">{_3_greater_than_equal_15_female}</td>
              <td className="text-center">{_3_male}</td>
              <td className="text-center">{_3_female}</td>
              <td className="text-center">{_3_total}</td>
            </tr>
            <tr>
              <td>4</td>
              <td>No. of VOT patients with volunteer</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    VOT Patient With Volunteer
                  </span>
                </i>
              </td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td className="text-center">{_4_male}</td>
              <td className="text-center">{_4_female}</td>
              <td className="text-center">{_4_total}</td>
            </tr>
            <tr>
              <td>5</td>
              <td>No. of VOT patients without volunteer</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    VOT Patient Without Volunteer
                  </span>
                </i>
              </td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td className="text-center">{_5_male}</td>
              <td className="text-center">{_5_female}</td>
              <td className="text-center">{_5_total}</td>
            </tr>
            <tr>
              <td>6</td>
              <td>No. of VOT patients with treatment outcomes</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    VOT Start Date and Treatment Outcome is not NULL
                  </span>
                </i>
              </td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td
                style={{ backgroundColor: '#AFAAAA' }}
                className="text-center"
              ></td>
              <td className="text-center">{_6_male}</td>
              <td className="text-center">{_6_female}</td>
              <td className="text-center">{_6_total}</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Container>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  report: store.report,
})

export default connect(mapStateToProps, {
  getTownships,
  getAISReport,
})(AISReport)
