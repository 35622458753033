import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Container, Card, CardHeader, Row, Col, Button } from 'reactstrap'
import BackBtn from 'utils/backBtn'
import { years } from 'variables/options'
import { reportMonths } from 'variables/options'
import styles from './VolunteerReport.module.css'
import { connect } from 'react-redux'
import { getVolunteers, getTownships, getVolunteerReport } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import { isVolunteerSupervisor } from 'utils/middleware'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import classnames from 'classnames'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

const VolunteerReport = ({
  status,
  auth,
  township,
  volunteer,
  report,
  getVolunteers,
  getTownships,
  getVolunteerReport,
}) => {
  const { role } = auth.user
  const router = useHistory()

  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)
  const [selectedVolunteer, setSelectedVolunteer] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: `${element.name}(${element.code})`,
  }))

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships()
    }
  }, [getTownships, role])

  useEffect(() => {
    getVolunteers()
  }, [getVolunteers, role])

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    if (selectedVolunteer) {
      query.volunteer_id = selectedVolunteer.value
    } else {
      delete query['volunteer_id']
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value
    } else {
      delete query['township_id']
    }

    if (month) {
      query.month = month.value
    } else {
      delete query['month']
    }

    if (year) {
      query.year = year.value
    } else {
      delete query['year']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    getVolunteerReport(query)
  }, [getVolunteerReport, router.location.search])

  if (status.loading) {
    return <FullScreenLoading />
  }

  const {
    _1_total,
    _2_male,
    _2_female,
    _2_total,
    _3_NTP_male,
    _3_NTP_female,
    _3_NTP_total,
    _3_PPNGOClinic_male,
    _3_PPNGOClinic_female,
    _3_PPNGOClinic_total,
    _3_MCXRClinic_male,
    _3_MCXRClinic_female,
    _3_MCXRClinic_total,
    _4_NTP_male,
    _4_NTP_female,
    _4_NTP_total,
    _4_PPNGOClinic_male,
    _4_PPNGOClinic_female,
    _4_PPNGOClinic_total,
    _4_MCXRClinic_male,
    _4_MCXRClinic_female,
    _4_MCXRClinic_total,
    _5_NTP_male,
    _5_NTP_female,
    _5_NTP_total,
    _5_PPNGOClinic_male,
    _5_PPNGOClinic_female,
    _5_PPNGOClinic_total,
    _5_MCXRClinic_male,
    _5_MCXRClinic_female,
    _5_MCXRClinic_total,
    _6_NTP_male,
    _6_NTP_female,
    _6_NTP_total,
    _6_PPNGOClinic_male,
    _6_PPNGOClinic_female,
    _6_PPNGOClinic_total,
    _6_MCXRClinic_male,
    _6_MCXRClinic_female,
    _6_MCXRClinic_total,
    _7_male,
    _7_female,
    _7_total,
  } = report.volunteerReport

  let fileName = 'Volunteer-Report'
  if (selectedVolunteer) {
    fileName += `-${selectedVolunteer.label}`
  }
  if (month) {
    fileName += `-${month.label}`
  }
  if (year) {
    fileName += `-${year.label}`
  }
  if (selectedTownship) {
    fileName += `-${selectedTownship.label}`
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row>
            <Col xs={6}>
              <h3 className="mb-0">Volunteer Report</h3>
            </Col>
            <Col className="text-right" xs={6}>
              <Button size="sm" color="success" onClick={handleFilter}>
                <i className="fa fa-search" />
              </Button>
              <ReactHTMLTableToExcel
                className="btn btn-info btn-sm"
                table="table-to-xls"
                filename={fileName}
                sheet="Volunteer-Report"
                buttonText="Export"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Select
                className="mt-3"
                options={volunteers}
                value={selectedVolunteer}
                onChange={(value) => setSelectedVolunteer(value)}
                placeholder="Filter by volunteer..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={reportMonths}
                value={month}
                onChange={(value) => setMonth(value)}
                placeholder="Filter by month..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            <Col md={3}>
              <Select
                className="mt-3"
                options={years}
                value={year}
                onChange={(value) => setYear(value)}
                placeholder="Filter by year..."
                isSearchable={true}
                isClearable={true}
              />
            </Col>
            {role !== undefined && !isVolunteerSupervisor(role) && (
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={townships}
                  value={selectedTownship}
                  onChange={(value) => setSelectedTownship(value)}
                  placeholder="Filter by township..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            )}
          </Row>
        </CardHeader>

        <table id="table-to-xls" className={styles.styled_table}>
          <thead>
            <tr>
              <th rowSpan={2} scope="col">
                စဉ်
              </th>
              <th rowSpan={2} colSpan={2} scope="col">
                အစီရင်ခံရမည့် အချက်အလက်များ
              </th>
              <th className="text-center" colSpan={4} scope="col">
                အရေအတွက်
              </th>
            </tr>
            <tr>
              <th></th>
              <th className="text-center" scope="col">
                ကျား
              </th>
              <th className="text-center" scope="col">
                မ
              </th>
              <th className="text-center" scope="col">
                ပေါင်း
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center" colSpan={6}>
                ကျန်းမာရေးပညာပေးခြင်း
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td colSpan={2}>
                ပြည်သူလူထုအတွင်း ကျန်းမာရေးပညာပေးသည့် အကြိမ်အရေအတွက်
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    HE အရေအတွက်
                  </span>
                </i>
              </td>
              <td style={{ backgroundColor: '#AFAAAA' }}></td>
              <td style={{ backgroundColor: '#AFAAAA' }}></td>
              <td className="text-center">{_1_total}</td>
            </tr>
            <tr>
              <td>2</td>
              <td colSpan={2}>
                ပြီးခဲ့သောလအတွင်း ပြည်သူလူထုအတွင်း ကျန်းမာရေးပညာပေးလုပ်ငန်းများ
                ဆောင်ရွက်ရာတွင် တက်ရောက်ခဲ့သူစုစုပေါင်း
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    HE ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td className="text-center">{_2_male}</td>
              <td className="text-center">{_2_female}</td>
              <td className="text-center">{_2_total}</td>
            </tr>
            <tr>
              <td className="text-center" colSpan={6}>
                တီဘီဖြစ်နိုင်ခြေရှိသူ ရှာဖွေခြင်း
              </td>
            </tr>
            <tr>
              <td rowSpan={3}>3</td>
              <td rowSpan={3}>
                ပြည်သူလူထုအတွင်းမှ ညွှန်းပို့သည့် တီဘီရောဂါဖြစ်နိုင်ခြေရှိသူ
                ဦးရေ (အတူနေ/မိသားစု မပါဝင်ပါ)
              </td>
              <td>တီဘီရောဂါတိုက်ဖျက်ရေးဌာန</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    HE နှင့် Community ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td className="text-center">{_3_NTP_male}</td>
              <td className="text-center">{_3_NTP_female}</td>
              <td className="text-center">{_3_NTP_total}</td>
            </tr>
            <tr>
              <td>မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း</td>
              <td></td>
              <td className="text-center">{_3_PPNGOClinic_male}</td>
              <td className="text-center">{_3_PPNGOClinic_female}</td>
              <td className="text-center">{_3_PPNGOClinic_total}</td>
            </tr>
            <tr>
              <td>ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း</td>
              <td></td>
              <td className="text-center">{_3_MCXRClinic_male}</td>
              <td className="text-center">{_3_MCXRClinic_female}</td>
              <td className="text-center">{_3_MCXRClinic_total}</td>
            </tr>
            <tr>
              <td rowSpan={3}>4</td>
              <td rowSpan={3}>
                ညွှန်းပို့သူများအနက်မှ တွေ့ရှိရသည့် တီဘီလူနာဦးရေ (အတူနေ/မိသားစု
                မပါဝင်ပါ)
              </td>
              <td>တီဘီရောဂါတိုက်ဖျက်ရေးဌာန</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Patient ရဲ့ case founded activity(Community, HE) နှင့် TB
                    ဖြစ်သည့် ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td className="text-center">{_4_NTP_male}</td>
              <td className="text-center">{_4_NTP_female}</td>
              <td className="text-center">{_4_NTP_total}</td>
            </tr>
            <tr>
              <td>မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း</td>
              <td></td>
              <td className="text-center">{_4_PPNGOClinic_male}</td>
              <td className="text-center">{_4_PPNGOClinic_female}</td>
              <td className="text-center">{_4_PPNGOClinic_total}</td>
            </tr>
            <tr>
              <td>ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း</td>
              <td></td>
              <td className="text-center">{_4_MCXRClinic_male}</td>
              <td className="text-center">{_4_MCXRClinic_female}</td>
              <td className="text-center">{_4_MCXRClinic_total}</td>
            </tr>
            <tr>
              <td rowSpan={3}>5</td>
              <td rowSpan={3}>
                တီဘီရောဂါရှိမရှိ စစ်ဆေးရန် ညွှန်းပို့သည့် တီဘီလူနာ၏ အတူနေသူဦးရေ
              </td>
              <td>တီဘီရောဂါတိုက်ဖျက်ရေးဌာန</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    CT ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td className="text-center">{_5_NTP_male}</td>
              <td className="text-center">{_5_NTP_female}</td>
              <td className="text-center">{_5_NTP_total}</td>
            </tr>
            <tr>
              <td>မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း</td>
              <td></td>
              <td className="text-center">{_5_PPNGOClinic_male}</td>
              <td className="text-center">{_5_PPNGOClinic_female}</td>
              <td className="text-center">{_5_PPNGOClinic_total}</td>
            </tr>
            <tr>
              <td>ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း</td>
              <td></td>
              <td className="text-center">{_5_MCXRClinic_male}</td>
              <td className="text-center">{_5_MCXRClinic_female}</td>
              <td className="text-center">{_5_MCXRClinic_total}</td>
            </tr>
            <tr>
              <td rowSpan={3}>6</td>
              <td rowSpan={3}>
                လူနာနှင့် အတူနေသူထဲမှ တွေ့ရှိသည့် တီဘီလူနာဦးရေ
              </td>
              <td>တီဘီရောဂါတိုက်ဖျက်ရေးဌာန</td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Patient ရဲ့ case founded activity(CT) နှင့် TB ဖြစ်သည့် ကျား
                    မ ပေါင်း
                  </span>
                </i>
              </td>
              <td className="text-center">{_6_NTP_male}</td>
              <td className="text-center">{_6_NTP_female}</td>
              <td className="text-center">{_6_NTP_total}</td>
            </tr>
            <tr>
              <td>မိတ်ဖက် ပြင်ပ/NGO ဆေးခန်း</td>
              <td></td>
              <td className="text-center">{_6_PPNGOClinic_male}</td>
              <td className="text-center">{_6_PPNGOClinic_female}</td>
              <td className="text-center">{_6_PPNGOClinic_total}</td>
            </tr>
            <tr>
              <td>ရွေ့လျားတီဘီရှာဖွေရေးဆေးခန်း</td>
              <td></td>
              <td className="text-center">{_6_MCXRClinic_male}</td>
              <td className="text-center">{_6_MCXRClinic_female}</td>
              <td className="text-center">{_6_MCXRClinic_total}</td>
            </tr>
            <tr>
              <td className="text-center" colSpan={6}>
                တီဘီဆေး တိုက်ကျွေးခြင်း
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td colSpan={2}>
                အစီရင်ခံသည့် ကာလအတွင်း စေတနာ့ဝန်ထမ်းမှ တီဘီဆေးစတင်တိုက်ကျွေးသူ
                တီဘီလူနာဦးရေ
              </td>
              <td>
                <i
                  className={classnames(
                    'fa fa-question-circle',
                    styles.tooltip,
                  )}
                >
                  <span className={classnames(styles.tooltiptext)}>
                    Patient ရဲ့ DOT start date ရှိပြီး DOT end date မရှိသည့်
                    ကျား မ ပေါင်း
                  </span>
                </i>
              </td>
              <td className="text-center">{_7_male}</td>
              <td className="text-center">{_7_female}</td>
              <td className="text-center">{_7_total}</td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Container>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  report: store.report,
})

export default connect(mapStateToProps, {
  getVolunteers,
  getTownships,
  getVolunteerReport,
})(VolunteerReport)
