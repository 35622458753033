import {
  CREATE_REGION,
  DELETE_REGION,
  SET_REGION,
  SHOW_REGIONS,
  UPDATE_REGION,
} from '../type'

const initialState = {
  regions: [],
  region: {},
}

const region = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_REGIONS:
      return {
        ...state,
        regions: action.payload,
      }
    case CREATE_REGION:
      return {
        ...state,
        regions: [action.payload, ...state.regions],
      }
    case DELETE_REGION:
      return {
        ...state,
        regions: state.regions.filter((region) => region.id !== action.payload),
      }
    case SET_REGION:
      return {
        ...state,
        region: action.payload,
      }
    case UPDATE_REGION:
      let index = state.regions.findIndex(
        (region) => region.id === action.payload.id,
      )
      state.regions[index] = action.payload
      if (state.region.id === action.payload.id) {
        state.region = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default region
