export const barChartConfig = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      anchor: "end",
      align: "top",
      offset: 10, 
      font: {
        size: 20,
      },
    },
  },
};
export const lineChartConfig = {
  elements: {
    line: {
      tension: 0, // Set line tension to 0 for straight lines
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        stacked: false,
      },
    ],
  },
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      font: {
        size: 20,
        lineHeight: 10,
      },
    },
  },
};

export const pieChartOptions = {
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const total = dataset.data.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        const value = dataset.data[tooltipItem.index];
        const percentage = ((value / total) * 100).toFixed(2) + "%";
        return `${data.labels[tooltipItem.index]}: ${value} (${percentage})`;
      },
    },
  },
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      font: {
        size: 20,
      },
      formatter: (value, context) => {
        const dataset = context.chart.data.datasets[0];
        const total = dataset.data.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        if (total > 0) {
          const percentage = ((value * 100) / total).toFixed(2) + "%";
          return `${value}, ${percentage}`;
        } else {
          return "There is no data in this category";
        }
      },
    },
  },
};

export const doughnutChartOptions = {
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const dataset = data.datasets[tooltipItem.datasetIndex];
        const total = dataset.data.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        const value = dataset.data[tooltipItem.index];
        const percentage = ((value / total) * 100).toFixed(2) + "%";
        return `${data.labels[tooltipItem.index]}: ${value} (${percentage})`;
      },
    },
  },
  plugins: {
    datalabels: {
      display: true,
      color: "black",
      font: {
        size: 20,
      },
      formatter: (value, context) => {
        const dataset = context.chart.data.datasets[0];
        const total = dataset.data.reduce(
          (accumulator, currentValue) => accumulator + currentValue
        );
        if (total > 0) {
          const percentage = ((value * 100) / total).toFixed(2) + "%";
          return `${value}, ${percentage}`;
        } else {
          return "There is no data in this category";
        }
      },
    },
  },
};
