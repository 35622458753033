import React from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
} from 'reactstrap'

const DashboardCard = ({ title, onClick }) => {
    return (
        <Card className="bg-gradient-success border-0">
            <CardBody>
                <Row>
                    <div className="col">
                        <CardTitle
                            className="text-uppercase text-muted mb-0 text-white"
                            tag="h3"
                        >
                            {title}
                        </CardTitle>
                    </div>
                    <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                            <i className="fas fa-user-friends" />
                        </div>
                    </Col>
                </Row>
                <p className="mt-3 mb-0 text-sm">
                    <Button
                        size="sm"
                        onClick={onClick}
                    >
                        Detail
                    </Button>
                </p>
            </CardBody>
        </Card>
    )
}

export default DashboardCard