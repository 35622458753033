import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import { connect } from "react-redux";
import CustomSelect from "components/Inputs/CustomSelect";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { currentDate } from "utils/currentDate";
import { TBStatus } from "variables/options";
import CustomInput from "components/Inputs/CustomInput";
import { treatmentRegimens, treatmentStatus } from "variables/options";
import { definedTBTypes } from "variables/options";
import { typeOfTBPateints } from "variables/options";
import { smokingStatus } from "variables/options";
import { DMStatus } from "variables/options";
import { dones } from "variables/options";
import { HIVResults } from "variables/options";
import { yesOrNo } from "variables/options";
import { no } from "variables/options";
import { treatmentOutcomes } from "variables/options";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { updatePatient } from "store/actions";
import { useParams } from "react-router";
// import { useQuery } from "hooks/useQuery";

const TBConclusion = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { id } = useParams();

  // const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false);
  const [selectedTBStatus, setSelectedTBStatus] = useState(null);
  const [selectedTreatmentStatus, setSelectedTreatmentStatus] = useState(null);
  const [treatmentRegimen, setTreatmentRegimen] = useState(null);
  const [definedTBType, setDefinedTBType] = useState(null);
  const [typeOfTBPatient, setTypeOfTBPatient] = useState(null);
  const [selectedSmokingStatus, setSelectedSmokingStatus] = useState(null);
  const [selectedDBStatus, setSelectedDMStatus] = useState(null);
  const [HIVTestDoneNotDone, setHIVTestDoneNotDone] = useState(null);
  const [HIVResult, setHIVResult] = useState(null);
  const [CPTStatus, setCPTStatus] = useState(null);
  const [ARTStatus, setARTStatus] = useState(null);
  const [treatmentOutcome, setTreatmentOutcome] = useState(null);

  useEffect(() => {
    const {
      TB_status,
      treatment_status,
      TB_DRTB_code,
      treatment_start_date,
      treatment_regimen,
      defined_TB_type,
      defined_TB_type_other,
      type_of_TB_patient,
      smoking_status,
      DM_status,
      HIV_test,
      HIV_test_date,
      HIV_result,
      CPT_status,
      CPT_date,
      ART_status,
      ART_date,
      treatment_outcome,
      treatment_outcome_date,
      remark,
    } = patient.patient;

    setValue(
      "TB_status",
      TB_status === null ? null : { value: TB_status, label: TB_status }
    );
    setSelectedTBStatus(
      TB_status === null ? null : { value: TB_status, label: TB_status }
    );
    setValue(
      "treatment_status",
      treatment_status === null
        ? null
        : { value: treatment_status, label: treatment_status }
    );
    setSelectedTreatmentStatus(
      treatment_status === null
        ? null
        : { value: treatment_status, label: treatment_status }
    );
    setValue("TB_DRTB_code", TB_DRTB_code);
    setValue("treatment_start_date", treatment_start_date);
    setValue(
      "treatment_regimen",
      treatment_regimen === null
        ? null
        : { value: treatment_regimen, label: treatment_regimen }
    );
    setTreatmentRegimen(
      treatment_regimen === null
        ? null
        : { value: treatment_regimen, label: treatment_regimen }
    );
    setValue(
      "defined_TB_type",
      defined_TB_type === null
        ? null
        : { value: defined_TB_type, label: defined_TB_type }
    );
    setDefinedTBType(
      defined_TB_type === null
        ? null
        : { value: defined_TB_type, label: defined_TB_type }
    );
    setValue("defined_TB_type_other", defined_TB_type_other);
    setValue(
      "type_of_TB_patient",
      type_of_TB_patient === null
        ? null
        : { value: type_of_TB_patient, label: type_of_TB_patient }
    );
    setTypeOfTBPatient(
      type_of_TB_patient === null
        ? null
        : { value: type_of_TB_patient, label: type_of_TB_patient }
    );
    setValue(
      "smoking_status",
      smoking_status === null
        ? null
        : { value: smoking_status, label: smoking_status }
    );
    setSelectedSmokingStatus(
      smoking_status === null
        ? null
        : { value: smoking_status, label: smoking_status }
    );
    setValue(
      "DM_status",
      DM_status === null ? null : { value: DM_status, label: DM_status }
    );
    setSelectedDMStatus(
      DM_status === null ? null : { value: DM_status, label: DM_status }
    );
    setValue(
      "HIV_test",
      HIV_test === null ? null : { value: HIV_test, label: HIV_test }
    );
    setHIVTestDoneNotDone(
      HIV_test === null ? null : { value: HIV_test, label: HIV_test }
    );
    setValue("HIV_test_date", HIV_test_date);
    setValue(
      "HIV_result",
      HIV_result === null ? null : { value: HIV_result, label: HIV_result }
    );
    setHIVResult(
      HIV_result === null ? null : { value: HIV_result, label: HIV_result }
    );
    setValue(
      "CPT_status",
      CPT_status === null ? null : { value: CPT_status, label: CPT_status }
    );
    setCPTStatus(
      CPT_status === null ? null : { value: CPT_status, label: CPT_status }
    );
    setValue("CPT_status_date", CPT_date);
    setValue(
      "ART_status",
      ART_status === null ? null : { value: ART_status, label: ART_status }
    );
    setARTStatus(
      ART_status === null ? null : { value: ART_status, label: ART_status }
    );
    setValue("ART_status_date", ART_date);
    setValue(
      "treatment_outcome",
      treatment_outcome === null
        ? null
        : { value: treatment_outcome, label: treatment_outcome }
    );
    setTreatmentOutcome(
      treatment_outcome === null
        ? null
        : { value: treatment_outcome, label: treatment_outcome }
    );
    setValue("treatment_outcome_date", treatment_outcome_date);
    setValue("remark", remark);
  }, [patient.patient, setValue]);

  const onSubmit = async (data) => {
    const TB_status = data.TB_status?.value;
    const treatment_status =
      TB_status === "No TB" ? null : data.treatment_status?.value;
    const status =
      treatment_status === "Received TB Treatment" ||
      treatment_status === "Refer to other Township"
        ? true
        : false;

    setLoading(true);
    await updatePatient(
      id,
      {
        TB_status: TB_status,
        treatment_status: treatment_status,
        TB_DRTB_code: status ? data.TB_DRTB_code : null,
        treatment_start_date: status ? data.treatment_start_date : null,
        treatment_regimen: status ? data.treatment_regimen?.value : null,
        defined_TB_type: status ? data.defined_TB_type?.value : null,
        defined_TB_type_other: status ? data.defined_TB_type_other : null,
        type_of_TB_patient: status ? data.type_of_TB_patient?.value : null,
        smoking_status: status ? data.smoking_status?.value : null,
        DM_status: status ? data.DM_status?.value : null,
        HIV_test: status ? data.HIV_test?.value : null,
        HIV_test_date:
          status && data.HIV_test?.value === "Done" ? data.HIV_test_date : null,
        HIV_result:
          status && data.HIV_test?.value === "Done"
            ? data.HIV_result?.value
            : null,
        CPT_status: status ? data.CPT_status?.value : null,
        CPT_date:
          status && data.CPT_status?.value === "Yes"
            ? data.CPT_status_date
            : null,
        ART_status: status ? data.ART_status?.value : null,
        ART_date:
          status && data.ART_status?.value === "Yes"
            ? data.ART_status_date?.value
            : null,
        treatment_outcome: status ? data.treatment_outcome?.value : null,
        treatment_outcome_date:
          status && data.treatment_outcome?.value !== null
            ? data.treatment_outcome_date
            : null,
        remark: data.remark,
      },
      "TB-conclusion",
      "TB Conclusion"
    );
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>TB Conclusion</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="TB_status"
              label="TB Status"
              control={control}
              rules={{
                required: "TB Status is required!",
              }}
              options={TBStatus}
              value={selectedTBStatus}
              setData={setSelectedTBStatus}
              placeholder="TB Status"
              isRequired={true}
            />
          </Col>
          <Col md={4}>
            <CustomSelect
              id="treatment_status"
              label="Treatment Status"
              control={control}
              rules={{
                required:
                  selectedTBStatus !== null &&
                  selectedTBStatus?.value !== "No TB"
                    ? "Treatment Status is required!"
                    : false,
              }}
              options={treatmentStatus}
              value={selectedTreatmentStatus}
              setData={setSelectedTreatmentStatus}
              placeholder="Treatment Status"
              isRequired={
                selectedTBStatus !== null && selectedTBStatus?.value !== "No TB"
              }
              isDisabled={
                selectedTBStatus === null || selectedTBStatus?.value === "No TB"
              }
            />
          </Col>
        </Row>
        {selectedTBStatus !== null &&
          selectedTBStatus?.value !== "No TB" &&
          (selectedTreatmentStatus?.value === "Received TB Treatment" ||
            selectedTreatmentStatus?.value === "Refer to other Township") && (
            <>
              <Row>
                <Col md={4}>
                  <CustomInput
                    id="TB_DRTB_code"
                    label="TB Registration Code"
                    register={{
                      ...register("TB_DRTB_code", {
                        required:
                          selectedTreatmentStatus?.value ===
                            "Received TB Treatment" ||
                          selectedTreatmentStatus?.value ===
                            "Refer to other Township"
                            ? "TB Registration Code is required!"
                            : false,
                      }),
                    }}
                    placeholder="Enter TB Registration Code"
                    errors={errors}
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
                <Col md={4}>
                  <CustomDateInput
                    id="treatment_start_date"
                    label="Treatment Start Date "
                    register={{
                      ...register("treatment_start_date", {
                        required:
                          selectedTreatmentStatus?.value ===
                            "Received TB Treatment" ||
                          selectedTreatmentStatus?.value ===
                            "Refer to other Township"
                            ? "Treatment Start Date is required!"
                            : false,
                      }),
                    }}
                    placeholder="Treatment Start Date "
                    errors={errors}
                    max={currentDate()}
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="treatment_regimen"
                    label="Treatment Regimen"
                    rules={{
                      required:
                        selectedTreatmentStatus?.value ===
                          "Received TB Treatment" ||
                        selectedTreatmentStatus?.value ===
                          "Refer to other Township"
                          ? "Treatment Regimen is required!"
                          : false,
                    }}
                    control={control}
                    options={treatmentRegimens}
                    value={treatmentRegimen}
                    setData={setTreatmentRegimen}
                    placeholder="Treatment Regimen"
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="defined_TB_type"
                    label="Defined TB Type"
                    control={control}
                    rules={{
                      required:
                        selectedTreatmentStatus?.value ===
                          "Received TB Treatment" ||
                        selectedTreatmentStatus?.value ===
                          "Refer to other Township"
                          ? "Defined TB Type is required!"
                          : false,
                    }}
                    options={definedTBTypes}
                    value={definedTBType}
                    setData={(data) => {
                      setValue("defined_TB_type_other", null);
                      setDefinedTBType(data);
                    }}
                    placeholder="Defined TB Type"
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
                <Col md={4}>
                  <CustomInput
                    id="defined_TB_type_other"
                    label="Defined TB Type Other"
                    register={{
                      ...register("defined_TB_type_other", {
                        required:
                          definedTBType?.value === "Extra Pulmonary BC" ||
                          definedTBType?.value === "Extra Pulmonary CD"
                            ? "Defined TB Type Other is required!"
                            : false,
                      }),
                    }}
                    placeholder="Enter Defined TB Type Other"
                    errors={errors}
                    disabled={
                      definedTBType === null ||
                      (definedTBType?.value !== "Extra Pulmonary BC" &&
                        definedTBType?.value !== "Extra Pulmonary CD")
                    }
                    isRequired={
                      definedTBType?.value === "Extra Pulmonary BC" ||
                      definedTBType?.value === "Extra Pulmonary CD"
                    }
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="type_of_TB_patient"
                    label="Type Of TB Patient"
                    control={control}
                    rules={{
                      required:
                        selectedTreatmentStatus?.value ===
                          "Received TB Treatment" ||
                        selectedTreatmentStatus?.value ===
                          "Refer to other Township"
                          ? "Type Of TB Patient is required!"
                          : false,
                    }}
                    options={typeOfTBPateints}
                    value={typeOfTBPatient}
                    setData={setTypeOfTBPatient}
                    placeholder="Type Of TB Patient"
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="smoking_status"
                    label="Smoking Status"
                    control={control}
                    rules={{
                      required:
                        selectedTreatmentStatus?.value ===
                          "Received TB Treatment" ||
                        selectedTreatmentStatus?.value ===
                          "Refer to other Township"
                          ? "Smoking Status is required!"
                          : false,
                    }}
                    options={smokingStatus}
                    value={selectedSmokingStatus}
                    setData={setSelectedSmokingStatus}
                    placeholder="Smoking Status"
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="DM_status"
                    label="DM Status"
                    control={control}
                    rules={{
                      required:
                        selectedTreatmentStatus?.value ===
                          "Received TB Treatment" ||
                        selectedTreatmentStatus?.value ===
                          "Refer to other Township"
                          ? "DM Status is required!"
                          : false,
                    }}
                    options={DMStatus}
                    value={selectedDBStatus}
                    setData={setSelectedDMStatus}
                    placeholder="DM Status"
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="HIV_test"
                    label="HIV Test"
                    control={control}
                    rules={{
                      required:
                        selectedTreatmentStatus?.value ===
                          "Received TB Treatment" ||
                        selectedTreatmentStatus?.value ===
                          "Refer to other Township"
                          ? "HIV Test is required!"
                          : false,
                    }}
                    options={dones}
                    value={HIVTestDoneNotDone}
                    setData={setHIVTestDoneNotDone}
                    placeholder="HIV Test"
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
                <Col md={4}>
                  <CustomDateInput
                    id="HIV_test_date"
                    label="HIV Test Date"
                    register={{
                      ...register("HIV_test_date", {
                        required:
                          HIVTestDoneNotDone?.value === "Done"
                            ? "HIV Test Date is required!"
                            : false,
                      }),
                    }}
                    placeholder="HIV Test Date"
                    errors={errors}
                    max={currentDate()}
                    disabled={
                      HIVTestDoneNotDone === null ||
                      HIVTestDoneNotDone?.value !== "Done"
                    }
                    isRequired={HIVTestDoneNotDone?.value === "Done"}
                  />
                </Col>
                <Col md={4}>
                  <CustomSelect
                    id="HIV_result"
                    label="HIV Result"
                    control={control}
                    rules={{
                      required:
                        HIVTestDoneNotDone?.value === "Done"
                          ? "HIV Result is required!"
                          : false,
                    }}
                    options={HIVResults}
                    value={HIVResult}
                    setData={setHIVResult}
                    placeholder="HIV Result"
                    isRequired={HIVTestDoneNotDone?.value === "Done"}
                    isDisabled={
                      HIVTestDoneNotDone === null ||
                      HIVTestDoneNotDone?.value !== "Done"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <CustomSelect
                    id="CPT_status"
                    label="CPT Status"
                    rules={{
                      required:
                        selectedTreatmentStatus?.value ===
                          "Received TB Treatment" ||
                        selectedTreatmentStatus?.value ===
                          "Refer to other Township"
                          ? "CPT Status is required!"
                          : false,
                    }}
                    control={control}
                    options={yesOrNo}
                    value={CPTStatus}
                    setData={setCPTStatus}
                    placeholder="CPT Status"
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
                <Col md={3}>
                  <CustomDateInput
                    id="CPT_status_date"
                    label="Date"
                    register={{
                      ...register("CPT_status_date", {
                        required:
                          CPTStatus?.value === "Yes"
                            ? "CPT Date is required!"
                            : false,
                      }),
                    }}
                    placeholder="CPT Date"
                    errors={errors}
                    disabled={CPTStatus?.value !== "Yes"}
                    isRequired={CPTStatus?.value === "Yes"}
                    max={currentDate()}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <CustomSelect
                    id="ART_status"
                    label="ART Status"
                    rules={{
                      required:
                        selectedTreatmentStatus?.value ===
                          "Received TB Treatment" ||
                        selectedTreatmentStatus?.value ===
                          "Refer to other Township"
                          ? "ART Status is required!"
                          : false,
                    }}
                    control={control}
                    options={
                      HIVResult === null || HIVResult?.value !== "Non-Reactive"
                        ? yesOrNo
                        : no
                    }
                    value={ARTStatus}
                    setData={setARTStatus}
                    placeholder="ART Status"
                    isRequired={
                      selectedTreatmentStatus?.value ===
                        "Received TB Treatment" ||
                      selectedTreatmentStatus?.value ===
                        "Refer to other Township"
                    }
                  />
                </Col>
                <Col md={3}>
                  <CustomDateInput
                    id="ART_status_date"
                    label="Date"
                    register={{
                      ...register("ART_status_date", {
                        required:
                          ARTStatus?.value === "Yes"
                            ? "ART Date is required!"
                            : false,
                      }),
                    }}
                    placeholder="ART Date"
                    errors={errors}
                    disabled={ARTStatus?.value !== "Yes"}
                    isRequired={ARTStatus?.value === "Yes"}
                    max={currentDate()}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <CustomSelect
                    id="treatment_outcome"
                    label="Treatment Outcome"
                    control={control}
                    options={treatmentOutcomes}
                    value={treatmentOutcome}
                    setData={setTreatmentOutcome}
                    placeholder="Treatment Outcome"
                    isRequired={false}
                  />
                </Col>
                <Col md={3}>
                  <CustomDateInput
                    id="treatment_outcome_date"
                    label="Treatment Outcome Date"
                    register={{
                      ...register("treatment_outcome_date", {
                        required:
                          treatmentOutcome !== null &&
                          treatmentOutcome?.value !== null
                            ? "Select Treatment Outcome Date"
                            : false,
                      }),
                    }}
                    placeholder="Select Treatment Outcome Date"
                    errors={errors}
                    isRequired={
                      treatmentOutcome !== null &&
                      treatmentOutcome?.value !== null
                    }
                    disabled={
                      treatmentOutcome === null ||
                      treatmentOutcome?.value === null
                    }
                    max={currentDate()}
                  />
                </Col>
              </Row>
            </>
          )}
        <Row>
          <Col md={6}>
            <CustomTextArea
              id="remark"
              label="Remark"
              register={{
                ...register("remark"),
              }}
              placeholder="Enter Remark"
              errors={errors}
              isRequired={false}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
        {/* {patient.patient.DOT_start_date === null && fromTBPatient && ( */}
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        {/* )} */}
      </CardFooter>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(TBConclusion);
