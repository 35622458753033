import React from 'react'
import { FormGroup } from 'reactstrap'

const CustomTextArea = React.forwardRef(
  (
    {
      id,
      label,
      register,
      errors,
      placeholder,
      rows = 3,
      isRequired = false,
      isDisabled = false,
    },
    ref,
  ) => {
    return (
      <FormGroup>
        <label htmlFor={id}>
          {label} {isRequired && <sup className="text-danger">*</sup>}
        </label>
        <textarea
          ref={ref}
          id={id}
          className="form-control"
          {...register}
          rows={rows}
          placeholder={placeholder}
          disabled={isDisabled}
        />
        {errors[id] && (
          <span className="text-danger">{errors[id]?.message}</span>
        )}
      </FormGroup>
    )
  },
)

export default CustomTextArea
