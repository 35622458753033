import React from 'react'
import SpinnerImage from 'assets/img/icons/spinner.png'
import styles from './FullScreenLoading.module.scss'
function FullScreenLoading() {
  return (
    <div className={styles.container}>
      <img src={SpinnerImage} width="20%" alt="" />
    </div>
  )
}
export default FullScreenLoading
