import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import { connect } from "react-redux";
import { updatePatient } from "store/actions";
import { useParams } from "react-router";
import { yesOrNo } from "variables/options";
import { VOTNotAcceptReasons } from "variables/options";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { useQuery } from "hooks/useQuery";

const Accept = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const history = useHistory();
  const { id } = useParams();

  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false);
  const [selectedAccept, setSelectedAccept] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);

  useEffect(() => {
    const { VOT_accept, VOT_not_accept_reason, VOT_not_accept_reason_other } =
      patient.patient;

    setValue(
      "VOT_accept",
      VOT_accept === null
        ? null
        : {
            value: VOT_accept,
            label: VOT_accept,
          }
    );
    setSelectedAccept(
      VOT_accept === null
        ? null
        : {
            value: VOT_accept,
            label: VOT_accept,
          }
    );
    setValue(
      "VOT_not_accept_reason",
      VOT_not_accept_reason === null
        ? null
        : {
            value: VOT_not_accept_reason,
            label: VOT_not_accept_reason,
          }
    );
    setSelectedReason(
      VOT_not_accept_reason === null
        ? null
        : {
            value: VOT_not_accept_reason,
            label: VOT_not_accept_reason,
          }
    );
    setValue("VOT_not_accept_reason_other", VOT_not_accept_reason_other);
  }, [setValue, patient.patient]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      id,
      {
        VOT_accept: data.VOT_accept?.value,
        VOT_not_accept_reason:
          selectedAccept?.value === "No"
            ? data.VOT_not_accept_reason?.value
            : null,
        VOT_not_accept_reason_other:
          selectedReason?.value === "Others (Specify)"
            ? data.VOT_not_accept_reason_other
            : null,
      },
      "VOT-accept",
      "VOT Accept"
    );
    setLoading(false);
  };

  const handleNavigateMoveToVOT = () => {
    history.push(`/admin/move-to-DOT/${patient.patient.id}?VOT_accept=Yes`);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>VOT Accept</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="VOT_accept"
              label="VOT Accept"
              control={control}
              rules={{
                required: "VOT Accept is required!",
              }}
              options={yesOrNo}
              value={selectedAccept}
              setData={(value) => {
                setSelectedAccept(value);
                setSelectedReason(null);
              }}
              placeholder="VOT Accept"
              isRequired={true}
            />
          </Col>
          {selectedAccept?.value === "No" && (
            <Col md={4}>
              <CustomSelect
                id="VOT_not_accept_reason"
                label="Reason"
                control={control}
                rules={{
                  required:
                    selectedAccept?.value === "No"
                      ? "Reason is required!"
                      : false,
                }}
                options={VOTNotAcceptReasons}
                value={selectedReason}
                setData={setSelectedReason}
                placeholder="Reason"
                isRequired={selectedAccept?.value === "No"}
              />
            </Col>
          )}
        </Row>
        {selectedReason?.value === "Others (Specify)" && (
          <Row>
            <Col md={6}>
              <CustomTextArea
                id="VOT_not_accept_reason_other"
                label="Others (Specify)"
                register={{
                  ...register("VOT_not_accept_reason_other", {
                    required:
                      selectedReason?.value === "Others (Specify)"
                        ? "Others (Specify) is required!"
                        : false,
                  }),
                }}
                placeholder="Enter Others (Specify)"
                errors={errors}
                isRequired={selectedReason?.value === "Others (Specify)"}
              />
            </Col>
          </Row>
        )}
      </CardBody>
      <CardFooter className="text-right">
        {selectedAccept?.value === "No" && fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
        {selectedAccept?.value === "Yes" && (
          <Button color="primary" size="sm" onClick={handleNavigateMoveToVOT}>
            Move To VOT
          </Button>
        )}
      </CardFooter>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient })(Accept);
