import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form, Row, Col } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomTextArea from 'components/Inputs/CustomTextArea'
import CustomModal from 'components/Modal/CustomModal'
import { sexs, yesOrNo, educations } from 'variables/options'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import {
  updateVolunteer,
  temDeleteVolunteer,
  getVolunteerTrainings,
} from 'store/actions'
import CustomAlert from 'components/Alert/CustomAlert'
import {
  volunteerMiddleware,
  activitiesMiddleware,
  CREATE,
  READ,
  isVolunteerSupervisor,
} from 'utils/middleware'
import CustomNumberInput from 'components/Inputs/CustomNumberInput'

const VolunteerDetail = ({
  isOpen,
  toggle,
  status,
  auth,
  township,
  volunteer,
  updateVolunteer,
  temDeleteVolunteer,
}) => {
  const { role } = auth.user
  const router = useHistory()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const [alert, setAlert] = useState(null)
  const [loading, setLoading] = useState(false)
  const [gender, setGender] = useState(null)
  const [education, setEducation] = useState(null)
  const [selectedTownship, setSelectedTownship] = useState(null)
  const [selectedRecoverTownship, setSelectedRecoverTownship] = useState(null)
  const [pastHistoryOfTB, setPastHistoryOfTB] = useState(null)
  const [
    previousVolunteerExperience,
    setPreviousVolunteerExperience,
  ] = useState(null)

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }))

  useEffect(() => {
    const {
      name,
      gender,
      age,
      address,
      phone,
      email,
      education_status,
      township,
      recover_township,
      past_history_of_TB,
      previous_volunteer_experience,
      remark,
    } = volunteer.volunteer

    setValue('name', name)
    setValue('gender', { value: gender, label: gender })
    setGender({ value: gender, label: gender })
    setValue('age', age)
    setValue('address', address)
    setValue('phone', phone)
    setValue('email', email)
    setValue('education_status', {
      value: education_status,
      label: education_status,
    })
    setEducation({ value: education_status, label: education_status })
    setValue('township', { value: township?.id, label: township?.name })
    setSelectedTownship({ value: township?.id, label: township?.name })
    setValue(
      'recover_township',
      recover_township === null
        ? null
        : { value: recover_township?.id, label: recover_township?.name },
    )
    setSelectedRecoverTownship(
      recover_township === null
        ? null
        : { value: recover_township?.id, label: recover_township?.name },
    )
    setValue('past_history_of_TB', {
      value: past_history_of_TB,
      label: past_history_of_TB,
    })
    setPastHistoryOfTB({
      value: past_history_of_TB,
      label: past_history_of_TB,
    })
    setValue('previous_volunteer_experience', {
      value: previous_volunteer_experience,
      label: previous_volunteer_experience,
    })
    setPreviousVolunteerExperience({
      value: previous_volunteer_experience,
      label: previous_volunteer_experience,
    })
    setValue('remark', remark)
  }, [setValue, volunteer.volunteer])

  const onSubmit = async (data) => {
    setLoading(true)
    await updateVolunteer(volunteer.volunteer.id, {
      name: data.name,
      gender: data.gender.value,
      age: data.age,
      address: data.address,
      phone: data.phone,
      email: data.email,
      education_status: data.education_status.value,
      township_id:
        role !== undefined && !isVolunteerSupervisor(role)
          ? data.township.value
          : auth.user?.township?.id,
      recover_township_id: data.recover_township?.value,
      past_history_of_TB: data.past_history_of_TB.value,
      previous_volunteer_experience: data.previous_volunteer_experience.value,
      remark: data.remark,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      toggle(false)
    }
  }, [status.success, toggle])

  const handleDelete = () => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          temDeleteVolunteer(volunteer.volunteer.id)
          setAlert(null)
          toggle(false)
        }}
        onCancel={() => setAlert(null)}
        toTrash={true}
      />,
    )
  }

  return (
    <>
      {alert}
      <CustomModal
        isOpen={isOpen}
        title="Detail Volunteer"
        onClick={() => toggle(false)}
        style={{ maxWidth: '60%' }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <Row>
              <Col md={6}>
                <CustomInput
                  id="name"
                  label="Name"
                  register={{
                    ...register('name', {
                      required: 'Name is required!',
                    }),
                  }}
                  placeholder="Enter Name"
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  id="gender"
                  label="Select Gender"
                  rules={{ required: 'Gender is required!' }}
                  control={control}
                  options={sexs}
                  value={gender}
                  setData={setGender}
                  placeholder="Select gender"
                />
              </Col>
              <Col md={6}>
                <CustomNumberInput
                  id="age"
                  label="Age"
                  register={{
                    ...register('age', {
                      required: 'Age is required!',
                    }),
                  }}
                  placeholder="Enter age"
                  errors={errors}
                  max={120}
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  id="address"
                  label="Address"
                  register={{
                    ...register('address', {
                      required: 'Address is required!',
                    }),
                  }}
                  placeholder="Enter Address"
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  id="phone"
                  label="Phone"
                  register={{
                    ...register('phone', {
                      required: 'Phone is required!',
                    }),
                  }}
                  placeholder="Enter Phone"
                  errors={errors}
                />
              </Col>
              <Col md={6}>
                <CustomInput
                  id="email"
                  label="Email"
                  register={{
                    ...register('email', {
                      required: false,
                    }),
                  }}
                  placeholder="example@gmail.com"
                  errors={errors}
                  isRequired={false}
                  type="email"
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  id="education_status"
                  label="Select Education Status"
                  rules={{ required: 'Education status is required!' }}
                  control={control}
                  options={educations}
                  value={education}
                  setData={setEducation}
                  placeholder="Select education status"
                />
              </Col>
              <Col md={6}>
                {role !== undefined && !isVolunteerSupervisor(role) ? (
                  <CustomSelect
                    id="township"
                    label="Select Township"
                    rules={{ required: 'Township is required!' }}
                    control={control}
                    options={townships}
                    value={selectedTownship}
                    setData={setSelectedTownship}
                    placeholder="Select township"
                  />
                ) : (
                  <p>{auth.user?.township?.name}</p>
                )}
              </Col>
              <Col md={6}>
                <CustomSelect
                  id="recover_township"
                  label="Select Recover Township"
                  rules={{ required: false }}
                  control={control}
                  options={townships}
                  value={selectedRecoverTownship}
                  setData={setSelectedRecoverTownship}
                  placeholder="Select recover township"
                  isRequired={false}
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  id="past_history_of_TB"
                  label="Select Past History of TB"
                  rules={{ required: 'Past History of TB is required!' }}
                  control={control}
                  options={yesOrNo}
                  value={pastHistoryOfTB}
                  setData={setPastHistoryOfTB}
                  placeholder="Select Past History of TB"
                />
              </Col>
              <Col md={6}>
                <CustomSelect
                  id="previous_volunteer_experience"
                  label="Select Previous Volunteer Experience"
                  rules={{
                    required: 'Previous Volunteer Experience is required!',
                  }}
                  control={control}
                  options={yesOrNo}
                  value={previousVolunteerExperience}
                  setData={setPreviousVolunteerExperience}
                  placeholder="Select Previous Volunteer Experience"
                />
              </Col>
              <Col md={6}>
                <CustomTextArea
                  id="remark"
                  label="Remark"
                  register={{
                    ...register('remark'),
                  }}
                  placeholder="Write remark..."
                  errors={errors}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            {activitiesMiddleware(role, READ) && (
              <Button
                color="success"
                size="sm"
                onClick={() =>
                  router.push(
                    `/admin/volunteer-training-list/${volunteer.volunteer.id}`,
                  )
                }
              >
                Training
              </Button>
            )}

            {volunteerMiddleware(role, CREATE) && (
              <>
                <Button
                  color="primary"
                  size="sm"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Loading' : 'Update'}
                </Button>
                <Button color="danger" size="sm" onClick={handleDelete}>
                  Delete
                </Button>
              </>
            )}
          </div>
        </Form>
      </CustomModal>
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
})

export default connect(mapStateToProps, {
  updateVolunteer,
  temDeleteVolunteer,
  getVolunteerTrainings,
})(VolunteerDetail)
