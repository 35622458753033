import { SET_TAKE_MEDICINE, SHOW_TAKE_MEDICINES } from '../type'

const initialState = {
  takeMedicines: [],
  takeMedicine: {},
  total: 0,
}

const takeMedicine = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TAKE_MEDICINES:
      return {
        ...state,
        takeMedicines: action.payload.data,
        total: action.payload.total,
      }
    case SET_TAKE_MEDICINE:
      return {
        ...state,
        takeMedicine: action.payload,
      }

    default:
      return state
  }
}

export default takeMedicine
