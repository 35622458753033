import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import { dones } from 'variables/options'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { currentDate } from 'utils/currentDate'
import { drugSusceptibilityResults } from 'variables/options'
import { connect } from 'react-redux'
import { updatePatient } from 'store/actions'
import { useParams } from 'react-router'
import { useQuery } from 'hooks/useQuery'

const DrugSusceptibility = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const { id } = useParams()

  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false)
  const [
    drugSusceptibilityDoneNotDone,
    setDrugSusceptibilityDoneNotDone,
  ] = useState(null)
  const [drugSusceptibilityResult, setDrugSusceptibilityResult] = useState(null)

  useEffect(() => {
    const {
      drug_susceptibility_done_or_not_done,
      drug_susceptibility_date,
      drug_susceptibility_result,
    } = patient.patient

    setValue('drug_susceptibility_done_not_done', drug_susceptibility_done_or_not_done === null ? null : { value: drug_susceptibility_done_or_not_done, label: drug_susceptibility_done_or_not_done })
    setDrugSusceptibilityDoneNotDone(drug_susceptibility_done_or_not_done === null ? null : { value: drug_susceptibility_done_or_not_done, label: drug_susceptibility_done_or_not_done })
    setValue('drug_susceptibility_date', drug_susceptibility_date)
    setValue('drug_susceptibility_result', drug_susceptibility_result === null ? null : { value: drug_susceptibility_result, label: drug_susceptibility_result })
    setDrugSusceptibilityResult(drug_susceptibility_result === null ? null : { value: drug_susceptibility_result, label: drug_susceptibility_result })
  }, [setValue, patient.patient])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatient(id, {
      drug_susceptibility_done_or_not_done: data.drug_susceptibility_done_not_done?.value,
      drug_susceptibility_date: data.drug_susceptibility_done_not_done?.value === 'Not Done' ? null : data.drug_susceptibility_date,
      drug_susceptibility_result: data.drug_susceptibility_done_not_done?.value === 'Not Done' ? null : data.drug_susceptibility_result?.value,
    }, 'drug-susceptibility', 'Drug Susceptibility')
    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>Drug Susceptibility</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="drug_susceptibility_done_not_done"
              label="Drug Susceptibility Done or Not Done"
              control={control}
              rules={{
                required: 'Drug Susceptibility Done or Not Done is required!',
              }}
              options={dones}
              value={drugSusceptibilityDoneNotDone}
              setData={setDrugSusceptibilityDoneNotDone}
              placeholder="Drug Susceptibility Done or Not Done"
              isRequired={true}
            />
          </Col>
          <Col md={4}>
            <CustomDateInput
              id="drug_susceptibility_date"
              label="Drug Susceptibility Date"
              register={{
                ...register('drug_susceptibility_date', {
                  required:
                    drugSusceptibilityDoneNotDone?.value === 'Done'
                      ? 'Drug Susceptibility Date is required!'
                      : false,
                }),
              }}
              placeholder="Drug Susceptibility Date"
              errors={errors}
              max={currentDate()}
              disabled={
                drugSusceptibilityDoneNotDone === null ||
                drugSusceptibilityDoneNotDone?.value !== 'Done'
              }
              isRequired={drugSusceptibilityDoneNotDone?.value === 'Done'}
            />
          </Col>
          <Col md={4}>
            <CustomSelect
              id="drug_susceptibility_result"
              label="Drug Susceptibility Result"
              control={control}
              rules={{
                required:
                  drugSusceptibilityDoneNotDone?.value === 'Done'
                    ? 'Drug Susceptibility Result is required!'
                    : false,
              }}
              options={drugSusceptibilityResults}
              value={drugSusceptibilityResult}
              setData={setDrugSusceptibilityResult}
              placeholder="Drug Susceptibility Result"
              isRequired={drugSusceptibilityDoneNotDone?.value === 'Done'}
              isDisabled={
                drugSusceptibilityDoneNotDone === null ||
                drugSusceptibilityDoneNotDone?.value !== 'Done'
              }
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
      {fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
      </CardFooter>
    </Form>
  )
}

const mapStateToProps = (store) => ({
  patient: store.patient,
})

export default connect(mapStateToProps, { updatePatient })(DrugSusceptibility)
