import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form, Row, Col } from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomModal from 'components/Modal/CustomModal'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { months, dones, smearResults } from 'variables/options'
import {
  updateFUSputumExaminition,
  deleteFUSputumExaminition,
} from 'store/actions'
import CustomAlert from 'components/Alert/CustomAlert'
import { patientMiddleware, CREATE } from 'utils/middleware'
import CustomTextArea from 'components/Inputs/CustomTextArea'
import { currentDate } from 'utils/currentDate'

const FUSputumExaminitionDetail = ({
  isOpen,
  toggle,
  auth,
  status,
  fUSputumExaminition,
  updateFUSputumExaminition,
  deleteFUSputumExaminition,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()
  const { id } = useParams()
  const { role } = auth.user
  const [alert, setAlert] = useState(null)
  const [loading, setLoading] = useState(false)

  const [month, setMonth] = useState(null)
  const [smear, setSmear] = useState(null)
  const [smearResult, setSmearResult] = useState(null)

  useEffect(() => {
    const {
      month,
      to_send_date,
      send_date,
      smear,
      smear_date,
      smear_result,
      remark,
    } = fUSputumExaminition.fUSputumExaminition

    setValue('month', { value: month, label: month })
    setMonth({ value: month, label: month })
    setValue('to_send_date', to_send_date)
    setValue('send_date', send_date)
    setValue('smear', smear === null ? null : { value: smear, label: smear })
    setSmear(smear === null ? null : { value: smear, label: smear })
    setValue('smear_date', smear_date)
    setValue(
      'smear_result',
      smear_result === null
        ? null
        : { value: smear_result, label: smear_result },
    )
    setSmearResult(
      smear_result === null
        ? null
        : { value: smear_result, label: smear_result },
    )
    setValue('remark', remark)
  }, [setValue, fUSputumExaminition.fUSputumExaminition])

  const onSubmit = async (data) => {
    setLoading(true)
    await updateFUSputumExaminition(
      fUSputumExaminition.fUSputumExaminition.id,
      {
        patient_id: id,
        month: data.month?.value,
        smear: data.smear?.value,
        smear_date: data.smear_date,
        smear_result: data.smear_result?.value,
      },
    )
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      toggle(false)
    }
  }, [status.success, toggle])

  const handleDelete = () => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteFUSputumExaminition(fUSputumExaminition.fUSputumExaminition.id)
          setAlert(null)
          toggle(false)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  return (
    <>
      {alert}
      <CustomModal
        isOpen={isOpen}
        title="Detail F/U Sputum Examinition"
        onClick={() => toggle(false)}
        style={{ maxWidth: '60%' }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <Row>
              <Col md={4}>
                <CustomDateInput
                  id="to_send_date"
                  label="To Send Date"
                  register={{
                    ...register('to_send_date', {
                      required: false,
                    }),
                  }}
                  placeholder="Select To Send Date"
                  errors={errors}
                  disabled={true}
                  isRequired={false}
                />
              </Col>
              <Col md={4}>
                <CustomDateInput
                  id="send_date"
                  label="Send Date"
                  register={{
                    ...register('send_date', {
                      required: false,
                    }),
                  }}
                  placeholder="Select Send Date"
                  errors={errors}
                  disabled={true}
                  isRequired={false}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="month"
                  label="Select Month"
                  rules={{ required: 'Month is required!' }}
                  control={control}
                  options={months}
                  value={month}
                  setData={setMonth}
                  placeholder="Select Month"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="smear"
                  label="Select Smear"
                  rules={{ required: 'Smear is required!' }}
                  control={control}
                  options={dones}
                  value={smear}
                  setData={setSmear}
                  placeholder="Select Smear"
                />
              </Col>
              <Col md={4}>
                <CustomDateInput
                  id="smear_date"
                  label="Date"
                  register={{
                    ...register('smear_date', {
                      required:
                        smear?.value === 'Done'
                          ? 'Smear Date is required!'
                          : false,
                    }),
                  }}
                  placeholder="Select Smear Date"
                  errors={errors}
                  max={currentDate()}
                  disabled={smear === null || smear?.value === 'Not Done'}
                  isRequired={smear?.value === 'Done'}
                />
              </Col>
              <Col md={4}>
                <CustomSelect
                  id="smear_result"
                  label="Select Smear Result"
                  rules={{
                    required:
                      smear?.value === 'Done'
                        ? 'Smear Result is required!'
                        : false,
                  }}
                  control={control}
                  options={smearResults}
                  value={smearResult}
                  setData={setSmearResult}
                  placeholder="Select Smear Result"
                  isDisabled={smear === null || smear?.value === 'Not Done'}
                  isRequired={smear?.value === 'Done'}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <CustomTextArea
                  id="remark"
                  label="Remark"
                  register={{
                    ...register('remark'),
                  }}
                  placeholder="Enter Remark"
                  errors={errors}
                  isDisabled={true}
                  isRequired={false}
                />
              </Col>
            </Row>
          </div>
          {patientMiddleware(role, CREATE) && (
            <div className="modal-footer">
              <Button
                color="primary"
                size="sm"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Loading' : 'Update'}
              </Button>
              <Button color="danger" size="sm" onClick={handleDelete}>
                Delete
              </Button>
            </div>
          )}
        </Form>
      </CustomModal>
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
  fUSputumExaminition: store.fUSputumExaminition,
})

export default connect(mapStateToProps, {
  updateFUSputumExaminition,
  deleteFUSputumExaminition,
})(FUSputumExaminitionDetail)
