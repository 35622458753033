import {
  CREATE_SUPERVISION_VISIT,
  DELETE_SUPERVISION_VISIT,
  SET_SUPERVISION_VISIT,
  SHOW_SUPERVISION_VISITS,
  UPDATE_SUPERVISION_VISIT,
} from '../type'

const initialState = {
  supervisionVisits: [],
  supervisionVisit: {},
  total: 0,
}

const supervisionVisit = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SUPERVISION_VISITS:
      return {
        ...state,
        supervisionVisits: action.payload.data,
        total: action.payload.total,
      }
    case CREATE_SUPERVISION_VISIT:
      return {
        ...state,
        supervisionVisits: [action.payload, ...state.supervisionVisits],
        total: state.total + 1,
      }
    case DELETE_SUPERVISION_VISIT:
      return {
        ...state,
        supervisionVisits: state.supervisionVisits.filter(
          (supervisionVisit) => supervisionVisit.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_SUPERVISION_VISIT:
      return {
        ...state,
        supervisionVisit: action.payload,
      }
    case UPDATE_SUPERVISION_VISIT:
      let index = state.supervisionVisits.findIndex(
        (supervisionVisit) => supervisionVisit.id === action.payload.id,
      )
      state.supervisionVisits[index] = action.payload
      if (state.supervisionVisit.id === action.payload.id) {
        state.supervisionVisit = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default supervisionVisit
