import React, { useState } from "react";
// import { useHistory } from "react-router";
import { Button, Row, Col, Container, Card, CardHeader } from "reactstrap";
import BackBtn from "utils/backBtn";
import ChestXRay from "./DetailComponents/ChestXRay";
import DrugSusceptibility from "./DetailComponents/DrugSusceptibility";
import FNAC from "./DetailComponents/FNAC";
import GeneXpert from "./DetailComponents/GeneXpert";
import OtherInvestigation from "./DetailComponents/OtherInvestigation";
import PresumptiveInfo from "./DetailComponents/PresumptiveInfo";
import Sputum from "./DetailComponents/Sputum";
import SputumCulture from "./DetailComponents/SputumCulture";
import TBConclusion from "./DetailComponents/TBConclusion";
import TrueNat from "./DetailComponents/TrueNat";
import TuberculinSkin from "./DetailComponents/TuberculinSkin";
import { connect } from "react-redux";
import VDOTConclusion from "./DetailComponents/VDOTConclusion";
import Eligible from "./DetailComponents/Eligible";
import Accept from "./DetailComponents/Accept";

const DSTBPatientDetail = ({ patient }) => {
  // const history = useHistory();
  const [page, setPage] = useState("Presumptive_Info");

  const handleShowPage = () => {
    switch (page) {
      case "Presumptive_Info":
        return <PresumptiveInfo />;
      case "Sputum":
        return <Sputum />;
      case "Chest_XRay":
        return <ChestXRay />;
      case "Gene_Xpert":
        return <GeneXpert />;
      case "Truenat":
        return <TrueNat />;
      case "FNAC":
        return <FNAC />;
      case "Tuberculin_Skin":
        return <TuberculinSkin />;
      case "Sputum_Culture":
        return <SputumCulture />;
      case "Drug_Susceptibility":
        return <DrugSusceptibility />;
      case "Other_Investigation":
        return <OtherInvestigation />;
      case "Eligible":
        return <Eligible />;
      case "Accept":
        return <Accept />;
      case "VDOT_end":
        return <VDOTConclusion />;
      case "TB_conclusion":
        return <TBConclusion />;
      default:
        return <PresumptiveInfo />;
    }
  };

  const handleColor = (currentPage, data) => {
    if (page === currentPage) {
      return "success";
    } else {
      if (data) {
        return "primary";
      } else {
        return "danger";
      }
    }
  };

  const handleShowTBConclusion = () => {
    const {
      sputum_done_or_not_done,
      chest_Xray_done_or_not_done,
      gene_Xpert_done_or_not_done,
      truenat_done_or_not_done,
      FNAC_done_or_not_done,
      tuberculin_skin_done_or_not_done,
      sputum_culture_done_or_not_done,
      drug_susceptibility_done_or_not_done,
      other_investigation_done_or_not_done,
    } = patient.patient;

    if (
      sputum_done_or_not_done &&
      chest_Xray_done_or_not_done &&
      gene_Xpert_done_or_not_done &&
      truenat_done_or_not_done &&
      FNAC_done_or_not_done &&
      tuberculin_skin_done_or_not_done &&
      sputum_culture_done_or_not_done &&
      drug_susceptibility_done_or_not_done &&
      other_investigation_done_or_not_done
    ) {
      return true;
    }
    return false;
  };

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <Row className="mb-2">
            <Col>
              <Button
                color={page === "Presumptive_Info" ? "success" : "primary"}
                size="sm"
                onClick={() => setPage("Presumptive_Info")}
              >
                PRESUMPTIVE INFO
              </Button>
              <Button
                color={handleColor(
                  "Sputum",
                  patient.patient.sputum_done_or_not_done
                )}
                size="sm"
                onClick={() => setPage("Sputum")}
              >
                SPUTUM
              </Button>
              <Button
                color={handleColor(
                  "Chest_XRay",
                  patient.patient.chest_Xray_done_or_not_done
                )}
                size="sm"
                onClick={() => setPage("Chest_XRay")}
              >
                CHEST X-RAY
              </Button>
              <Button
                color={handleColor(
                  "Gene_Xpert",
                  patient.patient.gene_Xpert_done_or_not_done
                )}
                size="sm"
                onClick={() => setPage("Gene_Xpert")}
              >
                GENE XPERT
              </Button>
              <Button
                color={handleColor(
                  "Truenat",
                  patient.patient.truenat_done_or_not_done
                )}
                size="sm"
                onClick={() => setPage("Truenat")}
              >
                TRUENAT
              </Button>
              <Button
                color={handleColor(
                  "FNAC",
                  patient.patient.FNAC_done_or_not_done
                )}
                size="sm"
                onClick={() => setPage("FNAC")}
              >
                FNAC
              </Button>
              <Button
                color={handleColor(
                  "Tuberculin_Skin",
                  patient.patient.tuberculin_skin_done_or_not_done
                )}
                size="sm"
                onClick={() => setPage("Tuberculin_Skin")}
              >
                TUBERCULIN SKIN
              </Button>
              <Button
                color={handleColor(
                  "Sputum_Culture",
                  patient.patient.sputum_culture_done_or_not_done
                )}
                size="sm"
                onClick={() => setPage("Sputum_Culture")}
              >
                SPUTUM CULTURE
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                color={handleColor(
                  "Drug_Susceptibility",
                  patient.patient.drug_susceptibility_done_or_not_done
                )}
                size="sm"
                onClick={() => setPage("Drug_Susceptibility")}
              >
                DRUG SENSITIVITY
              </Button>
              <Button
                color={handleColor(
                  "Other_Investigation",
                  patient.patient.other_investigation_done_or_not_done
                )}
                size="sm"
                onClick={() => setPage("Other_Investigation")}
              >
                OTHER INVESTIGATION
              </Button>
              {handleShowTBConclusion() && (
                <Button
                  color={page === "TB_conclusion" ? "success" : "primary"}
                  size="sm"
                  onClick={() => setPage("TB_conclusion")}
                >
                  TB CONCLUSION
                </Button>
              )}
              {patient.patient.TB_status !== null &&
                patient.patient.TB_status !== "No TB" &&
                patient.patient.treatment_status ===
                  "Received TB Treatment" && (
                  <>
                    <Button
                      color={handleColor(
                        "Eligible",
                        patient.patient.VOT_eligible
                      )}
                      size="sm"
                      onClick={() => setPage("Eligible")}
                    >
                      VOT Eligible
                    </Button>
                    {patient.patient.VOT_eligible === "Yes" && (
                      <Button
                        color={handleColor(
                          "Accept",
                          patient.patient.VOT_accept
                        )}
                        size="sm"
                        onClick={() => setPage("Accept")}
                      >
                        VOT Accept
                      </Button>
                    )}
                  </>
                )}

              {/* {patient.patient.VOT_accept === "Yes" && (
                <Button
                  color="primary"
                  size="sm"
                  onClick={() =>
                    history.push(`/admin/move-to-DOT/${patient.patient.id}`)
                  }
                >
                  Move To VOT
                </Button>
              )} */}
              {patient.patient.volunteer &&
                patient.patient.TB_status !== "No TB" &&
                patient.patient.treatment_status ===
                  "Received TB Treatment" && (
                  <Button
                    color={handleColor(
                      "VDOT_end",
                      patient.patient.VDOT_end_date
                    )}
                    size="sm"
                    onClick={() => setPage("VDOT_end")}
                  >
                    VDOT CONCLUSION
                  </Button>
                )}
            </Col>
          </Row>
        </CardHeader>
        {handleShowPage()}
      </Card>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  patient: store.patient,
});

export default connect(mapStateToProps)(DSTBPatientDetail);
