import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { yesOrNo } from "variables/options";
import CustomSelect from "components/Inputs/CustomSelect";
import { currentDate } from "utils/currentDate";
import BackBtn from "utils/backBtn";
import { updatePatient, getPatient } from "store/actions";
import { useQuery } from "hooks/useQuery";

const MoveToDOT = ({ status, patient, updatePatient, getPatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const history = useHistory();
  const { id } = useParams();

  const votAcceptValue = useQuery("VOT_accept");

  const [loading, setLoading] = useState(false);
  const [isDOT, setIsDOT] = useState(null);

  useEffect(() => {
    const { DOT_start_date } = patient.patient;

    setValue("is_DOT", DOT_start_date ? { value: "Yes", label: "Yes" } : null);
    setIsDOT(DOT_start_date ? { value: "Yes", label: "Yes" } : null);
    setValue("DOT_start_date", DOT_start_date);
  }, [setValue, patient.patient]);

  useEffect(() => {
    getPatient(id);
  }, [getPatient, id]);

  const onSubmit = async (data) => {
    setLoading(true);
    if (votAcceptValue === "Yes") {
      await updatePatient(
        id,
        {
          VOT_accept: votAcceptValue,
          DOT_start_date: isDOT?.value === "Yes" ? data.DOT_start_date : null,
        },
        "move-to-DOT",
        "Move To VOT"
      );
    } else {
      await updatePatient(
        id,
        {
          VOT_accept: "No",
          DOT_start_date: null,
        },
        "move-to-DOT",
        "Move To VOT"
      );
    }
    setLoading(false);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Card>
        <CardHeader className="border-0">
          <h3 className="mb-3">({`${patient.patient?.name}`}) Move To VOT</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={3}>
                <CustomSelect
                  id="is_DOT"
                  label="DOT Patient"
                  rules={{ required: "DOT Patient is required!" }}
                  control={control}
                  options={yesOrNo}
                  value={isDOT}
                  setData={setIsDOT}
                  placeholder="Yes or No"
                  isDisabled={patient.patient.DOTs > 0}
                />
              </Col>
              {isDOT?.value === "Yes" && (
                <Col md={3}>
                  <CustomDateInput
                    id="DOT_start_date"
                    label="DOT Start Date"
                    register={{
                      ...register("DOT_start_date", {
                        required:
                          isDOT?.value === "Yes"
                            ? "Select DOT Start Date"
                            : false,
                      }),
                    }}
                    placeholder="Select DOT Start Date"
                    errors={errors}
                    isRequired={isDOT?.value === "Yes"}
                    max={currentDate()}
                    disabled={patient.patient.DOTs > 0}
                  />
                </Col>
              )}
              {isDOT?.value === "Yes" && patient.patient?.DOT_start_date && (
                <Col md={3}>
                  <Button
                    color="success"
                    size="sm"
                    className="mt-5"
                    onClick={() =>
                      history.push(
                        `/admin/change-volunteer-create/${patient.patient?.id}`
                      )
                    }
                  >
                    VOT with/without volunteer?
                  </Button>
                </Col>
              )}
            </Row>
            <div className="modal-footer">
              {patient.patient.DOTs === 0 && (
                <Button
                  color="primary"
                  size="sm"
                  disabled={loading}
                  type="submit"
                >
                  {loading ? "Loading" : "Save"}
                </Button>
              )}
            </div>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
});

export default connect(mapStateToProps, { updatePatient, getPatient })(
  MoveToDOT
);
