import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form, Row, Col } from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomModal from 'components/Modal/CustomModal'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import {
  months,
  dones,
  smearResults,
} from 'variables/options'
import { useParams } from 'react-router'
import { connect } from 'react-redux'
import { createFUSputumExaminition } from 'store/actions'

const FUSputumExaminitionCreate = ({
  isOpen,
  toggle,
  status,
  createFUSputumExaminition,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()
  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [month, setMonth] = useState(null)
  const [smear, setSmear] = useState(null)
  const [smearResult, setSmearResult] = useState(null)

  const onSubmit = async (data) => {
    setLoading(true)
    await createFUSputumExaminition({
      patient_id: id,
      month: data.month?.value,
      smear: data.smear?.value,
      smear_date: data.smear_date,
      smear_result: data.smear_result?.value,
    })
    setLoading(false)
  }

  const formReset = useCallback(() => {
    setMonth(null)
    setSmear(null)
    setSmearResult(null)
    reset()
  }, [reset, setMonth, setSmear, setSmearResult])

  useEffect(() => {
    if (status.success) {
      formReset()
    }

    return () => formReset()
  }, [status.success, formReset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create F/U Sputum Examinition"
      onClick={() => {
        toggle(false)
        formReset()
      }}
      style={{ maxWidth: '60%' }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col md={4}>
              <CustomSelect
                id="month"
                label="Select Month"
                rules={{ required: 'Month is required!' }}
                control={control}
                options={months}
                value={month}
                setData={setMonth}
                placeholder="Select Month"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <CustomSelect
                id="smear"
                label="Select Smear"
                rules={{ required: 'Smear is required!' }}
                control={control}
                options={dones}
                value={smear}
                setData={setSmear}
                placeholder="Select Smear"
              />
            </Col>
            <Col md={4}>
              <CustomDateInput
                id="smear_date"
                label="Date"
                register={{
                  ...register('smear_date', {
                    required:
                      smear?.value === 'Done'
                        ? 'Smear Date is required!'
                        : false,
                  }),
                }}
                placeholder="Select Smear Date"
                errors={errors}
                disabled={smear === null || smear?.value === 'Not Done'}
                isRequired={smear?.value === 'Done'}
              />
            </Col>
            <Col md={4}>
              <CustomSelect
                id="smear_result"
                label="Select Smear Result"
                rules={{
                  required:
                    smear?.value === 'Done'
                      ? 'Smear Result is required!'
                      : false,
                }}
                control={control}
                options={smearResults}
                value={smearResult}
                setData={setSmearResult}
                placeholder="Select Smear Result"
                isDisabled={smear === null || smear?.value === 'Not Done'}
                isRequired={smear?.value === 'Done'}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Save'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
})

export default connect(mapStateToProps, {
  createFUSputumExaminition,
})(FUSputumExaminitionCreate)
