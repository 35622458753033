import React from 'react'
import CustomModal from 'components/Modal/CustomModal'
import { Container } from 'reactstrap'
import styles from './InfectionCheckDetail.module.css'

const InfectionCheckDetail = ({ isOpen, toggle }) => {
  return (
    <CustomModal
      isOpen={isOpen}
      title="Patient's Infection Control Check Detail (01-01-2022)"
      onClick={() => toggle(false)}
      style={{ maxWidth: '70%' }}
    >
      <Container className="mt-3" fluid>
        <h2 className={styles.label}>လူနာကိုမေးရန်</h2>
        <Answer
          id="၁"
          ques="လူနာသည် ဆေးယဥ်ပါးတီဘီဆေးကို တစ်နေ့နှစ်ကြိမ် မပျက်မကွက်
          မှန်မှန်သောက်ပါသလား?"
          ans={
            'ပျက်ကွက်ရက်များမရှိ မသောက်ပါ\nပထမလ 1 ရက်\nဒုတိယလ 0 ရက်\nတတိယလ 1 ရက်\nလုပ်ဆောင်ချက်များ\n-လူနာအားဆေးသောက်မပျက်ရန်ပြောကြားခြင်း။\n-ကြီးကြပ်သူ (Fr) အားအကြောင်းကြားခြင်း။\n-အပိုင်ဆရာမ (BHSP) ရှိပါကအကြောင်းကြားခြင်း။\n'
          }
        />

        <Answer
          id="၂"
          ques="လူနာကိုဆေးတိုက်သည့်ပြုစုစောင့်ရှောက်သူတဦးဦးကို ရွေးရန်"
          ans={'မိသားစုဝင်တဦး\nဆက်သွယ်ရန်ဖုန်းနံပါတ်-09123456789\n'}
        />

        <Answer
          id="၃"
          ques="အတူနေမိသားစုဉီးရေ"
          ans={'အတူနေမိသားစုဉီးရေ - 2\n'}
        />

        <Answer
          id="၄"
          ques="လူနာသည် မိမိတွင်တီဘီရောဂါရှိနေသည်ကို မိသားစုဝင်များကို
          ပြောပြထားပါသလား။"
          ans={
            'မပြောပြထားပါ\nလုပ်ဆောင်ချက်များ\n-မပြောရသေးပါက အသိပေးရှင်းပြခြင်း\n'
          }
        />

        <Answer
          id="၅"
          ques="အတူနေမိသားစုဝင်များမှတီဘီရောဂါဖြစ်နိုင်ခြေရှိသော အရေအတွက်"
          ans={
            'အသက်ငါးနှစ်အောက်ဦးရေ-0\nသက်ကြီးရွယ်အို(အသက် ၆၀ နှစ်အထက်) ဦးရေ-1\nHIV ရောဂါသည်ဦးရေ-0\nဆီးချိုရောဂါရှိသူဦးရေ-0\nကိုယ်ဝန်ဆောင်မိခင်ဦးရေ-1\nလုပ်ဆောင်ချက်များ\n-ဖြစ်နိုင်ချေရှိသူများကိုတီဘီရောဂါစစ်ဆေးခြင်း ကာကွယ်ခြင်းများပြုလုပ်ရန်ပြောကြားခြင်း။\n'
          }
        />

        <Answer
          id="၆"
          ques="လူနာ၏အတူနေမိသားစုဝင်များတွင်
          တီဘီရောဂါလက္ခဏာများခံစားနေရခြင်းရှိပါသလား။"
          ans={
            'ခံစားနေရသောတီဘီရောဂါလက္ခဏာများ\n၂ပတ်ထက်ပို၍ချောင်းဆိုးခြင်း*\nမကြာခဏဖျားခြင်း (ညနေပိုင်း၊ညပိုင်း)\nလုပ်ဆောင်ချက်များ\nလက္ခဏာရှိသူများ ကိုစစ်ဆေးမူခံယူရန် တိုက်တွန်းပြောကြားအားပေးခြင်း။\n'
          }
        />

        <Answer
          id="၇"
          ques="လူနာ၏မိသားစုဝင်များသည် တီဘီရောဂါစစ်ဆေးမှုခံယူထားပါသလား။"
          ans={
            'မရှိပါ\nလုပ်ဆောင်ချက်များ\n-စစ်ဆေးမူခံယူသင့်ပါက တိုက်တွန်းခြင်း။\n'
          }
        />

        <Answer
          id="၈"
          ques="လူနာသည်ချောင်းဆိုးသောအခါ
          အခြားသူများသို့မကူးစက်ရန်မည်သို့ပြုမူရမည်ကိုလုပ်ပြ နိုင်ပါသလား။"
          ans={
            'မလုပ်ပြနိုင်ပါ\nလုပ်ဆောင်ချက်များ\n-သရုပ်ပြခြင်းပြန်လည်လုပ်ဆောင်ခိုင်းခြင်း။\n'
          }
        />

        <Answer
          id="၉"
          ques="လူနာသည်နှာခေါင်းစည်းကိုဘယ်အချိန်ထိတပ်ရန် လိုအပ်သလဲသိပါသလား။"
          ans={
            'မသိပါ\nလုပ်ဆောင်ချက်များ\n-နှာခေါင်းစည်း၏အရေးပါပုံ အသုံးပြုပုံ စွန့်ပစ်ပုံတို့ကို ရှင်းလင်းပြောကြားခြင်း။\n'
          }
        />

        <Answer
          id="၁၀"
          ques="လူနာသည်သလိပ်ကို မည်သို့စွန့်ပစ်ရမည်ကို သိပါသလား။"
          ans={
            'မသိပါ\nလုပ်ဆောင်ချက်များ\n-နှာခေါင်းစည်း၏အရေးပါပုံ အသုံးပြုပုံ စွန့်ပစ်ပုံတို့ကို ရှင်းလင်းပြောကြားခြင်း။\n'
          }
        />

        <Answer
          id="၁၁"
          ques="လူနာသည်ရောဂါကူးစက်နိုင်သောကာလကို ကျော်လွန်ပြီးပါသလား။"
          ans={'မကျော်လွန်သေးပါ\n'}
        />

        <Answer
          id="၁၂"
          ques="လူနာသည် ရောဂါကူးစက်နိုင်သောကာလ (သလိပ်ပိုးမွေးသောအဖြေတွင်ရောဂါ  ပိုးမတွေ့သည်အထိ) အတွင်းလူဦးရေထူထပ်သောနေရာများ (အများသုံးပို့ဆောင်ရေး၊ လူအများသွားလာလေ့ရှိသည့်နေရာများ) သွားလာအသုံးပြုခြင်းကို ရှောင်ကြဉ်ပါသလား။"
          ans={`မရှောင်ကြဉ်ပါ\nလုပ်ဆောင်ချက်များ\n-မရှောင်နိုင်ပါက သူတပါးအားကူးစက်ပျံ့ပွါးခြင်းမဖြစ်အောင် ဆောင်ရန်ရှောင်ရန်များပြောကြားခြင်း။\n`}
        />

        <Answer
          id="၁၃"
          ques="လူနာသည် ရောဂါကူးစက်နိုင်သောကာလ (သလိပ်ပိုးမွေးသောအဖြေတွင်ရောဂါ  ပိုးမတွေ့သည်အထိ) အတွင်းတခြားသူများနှင့်အခန်းတခန်းထဲ(သို့) ကုတင်တစ်ခုထဲတွင် နားနေအိပ်စက်ခြင်းကို ရှောင်ကြဉ်ပါသလား။"
          ans={`မရှောင်ကြဉ်ပါ\nလုပ်ဆောင်ချက်များ\n-ရှောင်ကြဉ်ရန် အသိပေးခြင်း။\n`}
        />

        <Answer
          id="၁၄"
          ques="လူနာသည် HIVပိုးရှိမရှိစစ်ဆေးဖူးခြင်းရှိပါသလား။"
          ans={
            'မရှိပါ\nလုပ်ဆောင်ချက်များ\n-စစ်ဆေးရန် တိုက်တွန်းခြင်းနှင့် ဆေးကုထုံးများ ထိရောက်ရန်အတွက် လိုအပ်ကြောင်းရှင်းပြခြင်း။\n'
          }
        />

        <h2 className={styles.label}>အတူနေမိသားစုဝင်များကိုမေးရန်</h2>
        <Answer
          id="၁၅"
          ques="လူနာ၏မိသားစုဝင်များသည် နှာခေါင်းစည်းကိုအချိန်ဘယ်လောက်ကြာကြာတပ်ရန်လိုအပ်သလဲသိပါသလား။"
          ans={
            'မသိပါ\nလုပ်ဆောင်ချက်များ\n-မိသားစုများနှင့် အတူရှိနေသည့်အချိန်တိုင်း တပ်ထားရန် မပေါ့ဆဖို့ မှာကြားရန်။\n'
          }
        />

        <Answer
          id="၁၆"
          ques="လူနာ၏မိသားစုဝင်များသည် နေအိမ်တွင်လေဝင်လေထွက်ကောင်းမွန်ခြင်း၏အရေးပါပုံကိုသိရှိပြီးပြူတင်းပေါက်တံခါးများအားဖွင့်ထားခြင်းရှိပါသလား။"
          ans={`မရှိပါ\nလုပ်ဆောင်ချက်များ\n-လေဝင်လေထွက်ရှိအောင် တတ်နိုင်သမျှ ထားရန်။\n`}
        />

        <Answer
          id="၁၇"
          ques="လေဝင်လေထွက်ကောင်းမွန်စေခြင်းဖြင့် တီဘီရောဂါကူးစက်ပြန့်နှံ့မှုကိုထိန်းချုပ်ရန်အတွက် လူနာအိမ်ရှိပြတင်းပေါက်တံခါးများသည်လုံလောက်မှုနှင့် ဖွင့်ထားခြင်းရှိမရှိကြည့်ရှုစစ်ဆေးရန်   (လေဝင်လေထွက်ကောင်းမွန်စေရန် ပြတင်းပေါက်တစ်ခုနှင့်အထက်ရှိသင့်သည့်အပြင်ဖွင့်ထားသင့် သည်။ ပြတင်းပေါက်များမျက်နှာခြင်းဆိုင်ဖြစ်ပါက လေဝင်လေထွက်ကိုပိုမိုကောင်းမွန်စေသည်။)"
          ans={`မရှိပါ\nလုပ်ဆောင်ချက်များ\n-ပြတင်းပေါက်များ ပန်ကာများ လေအေးပေးစက်များ၏ ဆောင်ရန် ရှောင်ရန်များ ပြောကြားအသိပေးခြင်း။\n`}
        />

        <Answer
          id="၁၈"
          ques="လူနာအိမ်သို့လာစဉ်အတွင်း ဧည့်သည်အဝင်အထွက်ရှိမရှိကြည့်ရှုစစ်ဆေးရန်"
          ans={'မရှိပါ\n'}
        />

        <h2 className={styles.label}>
          ကျန်းမာရေးဝန်ထမ်းများ မိမိကိုယ်ကိုကာကွယ်ရန်
        </h2>
        <Answer
          id="၁၉"
          ques="လူနာသည် ရောဂါပိုးကူးစက်နိုင်သည့် ကာလအတွင်း ကျန်းမာရေးစေတနာ့ဝန်ထမ်းများဆေးလာတိုက်ချိန်တွင် နှာခေါင်းစည်းတပ်ဆင်ထားခြင်းရှိပါသလား။"
          ans={
            'မရှိပါ\nလုပ်ဆောင်ချက်များ\n-ဆေးတိုက်ချိန်တွင် လူနာက Mask, စေတနာ့ဝန်ထမ်းက N 95 ကို တပ်ဆင်ထားရန် အရေးပါပုံ။\n'
          }
        />
      </Container>
    </CustomModal>
  )
}

export default InfectionCheckDetail

const lineBreak = (paragraph) => {
  return paragraph?.split('\n').map((line, index) => <p key={index}>{line}</p>)
}

const Answer = ({ id, ques, ans }) => {
  return (
    <>
      <h3>မေးခွန်း-{id}</h3>
      <h4>{ques}</h4>
      {lineBreak(ans)}
      <hr />
    </>
  )
}
