import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import { dones } from 'variables/options'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { currentDate } from 'utils/currentDate'
import { conclusionOfTSTResults } from 'variables/options'
import CustomNumberInput from 'components/Inputs/CustomNumberInput'
import { connect } from 'react-redux'
import { updatePatient } from 'store/actions'
import { useParams } from 'react-router'
import { useQuery } from 'hooks/useQuery'

const TuberculinSkin = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()
  const { id } = useParams()
  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false)
  const [tuberculinSkinDoneNotDone, setTuberculinSkinDoneNotDone] = useState(
    null,
  )
  const [conclusionOfTSTResult, setConclusionOfTSTResult] = useState(null)

  useEffect(() => {
    const {
      tuberculin_skin_done_or_not_done,
      TST_examination_date,
      size_of_induration_in_millimeter,
      conclusion_of_TST,
    } = patient.patient

    setValue(
      'tuberculin_skin_done_not_done',
      tuberculin_skin_done_or_not_done === null
        ? null
        : {
            value: tuberculin_skin_done_or_not_done,
            label: tuberculin_skin_done_or_not_done,
          },
    )
    setTuberculinSkinDoneNotDone(
      tuberculin_skin_done_or_not_done === null
        ? null
        : {
            value: tuberculin_skin_done_or_not_done,
            label: tuberculin_skin_done_or_not_done,
          },
    )
    setValue('TST_examination_date', TST_examination_date)
    setValue(
      'size_of_induration_in_millimeter',
      size_of_induration_in_millimeter,
    )
    setValue(
      'conclusion_of_TST_result',
      conclusion_of_TST === null
        ? null
        : { value: conclusion_of_TST, label: conclusion_of_TST },
    )
    setConclusionOfTSTResult(
      conclusion_of_TST === null
        ? null
        : { value: conclusion_of_TST, label: conclusion_of_TST },
    )
  }, [setValue, patient.patient])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatient(
      id,
      {
        tuberculin_skin_done_or_not_done:
          data.tuberculin_skin_done_not_done?.value,
        TST_examination_date:
          data.tuberculin_skin_done_not_done?.value === 'Not Done'
            ? null
            : data.TST_examination_date,
        size_of_induration_in_millimeter:
          data.tuberculin_skin_done_not_done?.value === 'Not Done'
            ? null
            : data.size_of_induration_in_millimeter,
        conclusion_of_TST:
          data.tuberculin_skin_done_not_done?.value === 'Not Done'
            ? null
            : data.conclusion_of_TST_result?.value,
      },
      'tuberculin-skin',
      'Tuberculin Skin',
    )
    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>Tuberculin Skin Examination</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="tuberculin_skin_done_not_done"
              label="Tuberculin Skin Done or Not Done"
              control={control}
              rules={{
                required: 'Tuberculin Skin Done or Not Done is required!',
              }}
              options={dones}
              value={tuberculinSkinDoneNotDone}
              setData={setTuberculinSkinDoneNotDone}
              placeholder="Tuberculin Skin Done or Not Done"
              isRequired={true}
            />
          </Col>
          <Col md={4}>
            <CustomDateInput
              id="TST_examination_date"
              label="TST Examination Date"
              register={{
                ...register('TST_examination_date', {
                  required:
                    tuberculinSkinDoneNotDone?.value === 'Done'
                      ? 'TST Examination Date is required!'
                      : false,
                }),
              }}
              placeholder="TST Examination Date"
              errors={errors}
              max={currentDate()}
              disabled={
                tuberculinSkinDoneNotDone === null ||
                tuberculinSkinDoneNotDone?.value !== 'Done'
              }
              isRequired={tuberculinSkinDoneNotDone?.value === 'Done'}
            />
          </Col>
          <Col md={4}>
            <CustomNumberInput
              id="size_of_induration_in_millimeter"
              label="Size of induration in millimeter"
              register={{
                ...register('size_of_induration_in_millimeter', {
                  required:
                    tuberculinSkinDoneNotDone?.value === 'Done'
                      ? 'Size of induration in millimeter is required!'
                      : false,
                }),
              }}
              placeholder="Enter Size of induration in millimeter"
              errors={errors}
              disabled={
                tuberculinSkinDoneNotDone === null ||
                tuberculinSkinDoneNotDone?.value !== 'Done'
              }
              isRequired={tuberculinSkinDoneNotDone?.value === 'Done'}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="conclusion_of_TST_result"
              label="Conclusion of TST"
              control={control}
              rules={{
                required:
                  tuberculinSkinDoneNotDone?.value === 'Done'
                    ? 'Conclusion of TST is required!'
                    : false,
              }}
              options={conclusionOfTSTResults}
              value={conclusionOfTSTResult}
              setData={setConclusionOfTSTResult}
              placeholder="Conclusion of TST"
              isRequired={tuberculinSkinDoneNotDone?.value === 'Done'}
              isDisabled={
                tuberculinSkinDoneNotDone === null ||
                tuberculinSkinDoneNotDone?.value !== 'Done'
              }
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
      {fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
      </CardFooter>
    </Form>
  )
}

const mapStateToProps = (store) => ({
  patient: store.patient,
})

export default connect(mapStateToProps, { updatePatient })(TuberculinSkin)
