import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  CREATE_VOLUNTEER,
  DELETE_VOLUNTEER,
  SET_VOLUNTEER,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_VOLUNTEERS,
  UPDATE_VOLUNTEER,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getVolunteers = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `volunteers?${new URLSearchParams(query).toString()}`,
      )

      dispatch({
        type: SHOW_VOLUNTEERS,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getVolunteer = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `volunteers/${id}`)
      const result = response.data

      dispatch({
        type: SET_VOLUNTEER,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const createVolunteer = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('post', 'volunteers', data)
      const result = response.data

      dispatch({
        type: CREATE_VOLUNTEER,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Volunteer has been created successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const updateVolunteer = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await call('post', `volunteers/${id}?_method=PUT`, data)
      const result = response.data

      dispatch({
        type: UPDATE_VOLUNTEER,
        payload: result,
      })
      dispatch({
        type: SET_SUCCESS,
        payload: true,
      })
      NotificationManager.success('Volunteer has been updated successfully!')
    } catch (error) {
      const { status, data } = error.response
      if (status === 400) {
        const obj = data.data
        NotificationManager.error(obj[Object.keys(obj)[0]])
      } else if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
      dispatch({
        type: SET_SUCCESS,
        payload: false,
      })
    }
  }
}

export const recoverVolunteer = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('get', `volunteers-recover/${id}`)

      dispatch({ type: DELETE_VOLUNTEER, payload: id })
      NotificationManager.success('Volunteer has been recovered successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const temDeleteVolunteer = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('get', `volunteers-delete/${id}`)

      dispatch({ type: DELETE_VOLUNTEER, payload: id })
      NotificationManager.success('Volunteer has been deleted successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const deleteVolunteer = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      await call('delete', `volunteers/${id}`)

      dispatch({ type: DELETE_VOLUNTEER, payload: id })
      NotificationManager.success('Volunteer has been deleted successfully!')
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
