import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomModal from 'components/Modal/CustomModal'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { createPatientMedicine } from 'store/actions'

const PatientMedicineCreate = ({
  isOpen,
  toggle,
  status,
  createPatientMedicine,
  medicine,
}) => {
  const { handleSubmit, reset, control } = useForm()

  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [selectedMedicine, setSelectedMedicine] = useState(null)

  const medicines = medicine.medicines.map((element) => ({
    value: `${element.name}(${element.abbreviation})`,
    label: `${element.name}(${element.abbreviation})`,
  }))

  const onSubmit = async (data) => {
    setLoading(true)
    await createPatientMedicine({
      patient_id: id,
      medicine_name: data.medicine?.value,
    })
    setLoading(false)
  }

  const formReset = useCallback(() => {
    setSelectedMedicine(null)
    reset()
  }, [reset, setSelectedMedicine])

  useEffect(() => {
    if (status.success) {
      formReset()
    }

    return () => formReset()
  }, [status.success, formReset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Patient's Medicine"
      onClick={() => {
        toggle(false)
        formReset()
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomSelect
            id="medicine"
            label="Select Medicine"
            rules={{ required: 'Medicine is required!' }}
            control={control}
            options={medicines}
            value={selectedMedicine}
            setData={setSelectedMedicine}
            placeholder="Select Medicine"
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Save'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  medicine: store.medicine,
})

export default connect(mapStateToProps, { createPatientMedicine })(
  PatientMedicineCreate,
)
