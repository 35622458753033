const handleDate = (date) => {
  var dd = String(date.getDate()).padStart(2, '0')
  var mm = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
  var yyyy = date.getFullYear()

  date = yyyy + '-' + mm + '-' + dd

  return date
}

export const handleDateArray = (startDate, endDate) => {
  let arr = [],
    dt = new Date(startDate)

  while (dt <= endDate) {
    arr.push(handleDate(new Date(dt)))
    dt.setDate(dt.getDate() + 1)
  }

  return arr
}
