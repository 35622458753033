import React from 'react'
import { Table } from 'reactstrap'

const CustomTable = ({ header, body }) => {
  return (
    <Table className="app-table align-items-center table-flush" responsive>
      <thead className="thead-light">{header}</thead>
      <tbody className="list">{body}</tbody>
    </Table>
  )
}

export default CustomTable
