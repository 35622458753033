import React from 'react'
import CustomModal from 'components/Modal/CustomModal'
import { Col, Container, Row, Table } from 'reactstrap'
import { connect } from 'react-redux'

const HEDetail = ({ isOpen, toggle, he }) => {
  const {
    date,
    place,
    male,
    female,
    total,
    move,
    HIV,
    diabetes,
    elderly,
    pregnant,
    ordinary_people,
    other,
    referrals,
    topic_discussed,
    topic_discussed_other,
  } = he.he

  return (
    <CustomModal
      isOpen={isOpen}
      title="ကျန်းမာရေးအသိပညာပေးခြင်း အသေးစိတ်"
      onClick={() => toggle(false)}
      style={{ maxWidth: '60%' }}
    >
      <Container fluid>
        <Row className="mb-3">
          <Col sm={6}>နေ့စွဲ</Col>
          <Col sm={6}>{date}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ကျင်းပသည့်နေရာ</Col>
          <Col sm={6}>{place}</Col>
        </Row>
        <Row className="mb-3">
          <Col>တက်ရောက်သူအရေအတွက်</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ကျား</Col>
          <Col sm={6}>{male}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>မ</Col>
          <Col sm={6}>{female}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>စုစုပေါင်း</Col>
          <Col sm={6}>{total}</Col>
        </Row>
        <Row className="mb-3">
          <Col>ဦးတည်အုပ်စု</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ရွှေ့ပြောင်း</Col>
          <Col sm={6}>{move}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>HIV ရောဂါပိုးရှိသူ</Col>
          <Col sm={6}>{HIV}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ဆီးချိုရောဂါရှိသူ</Col>
          <Col sm={6}>{diabetes}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>သက်ကြီးရွယ်အို</Col>
          <Col sm={6}>{elderly}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ကိုယ်ဝန်ဆောင်</Col>
          <Col sm={6}>{pregnant}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>သာမန်လူ</Col>
          <Col sm={6}>{ordinary_people}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>အခြား</Col>
          <Col sm={6}>{other}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ဆွေးနွေးခဲ့သောအကြောင်းအရာ</Col>
          <Col sm={6}>{topic_discussed}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ဆွေးနွေးခဲ့သောအကြောင်းအရာ(အခြား)</Col>
          <Col sm={6}>{topic_discussed_other}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ညွှန်းပို့ခံရသူအရေအတွက်</Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Table
              className="app-table align-items-center table-flush"
              responsive
            >
              <thead>
                <tr>
                  <th>Male</th>
                  <th>Female</th>
                  <th>Total</th>
                  <th>Place</th>
                </tr>
              </thead>
              <tbody>
                {referrals?.map((referral) => (
                  <tr key={referral.id}>
                    <td>{referral.male}</td>
                    <td>{referral.female}</td>
                    <td>{referral.total}</td>
                    <td>{referral.place}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  he: store.he,
})

export default connect(mapStateToProps)(HEDetail)
