import React, { useEffect, useState } from 'react'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Container } from 'reactstrap'
import InfectionCheckDetail from './InfectionCheckDetail'
import { connect } from 'react-redux'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import CustomAlert from 'components/Alert/CustomAlert'
import { patientMiddleware, DELETE } from 'utils/middleware'
import BackBtn from 'utils/backBtn'

const InfectionCheckList = ({ status, auth }) => {
  const { role } = auth.user
  const [alert, setAlert] = useState(null)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {}, [])

  const handleDelete = () => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <h3 className="mb-0">(Mg Mg)'s Infection Check List</h3>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            }
            body={
              <tr key={1}>
                <td>{1}</td>
                <td>2022-10-09</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => {
                      setIsOpen(true)
                    }}
                  >
                    Detail
                  </Button>
                  {patientMiddleware(role, DELETE) && (
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete()}
                    >
                      Delete
                    </Button>
                  )}
                </td>
              </tr>
            }
          />
        </Card>
      </Container>

      <InfectionCheckDetail isOpen={isOpen} toggle={setIsOpen} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
})

export default connect(mapStateToProps)(InfectionCheckList)
