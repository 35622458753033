import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
} from 'reactstrap'
import queryString from 'query-string'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import styles from './NoAppDotList.module.css'
import { currentDate } from 'utils/currentDate'
import BackBtn from 'utils/backBtn'
import NoAppDotCreate from './NoAppDotCreate'
import {
  getNoAppDOTs,
  getPatient,
  changeStatusNoApp,
  deleteDOT,
  deleteStatusNoApp,
} from 'store/actions'
import CustomPagination from 'components/Pagination/Pagination'
import CustomAlert from 'components/Alert/CustomAlert'
import { NotificationManager } from 'react-notifications'
import { call } from 'services/api'
import { excelExport } from 'utils/excelExport'

const NoAppDotList = ({
  status,
  patient,
  DOT,
  getNoAppDOTs,
  getPatient,
  changeStatusNoApp,
  deleteDOT,
  deleteStatusNoApp,
}) => {
  const { id } = useParams()
  const router = useHistory()

  const [exportLoading, setExportLoading] = useState(false)
  const [alert, setAlert] = useState(null)
  const [isCreate, setIsCreate] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const handleFilter = () => {
    const query = queryString.parse(router.location.search)
    query.page = 1
    if (startDate !== '') {
      query.start_date = startDate
    } else {
      delete query['start_date']
    }

    if (endDate !== '') {
      query.end_date = endDate
    } else {
      delete query['end_date']
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`)
    setStartDate('')
    setEndDate('')
  }

  useEffect(() => {
    const query = queryString.parse(router.location.search)
    if (!('page' in query)) {
      query.page = 1
    }
    getNoAppDOTs(id, query)
  }, [getNoAppDOTs, id, router.location.search])

  useEffect(() => {
    getPatient(id)
  }, [getPatient, id])

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteDOT(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  const handleExport = async () => {
    setExportLoading(true)
    try {
      const query = queryString.parse(router.location.search)
      const response = await call(
        'get',
        `export-NoApp-DOTs/${id}?${new URLSearchParams(query).toString()}`,
      )
      const result = response.data.map((data, index) => ({
        ID: index + 1,
        Patient_Name: data.patient.name,
        Date: data.date,
        Work_Status: data.work_status ? 'အောင်မြင်ပါသည်' : 'မအောင်မြင်ပါ',
      }))

      if (response.status === 'success') {
        excelExport(result, 'DOT-NoAPP')
      }
    } catch (_) {
      NotificationManager.error('Please try again!')
    }
    setExportLoading(false)
  }

  let yellow = 0
  let black = 0
  let white = 0
  DOT.DOTs.forEach((DOT) => {
    if (DOT.work_status === null) {
      white++
    } else {
      if (DOT.work_status) {
        yellow++
      } else {
        black++
      }
    }
  })

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">
                  ({patient.patient?.name}) VOT Visit Date (No App)
                </h3>
              </Col>
              {patient.patient?.type === 'No App' && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    className="mb-3"
                    onClick={() => setIsCreate(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={4}>
                <label htmlFor="start_date">Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  min=""
                  max={currentDate()}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={4}>
                <label htmlFor="end_date">End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  min={startDate}
                  max={currentDate()}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ''}
                />
              </Col>
              <Col sm={4}>
                <Button
                  size="sm"
                  color="success"
                  className="mt-4"
                  onClick={handleFilter}
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="info"
                  className="mt-4"
                  onClick={handleExport}
                  disabled={exportLoading}
                >
                  {exportLoading ? 'Loading' : 'Export'}
                </Button>
              </Col>
            </Row>
            <h5 className='mt-3'>
              အောင်မြင်သည့်အရေအတွက်{' '}
              <Button
                size="sm"
                style={{ backgroundColor: 'yellow', color: '#000' }}
              >
                {yellow}
              </Button>
            </h5>
            <h5>
              မအောင်မြင်သည့်အရေအတွက်{' '}
              <Button
                size="sm"
                style={{ backgroundColor: '#000', color: '#fff' }}
              >
                {black}
              </Button>
            </h5>
            <h5>
              မသွားရသေးသည့် ရက်စုစုပေါင်း{' '}
              <Button
                size="sm"
                style={{ backgroundColor: '#fff', color: '#000' }}
              >
                {white}
              </Button>
            </h5>
          </CardHeader>
          <CardBody>
            <Row>
              {DOT.DOTs.map((DOT, index) => {
                let style = null

                if (DOT.work_status === null) {
                  style = null
                } else {
                  if (DOT.work_status) {
                    style = { backgroundColor: 'yellow', color: '#000' }
                    yellow++
                  } else {
                    style = { backgroundColor: '#000', color: '#fff' }
                    black++
                  }
                }

                return (
                  <Col
                    key={index}
                    xs={3}
                    md={3}
                    lg={2}
                    className={`text-center ${styles.box}`}
                    style={style}
                  >
                    <i
                      className="ni ni-check-bold mr-1"
                      onClick={async () => await changeStatusNoApp(DOT.id)}
                    />
                    {DOT.date}
                    <i
                      className="ni ni-fat-delete ml-1"
                      onClick={async () => await deleteStatusNoApp(DOT.id)}
                    />
                    <i
                      className="ni ni-fat-remove ml-1"
                      onClick={() => handleDelete(DOT.id)}
                    />
                  </Col>
                )
              })}
            </Row>
          </CardBody>
          {DOT.total > 28 && <CustomPagination pageCount={DOT.total / 28} />}
        </Card>
      </Container>
      <NoAppDotCreate isOpen={isCreate} toggle={setIsCreate} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  patient: store.patient,
  DOT: store.DOT,
})

export default connect(mapStateToProps, {
  getNoAppDOTs,
  getPatient,
  changeStatusNoApp,
  deleteDOT,
  deleteStatusNoApp,
})(NoAppDotList)
