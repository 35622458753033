import React, { useEffect, useState } from 'react'
import CustomTable from 'components/Table/Table'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import PatientMedicineCreate from './PatientMedicineCreate'
import PatientMedicineUpdate from './PatientMedicineUpdate'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  getPatientMedicines,
  deletePatientMedicine,
  getPatientMedicine,
  getPatient,
  getMedicines,
} from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import CustomAlert from 'components/Alert/CustomAlert'
import { patientMiddleware, CREATE } from 'utils/middleware'
import BackBtn from 'utils/backBtn'

const PatientMedicineList = ({
  status,
  auth,
  patientMedicine,
  patient,
  getPatientMedicines,
  getPatientMedicine,
  deletePatientMedicine,
  getPatient,
  getMedicines,
}) => {
  const { role } = auth.user
  const { id } = useParams()
  const [alert, setAlert] = useState(null)

  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)

  useEffect(() => {
    getPatientMedicines(id)
  }, [getPatientMedicines, id])

  useEffect(() => {
    getPatient(id)
  }, [getPatient, id])

  useEffect(() => {
    getMedicines()
  }, [getMedicines])

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deletePatientMedicine(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">
                  ({patient.patient?.name}) Medicine List
                </h3>
              </Col>
              {patientMiddleware(role, CREATE) &&
                !patient.patient?.DOT_end_date && (
                  <Col className="text-right" xs="6">
                    <Button
                      size="sm"
                      color="success"
                      onClick={() => setIsCreateOpen(true)}
                      className="mb-3"
                    >
                      New
                    </Button>
                  </Col>
                )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                {patientMiddleware(role, CREATE) &&
                  !patient.patient?.DOT_end_date && <th scope="col">Action</th>}
              </tr>
            }
            body={patientMedicine.patientMedicines.map(
              (patientMedicine, index) => (
                <tr key={patientMedicine.id}>
                  <td>{index + 1}</td>
                  <td>{patientMedicine.medicine_name}</td>
                  {patientMiddleware(role, CREATE) &&
                    !patient.patient?.DOT_end_date && (
                      <td>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={async () => {
                            await getPatientMedicine(patientMedicine.id)
                            setIsUpdateOpen(true)
                          }}
                        >
                          Update
                        </Button>
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleDelete(patientMedicine.id)}
                        >
                          Delete
                        </Button>
                      </td>
                    )}
                </tr>
              ),
            )}
          />
        </Card>
      </Container>

      <PatientMedicineUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <PatientMedicineCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  patient: store.patient,
  patientMedicine: store.patientMedicine,
})

export default connect(mapStateToProps, {
  getPatientMedicines,
  getPatientMedicine,
  deletePatientMedicine,
  getPatient,
  getMedicines,
})(PatientMedicineList)
