import React from "react";
import { Pie } from "react-chartjs-2";
import { Card, CardBody, Row, Col, CardHeader, Button } from "reactstrap";
import { pieChartOptions } from "utils/chartConfig";
import { generatePDF } from "utils/generatePDF";
import ChartDataLabels from "chartjs-plugin-datalabels";

const PieChart = ({ title, total, className, data }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader>
            <Row>
              <Col xs="6">
                <h3>{title}</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button size="sm" color="primary">
                  Total - {total}
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={() => generatePDF(className, `${className}.pdf`)}
                >
                  Export
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className={className}>
              <Pie
                data={data}
                options={pieChartOptions}
                plugins={[ChartDataLabels]}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PieChart;
