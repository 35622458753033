import {
  CREATE_VOLUNTEER,
  DELETE_VOLUNTEER,
  SET_VOLUNTEER,
  SHOW_VOLUNTEERS,
  UPDATE_VOLUNTEER,
} from '../type'

const initialState = {
  volunteers: [],
  volunteer: {},
  total: 0,
}

const volunteer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_VOLUNTEERS:
      return {
        ...state,
        volunteers: action.payload.data,
        total: action.payload.total,
      }
    case CREATE_VOLUNTEER:
      return {
        ...state,
        volunteers: [action.payload, ...state.volunteers],
        total: state.total + 1,
      }
    case DELETE_VOLUNTEER:
      return {
        ...state,
        volunteers: state.volunteers.filter(
          (volunteer) => volunteer.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_VOLUNTEER:
      return {
        ...state,
        volunteer: action.payload,
      }
    case UPDATE_VOLUNTEER:
      let index = state.volunteers.findIndex(
        (volunteer) => volunteer.id === action.payload.id,
      )
      state.volunteers[index] = action.payload
      if (state.volunteer.id === action.payload.id) {
        state.volunteer = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default volunteer
