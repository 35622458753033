import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader, Container, Row, Col } from 'reactstrap'
import CustomTable from 'components/Table/Table'
import DistrictCreate from './DistrictCreate'
import DistrictUpdate from './DistrictUpdate'
import { connect } from 'react-redux'
import { getDistricts, getDistrict, deleteDistrict } from 'store/actions'
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading'
import CustomAlert from 'components/Alert/CustomAlert'
import { basicDataMiddleware, CREATE } from 'utils/middleware'
import BackBtn from 'utils/backBtn'

const DistrictList = ({
  status,
  auth,
  district,
  getDistricts,
  getDistrict,
  deleteDistrict,
}) => {
  const { role } = auth.user
  const [alert, setAlert] = useState(null)

  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isUpdateOpen, setIsUpdateOpen] = useState(false)

  useEffect(() => {
    getDistricts()
  }, [getDistricts])

  const handleDelete = (id) => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          deleteDistrict(id)
          setAlert(null)
        }}
        onCancel={() => setAlert(null)}
      />,
    )
  }

  if (status.loading) {
    return <FullScreenLoading />
  }

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Districts</h3>
              </Col>
              {basicDataMiddleware(role, CREATE) && (
                <Col className="text-right" xs="6">
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => setIsCreateOpen(true)}
                  >
                    New
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                {basicDataMiddleware(role, CREATE) && (
                  <th scope="col">Action</th>
                )}
              </tr>
            }
            body={district.districts.map((district, index) => (
              <tr key={district.id}>
                <td>{index + 1}</td>
                <td>{district.name}</td>
                {basicDataMiddleware(role, CREATE) && (
                  <td>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={async () => {
                        await getDistrict(district.id)
                        setIsUpdateOpen(true)
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => handleDelete(district.id)}
                    >
                      Delete
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          />
        </Card>
      </Container>

      <DistrictUpdate isOpen={isUpdateOpen} toggle={setIsUpdateOpen} />
      <DistrictCreate isOpen={isCreateOpen} toggle={setIsCreateOpen} />
    </>
  )
}

const mapStateToProps = (store) => ({
  auth: store.auth,
  status: store.status,
  district: store.district,
})

export default connect(mapStateToProps, {
  getDistricts,
  getDistrict,
  deleteDistrict,
})(DistrictList)
