import React from 'react'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'

const BackBtn = () => {
  const router = useHistory()
  return (
    <Button
      size="sm"
      color="info"
      className="mb-3"
      onClick={() => router.goBack()}
    >
      <i className="fa fa-angle-left" />
    </Button>
  )
}

export default BackBtn
