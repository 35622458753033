import React from 'react'
import CustomModal from 'components/Modal/CustomModal'
import { Col, Container, Row, Table } from 'reactstrap'
import { connect } from 'react-redux'

const ContactTracyDetail = ({ isOpen, toggle, contactTracy }) => {
  const {
    patient,
    date,
    plan,
    contact_tracy_records,
  } = contactTracy.contactTracy

  return (
    <CustomModal
      isOpen={isOpen}
      title='"တီဘီလူနာနှင့်အတူနေသူ" များအား ကျန်းမာရေးစစ်ဆေးခြင်း အသေးစိတ်'
      onClick={() => toggle(false)}
      style={{ maxWidth: '60%' }}
    >
      <Container className="mt-3" fluid>
        <Row className="mb-3">
          <Col sm={6}>ရက်စွဲ</Col>
          <Col sm={6}>{date}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>စီမံချက်</Col>
          <Col sm={6}>{plan}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>မြို့နယ်</Col>
          <Col sm={6}>{patient?.township?.name}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>တီဘီလူနာအမျိုးအစား</Col>
          <Col sm={6}>{patient?.type_of_TB_patient}</Col>
        </Row>
        <Row className="mb-3">
          <Col sm={6}>ညွှန်းပို့ခံရသူအရေအတွက်</Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Table
              className="app-table align-items-center table-flush"
              responsive
            >
              <thead>
                <tr>
                  <th>Male</th>
                  <th>Female</th>
                  <th>Total</th>
                  <th>Index Case</th>
                  <th>Referral Place</th>
                </tr>
              </thead>
              <tbody>
                {contact_tracy_records?.map((contact_tracy_record) => (
                  <tr key={contact_tracy_record.id}>
                    <td>{contact_tracy_record.male}</td>
                    <td>{contact_tracy_record.female}</td>
                    <td>{contact_tracy_record.total}</td>
                    <td>{contact_tracy_record.index_case}</td>
                    <td>{contact_tracy_record.referral_place}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  contactTracy: store.contactTracy,
})

export default connect(mapStateToProps)(ContactTracyDetail)
