import { SET_HE, SHOW_HES } from '../type'

const initialState = {
  hes: [],
  he: {},
  total: 0,
}

const he = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_HES:
      return {
        ...state,
        hes: action.payload.data,
        total: action.payload.total,
      }
    case SET_HE:
      return {
        ...state,
        he: action.payload,
      }

    default:
      return state
  }
}

export default he
