import React from "react";
import { Card, CardBody, CardTitle, Row, Col, Button } from "reactstrap";
import styles from "./IndicatorCard.module.css";
import classnames from 'classnames'

const IndicatorCard = ({ total, indicator, label }) => {
  return (
    <Card className="bg-gradient-danger border-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              className="text-uppercase text-muted mb-0 text-white"
              tag="h3"
            >
              Total - {total}
            </CardTitle>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
              <i className="fas fa-user-friends" />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-sm">
          <Button size="sm">
            Indicator - {indicator}
            <i className={classnames("fa fa-question-circle", styles.tooltip)}>
              <p className={classnames(styles.tooltiptext)}>
                {label}
              </p>
            </i>
          </Button>
        </p>
      </CardBody>
    </Card>
  );
};

export default IndicatorCard;
