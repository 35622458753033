import {
  CREATE_ACCOUNT,
  DELETE_ACCOUNT,
  SET_ACCOUNT,
  SHOW_ACCOUNTS,
  UPDATE_ACCOUNT,
} from '../type'

const initialState = {
  accounts: [],
  account: {},
  total: 0,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload.data,
        total: action.payload.total,
      }
    case CREATE_ACCOUNT:
      return {
        ...state,
        accounts: [action.payload, ...state.accounts],
        total: state.total + 1,
      }
    case DELETE_ACCOUNT:
      return {
        ...state,
        accounts: state.accounts.filter(
          (account) => account.id !== action.payload,
        ),
        total: state.total - 1,
      }
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      }
    case UPDATE_ACCOUNT:
      let index = state.accounts.findIndex(
        (account) => account.id === action.payload.id,
      )
      state.accounts[index] = action.payload
      if (state.account.id === action.payload.id) {
        state.account = action.payload
      }
      return {
        ...state,
      }
    default:
      return state
  }
}

export default account
