import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import { dones } from 'variables/options'
import CustomDateInput from 'components/Inputs/CustomDateInput'
import { currentDate } from 'utils/currentDate'
import { geneXpertResults } from 'variables/options'
import { connect } from 'react-redux'
import { updatePatient } from 'store/actions'
import { useParams } from 'react-router'
import { useQuery } from 'hooks/useQuery'

const GeneXpert = ({ patient, updatePatient }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm()

  const { id } = useParams()

  const fromTBPatient = useQuery("from_TB_patient");

  const [loading, setLoading] = useState(false)
  const [geneXpertDoneNotDone, setGeneXpertDoneNotDone] = useState(null)
  const [geneXpertResult, setGeneXpertResult] = useState(null)

  useEffect(() => {
    const {
      gene_Xpert_done_or_not_done,
      gene_Xpert_examination_date,
      gene_Xpert_result,
    } = patient.patient

    setValue(
      'gene_xpert_done_not_done',
      gene_Xpert_done_or_not_done === null
        ? null
        : {
            value: gene_Xpert_done_or_not_done,
            label: gene_Xpert_done_or_not_done,
          },
    )
    setGeneXpertDoneNotDone(
      gene_Xpert_done_or_not_done === null
        ? null
        : {
            value: gene_Xpert_done_or_not_done,
            label: gene_Xpert_done_or_not_done,
          },
    )
    setValue('gene_xpert_examination_date', gene_Xpert_examination_date)
    setValue(
      'gene_xpert_result',
      gene_Xpert_result === null
        ? null
        : { value: gene_Xpert_result, label: gene_Xpert_result },
    )
    setGeneXpertResult(
      gene_Xpert_result === null
        ? null
        : { value: gene_Xpert_result, label: gene_Xpert_result },
    )
  }, [setValue, patient.patient])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatient(
      id,
      {
        gene_Xpert_done_or_not_done: data.gene_xpert_done_not_done?.value,
        gene_Xpert_examination_date:
          data.gene_xpert_done_not_done?.value === 'Not Done'
            ? null
            : data.gene_xpert_examination_date,
        gene_Xpert_result:
          data.gene_xpert_done_not_done?.value === 'Not Done'
            ? null
            : data.gene_xpert_result?.value,
      },
      'gene-Xpert',
      'Gene Xpert',
    )
    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CardBody>
        <CardTitle>
          <h3>Gene Xpert Examination</h3>
        </CardTitle>
        <Row>
          <Col md={4}>
            <CustomSelect
              id="gene_xpert_done_not_done"
              label="Gene Xpert Done or Not Done"
              control={control}
              rules={{
                required: 'Gene Xpert Done or Not Done is required!',
              }}
              options={dones}
              value={geneXpertDoneNotDone}
              setData={setGeneXpertDoneNotDone}
              placeholder="Gene Xpert Done or Not Done"
              isRequired={true}
            />
          </Col>
          <Col md={4}>
            <CustomDateInput
              id="gene_xpert_examination_date"
              label="GXP Examination Date"
              register={{
                ...register('gene_xpert_examination_date', {
                  required:
                    geneXpertDoneNotDone?.value === 'Done'
                      ? 'GXP Examination Date is required!'
                      : false,
                }),
              }}
              placeholder="Select GXP Examination Date"
              errors={errors}
              max={currentDate()}
              disabled={
                geneXpertDoneNotDone === null ||
                geneXpertDoneNotDone?.value !== 'Done'
              }
              isRequired={geneXpertDoneNotDone?.value === 'Done'}
            />
          </Col>
          <Col md={4}>
            <CustomSelect
              id="gene_xpert_result"
              label="Gene Xpert Result"
              control={control}
              rules={{
                required:
                  geneXpertDoneNotDone?.value === 'Done'
                    ? 'Gene Xpert Result is required!'
                    : false,
              }}
              options={geneXpertResults}
              value={geneXpertResult}
              setData={setGeneXpertResult}
              placeholder="Gene Xpert Result"
              isRequired={geneXpertDoneNotDone?.value === 'Done'}
              isDisabled={
                geneXpertDoneNotDone === null ||
                geneXpertDoneNotDone?.value !== 'Done'
              }
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter className="text-right">
      {fromTBPatient && (
          <Button color="primary" size="sm" disabled={loading} type="submit">
            {loading ? "Loading" : "Update"}
          </Button>
        )}
      </CardFooter>
    </Form>
  )
}

const mapStateToProps = (store) => ({
  patient: store.patient,
})

export default connect(mapStateToProps, { updatePatient })(GeneXpert)
