import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomInput from 'components/Inputs/CustomInput'
import CustomModal from 'components/Modal/CustomModal'
import { connect } from 'react-redux'
import { createMedicine } from 'store/actions'

const MedicineCreate = ({ isOpen, toggle, status, createMedicine }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [loading, setLoading] = useState(false)

  const onSubmit = async (data) => {
    setLoading(true)
    await createMedicine(data)
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      reset()
    }
  }, [status.success, reset])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Create Medicine"
      onClick={() => {
        toggle(false)
        reset()
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomInput
            id="name"
            label="FDC Dose"
            register={{
              ...register('name', {
                required: 'FDC Dose is required!',
              }),
            }}
            placeholder="Enter FDC Dose"
            errors={errors}
          />
          <CustomInput
            id="abbreviation"
            label="Acronym"
            register={{
              ...register('abbreviation', {
                required: 'Acronym is required!',
              }),
            }}
            placeholder="Enter Acronym"
            errors={errors}
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Save'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
})

export default connect(mapStateToProps, { createMedicine })(MedicineCreate)
