import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "reactstrap";
import CustomModal from "components/Modal/CustomModal";
import { connect } from "react-redux";
import CustomTextArea from "components/Inputs/CustomTextArea";
import { updateDOT } from "store/actions";

const NoVolunteerDotRemarkUpdate = ({
  isOpen,
  toggle,
  status,
  DOT,
  updateDOT,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { remark } = DOT.DOT;

    setValue("remark", remark);
  }, [setValue, DOT.DOT]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updateDOT(
      {
        remark: data.remark,
      },
      DOT.DOT.id,
      "DOT-without-volunteer-dashboard"
    );
    setLoading(false);
  };

  useEffect(() => {
    if (status.success) {
      toggle(false);
    }
  }, [status.success, toggle]);

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update VOT"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Row>
            <Col>
              <CustomTextArea
                id="remark"
                label="Remark"
                register={{
                  ...register("remark", {
                    required: "Remark is required!",
                  }),
                }}
                placeholder="Enter Remark"
                errors={errors}
                isRequired={true}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? "Loading" : "Update"}
          </Button>
        </div>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  DOT: store.DOT,
});

export default connect(mapStateToProps, {
  updateDOT,
})(NoVolunteerDotRemarkUpdate);
