import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import { connect } from "react-redux";
import {
  getTownships,
  getVolunteers,
  createPatient,
  getLatestPresumptiveTBNumber,
} from "store/actions";
import { isVolunteerSupervisor } from "utils/middleware";
import BackBtn from "utils/backBtn";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomInput from "components/Inputs/CustomInput";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import {
  sexs,
  referTos,
  caseFoundedActivities,
  riskFactors,
} from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { calculateBMI } from "utils/BMI";
import { TBCodeChooses } from "variables/options";
import { handleRiskFactor } from "utils/DSTBPatient";
import { currentDate } from "utils/currentDate";
import { symptomAges } from "variables/options";
import { greaterThanEqual15Symptoms } from "variables/options";
import { lessThan15Symptoms } from "variables/options";

const DSTBPatientCreate = ({
  status,
  auth,
  patient,
  township,
  volunteer,
  getTownships,
  getVolunteers,
  createPatient,
  getLatestPresumptiveTBNumber,
}) => {
  const { role } = auth.user;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [sex, setSex] = useState(null);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [referTo, setReferTo] = useState(null);
  const [BMI, setBMI] = useState(0);
  const [caseFoundedActivity, setCaseFoundedActivity] = useState(null);
  const [TBCodeChoose, setTBCodeChoose] = useState(null);
  const [symptomAge, setSymptomAge] = useState(null);
  const [symptom, setSymptom] = useState(null);
  const [riskFactor, setRiskFactor] = useState(null);

  const projects = [
    { value: "AIS", label: "AIS" },
    { value: "GF", label: "GF" },
    { value: "MMA", label: "MMA" },
  ];

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const onSubmit = async (data) => {
    setLoading(true);
    await createPatient({
      // township_id:
      //   role !== undefined && !isVolunteerSupervisor(role)
      //     ? data.township?.value
      //     : auth.user?.township?.id,
      township_id: data.township?.value,
      name: data.name,
      address: data.address,
      phone: data.phone,
      age: data.age,
      sex: data.sex?.value,
      referral_volunteer_id: data.referral_volunteer?.value,
      referral_date: data.referral_date,
      refer_to: data.refer_to?.value,
      refer_to_other: data.refer_to_other,
      height: data.height,
      weight: data.weight,
      BMI: data.BMI,
      case_founded_activity: data.case_founded_activity?.value,
      TB_code: data.TB_code,
      option: data.TB_code_choose?.value,
      referred_type: data.referred_type?.value,
      symptom_age: data.symptom_age?.value,
      symptoms: data.symptoms?.map((el) => el.value).join("|"),
      presumptive_TB_number: data.presumptive_TB_number,
      risk_factor: data.risk_factor?.map((el) => el.value).join("|"),
      registration_date: data.registration_date,
      project: data.project?.value,
    });
    setLoading(false);
  };

  const formReset = useCallback(() => {
    reset();
    setSelectedTownship(null);
    setSex(null);
    setSelectedVolunteer(null);
    setReferTo(null);
    setCaseFoundedActivity(null);
    setSymptomAge(null);
    setSymptom(null);
    setRiskFactor(null);
  }, [
    reset,
    setSelectedTownship,
    setSex,
    setSelectedVolunteer,
    setReferTo,
    setCaseFoundedActivity,
    setSymptomAge,
    setSymptom,
    setRiskFactor,
  ]);

  useEffect(() => {
    if (status.success) {
      formReset();
    }

    return () => formReset();
  }, [status.success, formReset]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  useEffect(() => {
    getVolunteers();
  }, [getVolunteers]);

  useEffect(() => {
    setValue("presumptive_TB_number", patient.latestPresumptiveTBNumber);
  }, [patient.latestPresumptiveTBNumber, setValue]);


  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader className="border-0">
            <h3>Create DSTB Patient</h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                {role !== undefined && !isVolunteerSupervisor(role) ? (
                  <CustomSelect
                    id="township"
                    label="Township"
                    rules={{ required: "Township is required!" }}
                    control={control}
                    options={townships}
                    value={selectedTownship}
                    setData={(data) => {
                      setSelectedTownship(data);
                      getLatestPresumptiveTBNumber(data?.value);
                    }}
                    placeholder="Township"
                  />
                ) : (
                  <CustomSelect
                    id="township"
                    label="Township"
                    rules={{ required: "Township is required!" }}
                    control={control}
                    options={[
                      {
                        value: auth.user?.township?.id,
                        label: auth.user?.township?.name,
                      },
                    ]}
                    value={selectedTownship}
                    setData={(data) => {
                      setSelectedTownship(data);
                      getLatestPresumptiveTBNumber(data?.value);
                    }}
                    placeholder="Township"
                  />
                )}
              </Col>
              <Col md={4}>
                <CustomInput
                  id="name"
                  label="Name"
                  register={{
                    ...register("name", {
                      required: "Name is required!",
                    }),
                  }}
                  placeholder="Enter Name"
                  errors={errors}
                />
              </Col>
              <Col md={4}>
                <CustomInput
                  id="address"
                  label="Address"
                  register={{
                    ...register("address", {
                      required: "Address is required!",
                    }),
                  }}
                  placeholder="Enter Address"
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomInput
                  id="phone"
                  label="Phone"
                  register={{
                    ...register("phone", {
                      required: "Phone is required!",
                    }),
                  }}
                  placeholder="Enter Phone"
                  errors={errors}
                />
              </Col>
              <Col md={4}>
                <CustomNumberInput
                  id="age"
                  label="Age"
                  register={{
                    ...register("age", {
                      required: "Age is required!",
                    }),
                  }}
                  placeholder="Enter age"
                  errors={errors}
                  onChange={(event) => {
                    let age = event.target.value;

                    setSymptom(null);
                    setValue("symptoms", null);
                    if (+age >= 15) {
                      setValue("symptom_age", symptomAges[0]);
                      setSymptomAge(symptomAges[0]);
                    } else {
                      setValue("symptom_age", symptomAges[1]);
                      setSymptomAge(symptomAges[1]);
                    }
                  }}
                  max={120}
                />
              </Col>
              <Col md={4}>
                <CustomSelect
                  id="sex"
                  label="Sex"
                  rules={{ required: "Sex is required!" }}
                  control={control}
                  options={sexs}
                  value={sex}
                  setData={setSex}
                  placeholder="Sex"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="referral_volunteer"
                  label="Referral volunteer"
                  control={control}
                  options={volunteers}
                  rules={{
                    required: false,
                  }}
                  value={selectedVolunteer}
                  setData={(data) => {
                    setCaseFoundedActivity(null);
                    setSelectedVolunteer(data);
                  }}
                  placeholder="Referral volunteer"
                  isRequired={false}
                />
              </Col>
              <Col md={4}>
                <CustomSelect
                  id="project"
                  label="Project"
                  control={control}
                  options={projects}
                  rules={{
                    required: false,
                  }}
                  value={selectedProject}
                  setData={(data) => {
                    setCaseFoundedActivity(null);
                    setSelectedProject(data);
                  }}
                  placeholder="Project"
                  isRequired={false}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomDateInput
                  id="referral_date"
                  label="Referral Date"
                  register={{
                    ...register("referral_date", {
                      required: false,
                    }),
                  }}
                  placeholder="Select Referral Date"
                  errors={errors}
                  isRequired={false}
                  max={currentDate()}
                />
              </Col>
              <Col md={4}>
                <CustomSelect
                  id="refer_to"
                  label="Refer To"
                  rules={{ required: false }}
                  control={control}
                  options={referTos}
                  value={referTo}
                  setData={setReferTo}
                  placeholder="Refer To"
                  isRequired={false}
                />
              </Col>
              <Col md={4}>
                <CustomInput
                  id="refer_to_other"
                  label="Refer To Other"
                  register={{
                    ...register("refer_to_other", {
                      required:
                        referTo?.value === "Other"
                          ? "Refer To Other is required!"
                          : false,
                    }),
                  }}
                  placeholder="Enter Refer To Other"
                  errors={errors}
                  disabled={referTo === null || referTo?.value !== "Other"}
                  isRequired={referTo?.value === "Other"}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomNumberInput
                  id="height"
                  label="Height(cm)"
                  register={{
                    ...register("height", {
                      required: "Height(cm) is required!",
                    }),
                  }}
                  placeholder="Enter height(cm)"
                  errors={errors}
                  onChange={(event) => {
                    let height = event.target.value * 0.01;
                    let weight = getValues("weight");

                    let BMI = weight / (height * height);
                    if (!isNaN(BMI) && BMI !== Infinity) {
                      setValue("BMI", BMI.toFixed(1));
                      setBMI(BMI.toFixed(1));
                    }
                  }}
                />
              </Col>
              <Col md={4}>
                <CustomNumberInput
                  id="weight"
                  label="Initial Body Weight(kg)"
                  register={{
                    ...register("weight", {
                      required: "Initial Body Weight(kg) is required!",
                    }),
                  }}
                  placeholder="Enter weight(Kg)"
                  errors={errors}
                  onChange={(event) => {
                    let weight = event.target.value;
                    let height = getValues("height") * 0.01;

                    let BMI = weight / (height * height);
                    if (!isNaN(BMI) && BMI !== Infinity) {
                      setValue("BMI", BMI.toFixed(1));
                      setBMI(BMI.toFixed(1));
                    }
                  }}
                  max={132}
                />
              </Col>
              <Col md={4}>
                <CustomNumberInput
                  id="BMI"
                  label={`BMI ${calculateBMI(parseFloat(BMI))}`}
                  register={{
                    ...register("BMI", {
                      required: "BMI is required!",
                    }),
                  }}
                  placeholder="Enter BMI"
                  errors={errors}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="case_founded_activity"
                  label="Case Founded Activity"
                  rules={{
                    required:
                      selectedVolunteer !== null
                        ? "Case Founded Activity is required!"
                        : false,
                  }}
                  control={control}
                  options={caseFoundedActivities}
                  value={caseFoundedActivity}
                  setData={setCaseFoundedActivity}
                  placeholder="Case Founded Activity"
                  isDisabled={selectedVolunteer === null}
                  isRequired={selectedVolunteer !== null}
                />
              </Col>
              <Col md={4}>
                <CustomInput
                  id="TB_code"
                  label="Index patient’s TB Registration No"
                  register={{
                    ...register("TB_code", {
                      required:
                        caseFoundedActivity?.value === "CI"
                          ? "Index patient’s TB Registration No is required!"
                          : false,
                    }),
                  }}
                  placeholder="Enter Index patient’s TB Registration No"
                  errors={errors}
                  disabled={
                    caseFoundedActivity === null ||
                    caseFoundedActivity?.value !== "CI"
                  }
                  isRequired={caseFoundedActivity?.value === "CI"}
                />
              </Col>
              <Col md={4}>
                <CustomSelect
                  id="TB_code_choose"
                  label="Choose your option"
                  rules={{
                    required:
                      caseFoundedActivity?.value === "CI"
                        ? "Choose your option is required!"
                        : false,
                  }}
                  control={control}
                  options={TBCodeChooses}
                  value={TBCodeChoose}
                  setData={setTBCodeChoose}
                  placeholder="Choose your option"
                  isDisabled={
                    caseFoundedActivity === null ||
                    caseFoundedActivity?.value !== "CI"
                  }
                  isRequired={caseFoundedActivity?.value === "CI"}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomInput
                  id="referred_type"
                  label="Referral Township"
                  register={{
                    ...register("referred_type", {
                      required: false,
                    }),
                  }}
                  placeholder="Enter Referral Township"
                  errors={errors}
                  isRequired={false}
                />
              </Col>
              <Col md={4}>
                <CustomNumberInput
                  id="presumptive_TB_number"
                  label="Presumptive TB Number"
                  register={{
                    ...register("presumptive_TB_number", {
                      required: "Presumptive TB Number is required!",
                    }),
                  }}
                  placeholder="Enter Presumptive TB Number"
                  errors={errors}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="symptom_age"
                  label="Age >=15 or <15"
                  rules={{
                    required: "Age >=15 or <15 is required!",
                  }}
                  control={control}
                  options={symptomAges}
                  value={symptomAge}
                  setData={(data) => {
                    setSymptomAge(data);
                    setSymptom(null);
                  }}
                  placeholder="Age >=15 or <15"
                  isRequired={true}
                  isDisabled={true}
                />
              </Col>
              {symptomAge?.value === ">=15" ? (
                <Col md={8}>
                  <CustomSelect
                    id="symptoms"
                    label="Symptoms"
                    rules={{
                      required: "Symptoms is required!",
                    }}
                    control={control}
                    options={greaterThanEqual15Symptoms}
                    value={symptom}
                    setData={setSymptom}
                    placeholder="Symptoms"
                    isMulti={true}
                  />
                </Col>
              ) : symptomAge?.value === "<15" ? (
                <Col md={8}>
                  <CustomSelect
                    id="symptoms"
                    label="Symptoms"
                    rules={{
                      required: "Symptoms is required!",
                    }}
                    control={control}
                    options={lessThan15Symptoms}
                    value={symptom}
                    setData={setSymptom}
                    placeholder="Symptoms"
                    isMulti={true}
                  />
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="risk_factor"
                  label="Risk Factor"
                  rules={{
                    required: "Risk Factor is required!",
                  }}
                  control={control}
                  options={riskFactors}
                  value={handleRiskFactor(riskFactor)}
                  setData={setRiskFactor}
                  placeholder="Risk Factor"
                  isMulti={true}
                  isRequired={true}
                />
              </Col>
              <Col md={4}>
                <CustomDateInput
                  id="registration_date"
                  label="Registration Date"
                  register={{
                    ...register("registration_date", {
                      required: "Registration Date is required!",
                    }),
                  }}
                  placeholder="Select Registration Date"
                  errors={errors}
                  isRequired={true}
                  max={currentDate()}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-right">
            <Button color="primary" size="sm" disabled={loading} type="submit">
              {loading ? "Loading" : "Save"}
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  getTownships,
  getVolunteers,
  createPatient,
  getLatestPresumptiveTBNumber,
})(DSTBPatientCreate);
