import React from 'react'
import Select from 'react-select'
import { Controller } from 'react-hook-form'
import { FormGroup } from 'reactstrap'

const CustomSelect = ({
  id,
  label,
  rules,
  control,
  options,
  value,
  setData,
  placeholder,
  isRequired = true,
  isDisabled = false,
  isMulti = false,
}) => {
  return (
    <FormGroup>
      <label htmlFor={id}>
        {label} {isRequired && <sup className="text-danger">*</sup>}
      </label>
      <Controller
        name={id}
        id={id}
        rules={rules}
        control={control}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <>
            <Select
              options={options}
              value={value}
              onChange={(value) => {
                onChange(value)
                setData(value)
              }}
              placeholder={placeholder}
              isSearchable={true}
              isDisabled={isDisabled}
              isMulti={isMulti}
              isClearable={true}
            />
            {error && <span className="text-danger">{error?.message}</span>}
          </>
        )}
      />
    </FormGroup>
  )
}

export default CustomSelect
