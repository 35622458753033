import { call, setAccessToken } from '../../services/api'
import { serverErrorMessage } from '../../utils/messages'
import {
  SET_CONTACT_TRACY,
  SET_LOADING,
  SET_SUCCESS,
  SHOW_CONTACT_TRACIES,
} from '../type'
import { NotificationManager } from 'react-notifications'

export const getContactTracies = (query) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    try {
      const result = await call(
        'get',
        `contact-tracies?${new URLSearchParams(query).toString()}`,
      )

      dispatch({
        type: SHOW_CONTACT_TRACIES,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}

export const getContactTracy = (id) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING })
    dispatch({ type: SET_SUCCESS, payload: false })
    try {
      const response = await call('get', `contact-tracies/${id}`)
      const result = response.data

      dispatch({
        type: SET_CONTACT_TRACY,
        payload: result,
      })
    } catch (error) {
      const { status, data } = error.response

      if (status === 401) {
        setAccessToken(null)
        NotificationManager.error(data.data.message)
      } else {
        NotificationManager.error(serverErrorMessage)
      }
    }
    dispatch({ type: SET_LOADING })
  }
}
