import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomPagination from "components/Pagination/Pagination";
import CustomTable from "components/Table/Table";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getPatients, getPatient, getTownships } from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { paginationLimit } from "variables/limits";
import {
  patientMiddleware,
  isVolunteerSupervisor,
  EXPORT,
} from "utils/middleware";
import BackBtn from "utils/backBtn";
import { NotificationManager } from "react-notifications";
import { excelExport } from "utils/excelExport";
import { patientExport } from "utils/patientExport";
import { currentDate } from "utils/currentDate";

const DOTPatientWithVolunteerList = ({
  status,
  auth,
  patient,
  township,
  getPatients,
  getTownships,
}) => {
  const { role } = auth.user;
  const router = useHistory();

  const [exportLoading, setExportLoading] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedProject, setSelectedTProject] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const patients = patient.patients.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const handleFilter = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    delete query["complete"];
    delete query["not_complete"];

    if (startDate !== "") {
      query.start_date = startDate;
    } else {
      delete query["start_date"];
    }

    if (endDate !== "") {
      query.end_date = endDate;
    } else {
      delete query["end_date"];
    }

    if (selectedPatient) {
      query.patient_id = selectedPatient.value;
    } else {
      delete query["patient_id"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    if (selectedProject) {
      query.project = selectedProject.value;
    } else {
      delete query["project"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
  };

  useEffect(() => {
    const query = queryString.parse(router.location.search);
    query.DOT_with_volunteer = true;
    if (!("page" in query)) {
      query.page = 1;
    }
    getPatients(query);
  }, [getPatients, router.location.search]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  const handleComplete = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    query.complete = true;

    delete query["not_complete"];

    if (selectedPatient) {
      query.patient_id = selectedPatient.value;
    } else {
      delete query["patient_id"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    setSelectedPatient(null);
    setSelectedTownship(null);
  };

  const handleNotComplete = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;
    query.not_complete = true;

    delete query["complete"];

    if (selectedPatient) {
      query.patient_id = selectedPatient.value;
    } else {
      delete query["patient_id"];
    }

    if (selectedTownship) {
      query.township_id = selectedTownship.value;
    } else {
      delete query["township_id"];
    }

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    setSelectedPatient(null);
    setSelectedTownship(null);
  };

  const handleAll = () => {
    const query = queryString.parse(router.location.search);
    query.page = 1;

    delete query["complete"];
    delete query["not_complete"];
    delete query["patient_id"];
    delete query["township_id"];

    router.push(`${router.location.pathname}?${queryString.stringify(query)}`);
    setSelectedPatient(null);
    setSelectedTownship(null);
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const query = queryString.parse(router.location.search);
      query.DOT_with_volunteer = true;
      const { response, result } = await patientExport(query);

      let fileName = "DOT-Patients-With-Volunteer";
      if (selectedPatient) {
        fileName += `-${selectedPatient.label}`;
      }
      if (selectedTownship) {
        fileName += `-${selectedTownship.label}`;
      }

      if (response.status === "success") {
        excelExport(result, fileName);
      }
    } catch (_) {
      NotificationManager.error("Please try again!");
    }
    setExportLoading(false);
  };

  return (
    <>
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-3">VOT Patient With Volunteer List</h3>
              </Col>
              <Col className="text-right" xs="6">
                {patientMiddleware(role, EXPORT) && (
                  <Button
                    size="sm"
                    color="info"
                    onClick={handleExport}
                    disabled={exportLoading}
                  >
                    {exportLoading ? "Loading" : "Export"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              {!isVolunteerSupervisor(role) && (
                <Col sm={3}>
                  <label>Townships</label>
                  <Select
                    options={townships}
                    value={selectedTownship}
                    onChange={(value) => setSelectedTownship(value)}
                    placeholder="Filter by township..."
                    isSearchable={true}
                    isClearable={true}
                  />
                </Col>
              )}
              <Col sm={3}>
                <label htmlFor="start_date">DOTS Start Date</label>
                <input
                  id="start_date"
                  className="form-control"
                  type="date"
                  value={startDate}
                  min={""}
                  max={currentDate()}
                  onChange={(event) => setStartDate(event.target.value)}
                />
              </Col>
              <Col sm={3}>
                <label htmlFor="end_date">DOTS End Date</label>
                <input
                  id="end_date"
                  className="form-control"
                  type="date"
                  value={endDate}
                  min={startDate}
                  max={currentDate()}
                  onChange={(event) => setEndDate(event.target.value)}
                  disabled={startDate === ""}
                />
              </Col>
              <Col sm={3}>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleFilter}
                  className="mt-3"
                >
                  <i className="fa fa-search" />
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleComplete}
                  className="mt-3"
                >
                  Complete
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleNotComplete}
                  className="mt-3"
                >
                  Not Complete
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={handleAll}
                  className="mt-3"
                >
                  All
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={patients}
                  value={selectedPatient}
                  onChange={(value) => setSelectedPatient(value)}
                  placeholder="Filter by patient..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>

              <Col sm={3}>
                <Select
                  className="mt-3"
                  options={[
                    { label: "AIS", value: "AIS" },
                    { label: "GF", value: "GF" },
                    { label: "MMA", value: "MMA" },
                  ]}
                  value={selectedProject}
                  onChange={(value) => setSelectedTProject(value)}
                  placeholder="Filter by project..."
                  isSearchable={true}
                  isClearable={true}
                />
              </Col>
            </Row>
          </CardHeader>

          <CustomTable
            header={
              <>
                <tr>
                  <th rowSpan={3} className="fixed-cell left" scope="col">
                    ID
                  </th>
                  <th rowSpan={3} className="fixed-cell left" scope="col">
                    Name
                  </th>
                  <th rowSpan={3} className="fixed-cell left" scope="col">
                    Project
                  </th>
                  <th rowSpan={3} scope="col">
                    Code
                  </th>
                  <th rowSpan={3} scope="col">
                    Password
                  </th>
                  <th rowSpan={3} scope="col">
                    Age
                  </th>
                  <th rowSpan={3} scope="col">
                    Sex
                  </th>
                  <th rowSpan={3} scope="col">
                    TB Status
                  </th>
                  <th rowSpan={3} scope="col">
                    TB/DRTB Code
                  </th>
                  <th rowSpan={3} scope="col">
                    Township
                  </th>
                  <th rowSpan={3} className="">
                    Total DOTS
                  </th>
                  <th rowSpan={3}>Visited</th>
                  <th rowSpan={3}>Missed VOT</th>
                  <th colSpan={3}>Missed targeted VOT doses</th>
                  <th rowSpan={3} scope="col">
                    Action
                  </th>
                </tr>
                <tr>
                  <th colSpan={4}>Missed doses (Frequency)</th>
                </tr>
                <tr>
                  <th>&lt;10%</th>
                  <th>10-50%</th>
                  <th>&gt;50%</th>
                </tr>
              </>
            }
            body={patient.patients.map((patient, index) => (
              <tr key={patient.id}>
                <td
                  className="fixed-cell left"
                  style={{
                    backgroundColor: patient.DOT_end_date
                      ? "#009879"
                      : "#EB2429",
                    color: "#ffffff",
                  }}
                >
                  {index + 1}
                </td>
                <td className="fixed-cell left">{patient.name}</td>
                <td className="fixed-cell left">{patient.project}</td>
                <td>{patient.code}</td>
                <td>{patient.plain_password}</td>
                <td>{patient.age}</td>
                <td>{patient.sex}</td>
                <td>{patient.TB_status}</td>
                <td>{patient.TB_DRTB_code}</td>
                <td>{patient.township?.name}</td>
                <td>
                  {patient?.missed_vot_calculation_result?.must_taking_dots}
                </td>
                <td>{patient?.missed_vot_calculation_result?.taking_dots}</td>
                <td>{patient?.missed_vot_calculation_result?.miss_dose}</td>
                <td>
                  {patient?.missed_vot_calculation_result?.result?.less_10
                    ? `(${patient?.missed_vot_calculation_result?.result?.less_10}) (${patient?.missed_vot_calculation_result?.result?.less_10_percentage}%)`
                    : "-"}
                </td>
                <td>
                  {" "}
                  {patient?.missed_vot_calculation_result?.result?.between_10_50
                    ? `(${patient?.missed_vot_calculation_result?.result?.between_10_50}) (${patient?.missed_vot_calculation_result?.result?.between_10_50_percentage}%)`
                    : "-"}
                </td>
                <td>
                  {" "}
                  {patient?.missed_vot_calculation_result?.result?.greater_50
                    ? `(${patient?.missed_vot_calculation_result?.result?.greater_50}) (${patient?.missed_vot_calculation_result?.result?.greater_50_percentage}%)`
                    : "-"}
                </td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() =>
                      router.push(
                        `/admin/dot-patient-with-volunteer-detail/${patient.id}`
                      )
                    }
                  >
                    Detail
                  </Button>
                </td>
              </tr>
            ))}
          />

          {patient.total > paginationLimit && (
            <CustomPagination pageCount={patient.total / paginationLimit} />
          )}
        </Card>
      </Container>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  getPatients,
  getPatient,
  getTownships,
})(DOTPatientWithVolunteerList);
