import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'reactstrap'
import CustomSelect from 'components/Inputs/CustomSelect'
import CustomModal from 'components/Modal/CustomModal'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { updatePatientMedicine } from 'store/actions'

const PatientMedicineUpdate = ({
  isOpen,
  toggle,
  status,
  medicine,
  patientMedicine,
  updatePatientMedicine,
}) => {
  const { id } = useParams()

  const { control, handleSubmit, setValue } = useForm()

  const [selectedMedicine, setSelectedMedicine] = useState(null)

  const [loading, setLoading] = useState(false)

  const medicines = medicine.medicines.map((element) => ({
    value: `${element.name}(${element.abbreviation})`,
    label: `${element.name}(${element.abbreviation})`,
  }))

  useEffect(() => {
    const { medicine_name } = patientMedicine.patientMedicine

    setValue('medicine', { value: medicine_name, label: medicine_name })
    setSelectedMedicine({ value: medicine_name, label: medicine_name })
  }, [setValue, patientMedicine.patientMedicine])

  const onSubmit = async (data) => {
    setLoading(true)
    await updatePatientMedicine(patientMedicine.patientMedicine.id, {
      patient_id: id,
      medicine_name: data.medicine?.value,
    })
    setLoading(false)
  }

  useEffect(() => {
    if (status.success) {
      toggle(false)
    }
  }, [status.success, toggle])

  return (
    <CustomModal
      isOpen={isOpen}
      title="Update Patient's Medicine"
      onClick={() => toggle(false)}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <CustomSelect
            id="medicine"
            label="Select Medicine"
            rules={{ required: 'Medicine is required!' }}
            control={control}
            options={medicines}
            value={selectedMedicine}
            setData={setSelectedMedicine}
            placeholder="Select Medicine"
          />
        </div>
        <div className="modal-footer">
          <Button color="primary" size="sm" type="submit" disabled={loading}>
            {loading ? 'Loading' : 'Update'}
          </Button>
        </div>
      </Form>
    </CustomModal>
  )
}

const mapStateToProps = (store) => ({
  status: store.status,
  patientMedicine: store.patientMedicine,
  medicine: store.medicine,
})

export default connect(mapStateToProps, { updatePatientMedicine })(
  PatientMedicineUpdate,
)
