import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import CustomSelect from "components/Inputs/CustomSelect";
import { isVolunteerSupervisor } from "utils/middleware";
import CustomInput from "components/Inputs/CustomInput";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import {
  sexs,
  referTos,
  caseFoundedActivities,
  riskFactors,
} from "variables/options";
import CustomDateInput from "components/Inputs/CustomDateInput";
import { calculateBMI } from "utils/BMI";
import { TBCodeChooses } from "variables/options";
import { handleRiskFactor } from "utils/DSTBPatient";
import { currentDate } from "utils/currentDate";
import { connect } from "react-redux";
import {
  getTownships,
  getVolunteers,
  getPatient,
  updatePatient,
  temDeletePatient,
  getLatestPresumptiveTBNumber,
} from "store/actions";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { useHistory, useParams } from "react-router";
import CustomAlert from "components/Alert/CustomAlert";
import { symptomAges } from "variables/options";
import { greaterThanEqual15Symptoms } from "variables/options";
import { lessThan15Symptoms } from "variables/options";
import { useQuery } from "hooks/useQuery";

const PresumptiveInfo = ({
  status,
  auth,
  township,
  patient,
  volunteer,
  getTownships,
  getVolunteers,
  getPatient,
  updatePatient,
  temDeletePatient,
  getLatestPresumptiveTBNumber,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const { role } = auth.user;
  const { id } = useParams();
  const router = useHistory();

  const fromTBPatient = useQuery("from_TB_patient");

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [sex, setSex] = useState(null);
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [referTo, setReferTo] = useState(null);
  const [BMI, setBMI] = useState(0);
  const [caseFoundedActivity, setCaseFoundedActivity] = useState(null);
  const [TBCodeChoose, setTBCodeChoose] = useState(null);
  const [symptomAge, setSymptomAge] = useState(null);
  const [symptom, setSymptom] = useState(null);
  const [riskFactor, setRiskFactor] = useState(null);

  const townships = township.townships.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const volunteers = volunteer.volunteers.map((element) => ({
    value: element.id,
    label: element.name,
  }));

  const projects = [
    { value: "AIS", label: "AIS" },
    { value: "GF", label: "GF" },
    { value: "MMA", label: "MMA" },
  ];

  useEffect(() => {
    getPatient(id);
  }, [getPatient, id]);

  useEffect(() => {
    const {
      township,
      name,
      address,
      phone,
      age,
      sex,
      referral_volunteer,
      referral_date,
      refer_to,
      refer_to_other,
      height,
      weight,
      BMI,
      case_founded_activity,
      TB_code,
      option,
      referred_type,
      symptom_age,
      symptoms,
      presumptive_TB_number,
      risk_factor,
      registration_date,
    } = patient.patient;

    setValue("township", { value: township?.id, label: township?.name });
    setSelectedTownship({ value: township?.id, label: township?.name });
    setValue("name", name);
    setValue("address", address);
    setValue("phone", phone);
    setValue("age", age);
    setValue("sex", { value: sex, label: sex });
    setSex({ value: sex, label: sex });
    setValue(
      "referral_volunteer",
      referral_volunteer === null
        ? null
        : {
            value: referral_volunteer?.id,
            label: referral_volunteer?.name,
          }
    );
    setSelectedVolunteer(
      referral_volunteer === null
        ? null
        : {
            value: referral_volunteer?.id,
            label: referral_volunteer?.name,
          }
    );
    setValue("referral_date", referral_date);
    setValue(
      "refer_to",
      refer_to === null ? null : { value: refer_to, label: refer_to }
    );
    setReferTo(refer_to === null ? null : { value: refer_to, label: refer_to });
    setValue("refer_to_other", refer_to_other);
    setValue("height", height);
    setValue("weight", weight);
    setValue("BMI", BMI);
    setValue(
      "case_founded_activity",
      case_founded_activity === null
        ? null
        : {
            value: case_founded_activity,
            label: case_founded_activity,
          }
    );
    setCaseFoundedActivity(
      case_founded_activity === null
        ? null
        : {
            value: case_founded_activity,
            label: case_founded_activity,
          }
    );
    setValue("TB_code", TB_code);
    setValue(
      "TB_code_choose",
      option === null ? null : { value: option, label: option }
    );
    setTBCodeChoose(option === null ? null : { value: option, label: option });
    setValue("referred_type", referred_type);
    setValue(
      "symptom_age",
      symptom_age === null ? null : { value: symptom_age, label: symptom_age }
    );
    setSymptomAge(
      symptom_age === null ? null : { value: symptom_age, label: symptom_age }
    );
    setValue(
      "symptoms",
      symptoms?.split("|").map((el) => {
        return {
          value: el,
          label: el,
        };
      })
    );
    setSymptom(
      symptoms?.split("|").map((el) => {
        return {
          value: el,
          label: el,
        };
      })
    );
    setValue("presumptive_TB_number", presumptive_TB_number);
    setValue(
      "risk_factor",
      risk_factor?.split("|").map((el) => {
        return {
          value: el,
          label: el,
        };
      })
    );
    setRiskFactor(
      risk_factor?.split("|").map((el) => {
        return {
          value: el,
          label: el,
        };
      })
    );
    setValue("registration_date", registration_date);
  }, [setValue, patient.patient]);

  useEffect(() => {
    if (role !== undefined && !isVolunteerSupervisor(role)) {
      getTownships();
    }
  }, [getTownships, role]);

  useEffect(() => {
    getVolunteers();
  }, [getVolunteers]);

  useEffect(() => {
    setValue("presumptive_TB_number", patient.latestPresumptiveTBNumber);
  }, [patient.latestPresumptiveTBNumber, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    await updatePatient(
      id,
      {
        township_id:
          role !== undefined && !isVolunteerSupervisor(role)
            ? data.township?.value
            : auth.user?.township?.id,
        name: data.name,
        address: data.address,
        phone: data.phone,
        age: data.age,
        sex: data.sex?.value,
        referral_volunteer_id: data.referral_volunteer?.value,
        referral_date: data.referral_date,
        refer_to: data.refer_to?.value,
        refer_to_other: data.refer_to_other,
        height: data.height,
        weight: data.weight,
        BMI: data.BMI,
        case_founded_activity: data.case_founded_activity?.value,
        TB_code: data.TB_code,
        option: data.TB_code_choose?.value,
        referred_type: data.referred_type?.value,
        symptom_age: data.symptom_age?.value,
        symptoms: data.symptoms?.map((el) => el.value).join("|"),
        presumptive_TB_number: data.presumptive_TB_number,
        risk_factor: data.risk_factor?.map((el) => el.value).join("|"),
        registration_date: data.registration_date,
        project: data.project?.value,
      },
      "patients",
      "Patient"
    );
    setLoading(false);
  };

  const handleDelete = () => {
    setAlert(
      <CustomAlert
        onConfirm={() => {
          temDeletePatient(patient.patient.id);
          setAlert(null);
          router.goBack();
        }}
        onCancel={() => setAlert(null)}
        toTrash={true}
      />
    );
  };

  if (status.loading) {
    return <FullScreenLoading />;
  }

  return (
    <>
      {alert}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
          <CardTitle>
            <h3>Presumptive Info</h3>
          </CardTitle>
          <Row>
            <Col md={4}>
              {role !== undefined && !isVolunteerSupervisor(role) ? (
                <CustomSelect
                  id="township"
                  label="Township"
                  rules={{ required: "Township is required!" }}
                  control={control}
                  options={townships}
                  value={selectedTownship}
                  setData={(data) => {
                    setSelectedTownship(data);
                    getLatestPresumptiveTBNumber(data?.value);
                  }}
                  placeholder="Township"
                />
              ) : (
                <p>{auth.user?.township?.name}</p>
              )}
            </Col>
            <Col md={4}>
              <CustomInput
                id="name"
                label="Name"
                register={{
                  ...register("name", {
                    required: "Name is required!",
                  }),
                }}
                placeholder="Enter Name"
                errors={errors}
              />
            </Col>
            <Col md={4}>
              <CustomInput
                id="address"
                label="Address"
                register={{
                  ...register("address", {
                    required: "Address is required!",
                  }),
                }}
                placeholder="Enter Address"
                errors={errors}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <CustomInput
                id="phone"
                label="Phone"
                register={{
                  ...register("phone", {
                    required: "Phone is required!",
                  }),
                }}
                placeholder="Enter Phone"
                errors={errors}
              />
            </Col>
            <Col md={4}>
              <CustomNumberInput
                id="age"
                label="Age"
                register={{
                  ...register("age", {
                    required: "Age is required!",
                  }),
                }}
                placeholder="Enter age"
                errors={errors}
                onChange={(event) => {
                  let age = event.target.value;

                  setSymptom(null);
                  setValue("symptoms", null);
                  if (+age >= 15) {
                    setValue("symptom_age", symptomAges[0]);
                    setSymptomAge(symptomAges[0]);
                  } else {
                    setValue("symptom_age", symptomAges[1]);
                    setSymptomAge(symptomAges[1]);
                  }
                }}
                max={120}
              />
            </Col>
            <Col md={4}>
              <CustomSelect
                id="sex"
                label="Sex"
                rules={{ required: "Sex is required!" }}
                control={control}
                options={sexs}
                value={sex}
                setData={setSex}
                placeholder="Sex"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <CustomSelect
                id="referral_volunteer"
                label="Referral volunteer"
                control={control}
                options={volunteers}
                rules={{
                  required: false,
                }}
                value={selectedVolunteer}
                setData={(data) => {
                  setCaseFoundedActivity(null);
                  setSelectedVolunteer(data);
                }}
                placeholder="Referral volunteer"
                isRequired={false}
              />
            </Col>
            <Col md={4}>
              <CustomSelect
                id="project"
                label="Project"
                control={control}
                options={projects}
                rules={{
                  required: false,
                }}
                value={selectedProject}
                setData={(data) => {
                  setCaseFoundedActivity(null);
                  setSelectedProject(data);
                }}
                placeholder="Project"
                isRequired={false}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <CustomDateInput
                id="referral_date"
                label="Referral Date"
                register={{
                  ...register("referral_date", {
                    required: false,
                  }),
                }}
                placeholder="Select Referral Date"
                errors={errors}
                isRequired={false}
                max={currentDate()}
              />
            </Col>
            <Col md={4}>
              <CustomSelect
                id="refer_to"
                label="Refer To"
                rules={{ required: false }}
                control={control}
                options={referTos}
                value={referTo}
                setData={setReferTo}
                placeholder="Refer To"
                isRequired={false}
              />
            </Col>
            <Col md={4}>
              <CustomInput
                id="refer_to_other"
                label="Refer To Other"
                register={{
                  ...register("refer_to_other", {
                    required:
                      referTo?.value === "Other"
                        ? "Refer To Other is required!"
                        : false,
                  }),
                }}
                placeholder="Enter Refer To Other"
                errors={errors}
                disabled={referTo === null || referTo?.value !== "Other"}
                isRequired={referTo?.value === "Other"}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <CustomNumberInput
                id="height"
                label="Height(cm)"
                register={{
                  ...register("height", {
                    required: "Height(cm) is required!",
                  }),
                }}
                placeholder="Enter height(cm)"
                errors={errors}
                onChange={(event) => {
                  let height = event.target.value * 0.01;
                  let weight = getValues("weight");

                  let BMI = weight / (height * height);
                  if (!isNaN(BMI) && BMI !== Infinity) {
                    setValue("BMI", BMI.toFixed(1));
                    setBMI(BMI.toFixed(1));
                  }
                }}
              />
            </Col>
            <Col md={4}>
              <CustomNumberInput
                id="weight"
                label="Initial Body Weight(kg)"
                register={{
                  ...register("weight", {
                    required: "Initial Body Weight(kg) is required!",
                  }),
                }}
                placeholder="Enter weight(Kg)"
                errors={errors}
                onChange={(event) => {
                  let weight = event.target.value;
                  let height = getValues("height") * 0.01;

                  let BMI = weight / (height * height);
                  if (!isNaN(BMI) && BMI !== Infinity) {
                    setValue("BMI", BMI.toFixed(1));
                    setBMI(BMI.toFixed(1));
                  }
                }}
                max={132}
              />
            </Col>
            <Col md={4}>
              <CustomNumberInput
                id="BMI"
                label={`BMI ${calculateBMI(parseFloat(BMI))}`}
                register={{
                  ...register("BMI", {
                    required: "BMI is required!",
                  }),
                }}
                placeholder="Enter BMI"
                errors={errors}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <CustomSelect
                id="case_founded_activity"
                label="Case Founded Activity"
                rules={{
                  required:
                    selectedVolunteer !== null
                      ? "Case Founded Activity is required!"
                      : false,
                }}
                control={control}
                options={caseFoundedActivities}
                value={caseFoundedActivity}
                setData={setCaseFoundedActivity}
                placeholder="Case Founded Activity"
                isDisabled={selectedVolunteer === null}
                isRequired={selectedVolunteer !== null}
              />
            </Col>
            <Col md={4}>
              <CustomInput
                id="TB_code"
                label="Index patient’s TB Registration No"
                register={{
                  ...register("TB_code", {
                    required:
                      caseFoundedActivity?.value === "CI"
                        ? "Index patient’s TB Registration No is required!"
                        : false,
                  }),
                }}
                placeholder="Enter Index patient’s TB Registration No"
                errors={errors}
                disabled={
                  caseFoundedActivity === null ||
                  caseFoundedActivity?.value !== "CI"
                }
                isRequired={caseFoundedActivity?.value === "CI"}
              />
            </Col>
            <Col md={4}>
              <CustomSelect
                id="TB_code_choose"
                label="Choose your option"
                rules={{
                  required:
                    caseFoundedActivity?.value === "CI"
                      ? "Choose your option is required!"
                      : false,
                }}
                control={control}
                options={TBCodeChooses}
                value={TBCodeChoose}
                setData={setTBCodeChoose}
                placeholder="Choose your option"
                isDisabled={
                  caseFoundedActivity === null ||
                  caseFoundedActivity?.value !== "CI"
                }
                isRequired={caseFoundedActivity?.value === "CI"}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <CustomInput
                id="referred_type"
                label="Referral Township"
                register={{
                  ...register("referred_type", {
                    required: false,
                  }),
                }}
                placeholder="Enter Referral Township"
                errors={errors}
                isRequired={false}
              />
            </Col>
            <Col md={4}>
              <CustomNumberInput
                id="presumptive_TB_number"
                label="Presumptive TB Number"
                register={{
                  ...register("presumptive_TB_number", {
                    required: "Presumptive TB Number is required!",
                  }),
                }}
                placeholder="Enter Presumptive TB Number"
                errors={errors}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <CustomSelect
                id="symptom_age"
                label="Age >=15 or <15"
                rules={{
                  required: "Age >=15 or <15 is required!",
                }}
                control={control}
                options={symptomAges}
                value={symptomAge}
                setData={(data) => {
                  setSymptomAge(data);
                  setSymptom(null);
                }}
                placeholder="Age >=15 or <15"
                isRequired={true}
                isDisabled={true}
              />
            </Col>
            {symptomAge?.value === ">=15" ? (
              <Col md={8}>
                <CustomSelect
                  id="symptoms"
                  label="Symptoms"
                  rules={{
                    required: "Symptoms is required!",
                  }}
                  control={control}
                  options={greaterThanEqual15Symptoms}
                  value={symptom}
                  setData={setSymptom}
                  placeholder="Symptoms"
                  isMulti={true}
                />
              </Col>
            ) : symptomAge?.value === "<15" ? (
              <Col md={8}>
                <CustomSelect
                  id="symptoms"
                  label="Symptoms"
                  rules={{
                    required: "Symptoms is required!",
                  }}
                  control={control}
                  options={lessThan15Symptoms}
                  value={symptom}
                  setData={setSymptom}
                  placeholder="Symptoms"
                  isMulti={true}
                />
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col md={4}>
              <CustomSelect
                id="risk_factor"
                label="Risk Factor"
                control={control}
                rules={{
                  required: "Risk Factor is required!",
                }}
                options={riskFactors}
                value={handleRiskFactor(riskFactor)}
                setData={setRiskFactor}
                placeholder="Risk Factor"
                isMulti={true}
                isRequired={true}
              />
            </Col>
            <Col md={4}>
              <CustomDateInput
                id="registration_date"
                label="Registration Date"
                register={{
                  ...register("registration_date", {
                    required: "Registration Date is required!",
                  }),
                }}
                placeholder="Select Registration Date"
                errors={errors}
                isRequired={true}
                max={currentDate()}
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-right">
          {fromTBPatient && (
            <Button color="primary" size="sm" disabled={loading} type="submit">
              {loading ? "Loading" : "Update"}
            </Button>
          )}
          <Button color="danger" size="sm" onClick={handleDelete}>
            Delete
          </Button>
        </CardFooter>
      </Form>
    </>
  );
};

const mapStateToProps = (store) => ({
  status: store.status,
  auth: store.auth,
  township: store.township,
  volunteer: store.volunteer,
  patient: store.patient,
});

export default connect(mapStateToProps, {
  getTownships,
  getVolunteers,
  getPatient,
  updatePatient,
  temDeletePatient,
  getLatestPresumptiveTBNumber,
})(PresumptiveInfo);
